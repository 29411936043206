import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
    MenuItem
} from '@material-ui/core';
import {
    SingleSelect
} from './FormComponents';

const GroupSizeSelect = ({
    disabled,
    value,
    onChange
}) => {
    let groups = useSelector(state => state.metadata.groups);
    groups = [ { id: null, name: 'Ei valittua ryhmäkokoa' }, ...groups ];
    return (
        <SingleSelect
            label="Ryhmäkoko"
            disabled={ disabled }
            value={ value }
            onChange={ (event) => onChange(event.target.value) }>
                { groups.map((group) => (
                    <MenuItem
                        key={ group.id }
                        value={ group.id }>
                        { `${ group.name }` }
                    </MenuItem>))
                }
        </SingleSelect>
    )
}

export default GroupSizeSelect;

