import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Button, Typography, Grid, makeStyles
} from '@material-ui/core';
import { routes } from 'routers/routes';
import { createCard, activateTemplate } from 'actions/cardEditor';
import { templateImages } from 'components/Card/images';
import './TemplatePickerPage.css';
import { useEffect } from 'react';
import { getTemplates } from 'services/templateService';
import { AppLoader } from 'utils/AppLoader';
import useUser from 'hooks/useUser';

const TAG = '[TemplatePickerPage]';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    }
  });

function TemplatePickerPage() {
    const [templates, setTemplates] = useState([]);
    const [selected, setSelected] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useUser();

    useEffect(() => {
        (async () => {
            try {
                setIsFetching(true);
                const templates = await getTemplates();
                setTemplates(templates);
                setIsFetching(false);
            } catch(error) {
                console.error(error);
                setIsFetching(false);
            }
        })();
    }, [])

    function handleConfirm() {
        console.log('should use template', selected);
        dispatch(createCard(user.id));
        dispatch(activateTemplate(selected));
        history.replace(routes.EDIT_CARD);
    }

    return (
        <div className="app-wrapper">
            <AppLoader active={ isFetching } />
            <Grid container>
                <Grid item xs={ 8 }>
                <Typography
                    variant="h4"
                    component="h1"
                    className="mb-5"
                >
                    Valitse korttipohja
                </Typography>
                </Grid>
                <Grid
                    item
                    xs={ 4 }
                    className={ classes.buttons }
                >
                    <Button
                        className="ml-3"
                        disabled={ !selected }
                        variant="contained"
                        color="primary"
                        onClick={ handleConfirm }>
                        Jatka
                    </Button>
                </Grid>
            </Grid>
            <div className="templates-grid">
                { templates.map((template) => (
                    <div
                        onClick={ () => setSelected(template) }
                        key={ template.id }
                        className={ `template-thumbnail ${ selected && selected.id === template.id ? 'selected' : '' }` }
                    >
                        <img
                            alt={ template.title }
                            src={ templateImages[`template_${ template.id }`] } />
                    </div>))
                }
            </div>
        </div>
    )
}

export default TemplatePickerPage;

