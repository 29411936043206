import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import {
    openEditor,
    updateNode,
    removeNode,
    closeEditor,
    closeNodeEditor,
    removeDifficultyLevel
} from 'actions/cardEditor';
import AddContentTrigger from 'components/editor/AddContentTrigger';
import EditorTrigger from 'components/editor/EditorTrigger';
import ContentTextEditor from 'components/editor/ContentTextEditor';
import './CardComponents.css';
import LevelIndicator from 'components/LevelIndicator';
import LevelIndicatorEditor from 'components/LevelIndicatorEditor';

export const Row = ({
    activeNodeId,
    canEdit = true,
    children,
    className,
    secondaryColor = '#aca',
    difficultyLevel,
    scale,
    id
}) => {
    const dispatch = useDispatch();
    let content = null;
    if (id === activeNodeId) {
        content = (
            <LevelIndicatorEditor onSelect={ (update) => {
                dispatch(updateNode(id, update));
                dispatch(closeNodeEditor());
            }} />
        );
    } else {
        content = (
            <>
                { canEdit &&
                    <EditorTrigger
                        disableDelete={ !difficultyLevel }
                        onEdit={ () => dispatch(openEditor('ROW', id)) }
                        onDelete={ () => dispatch(removeDifficultyLevel(id)) }
                    />
                }
                { difficultyLevel && <LevelIndicator level={ difficultyLevel } scale={ scale } /> }
            </>
        );
    }

    return (
        <div className={ 'leado-card-row ' + className }>
            <div
                className="leado-card-row-left-container"
                style={{ backgroundColor: secondaryColor }}
            >
                { content }
            </div>
            <div className="leado-card-row-content">
                { children }
            </div>
        </div>
    );
};

export const Column = ({
    canEdit,
    children,
    className,
    id,
    style
    }) => {
    let content;
    if (canEdit) {
        content = children.length ? children : <AddContentTrigger id={ id } />;
    } else {
        content = children;
    }
    return (
        <div className={ "leado-card-column " + className } style={ style }>
            { content }
        </div>
    )
};

export const ContentText = connect(null)(({
    activeNodeId,
    canEdit,
    data,
    dispatch,
    id,
    scale,
    style
}) => {
    // If the card has no line height set, use default
    style.lineHeight = style.lineHeight || 1.5;
    let content;
    if (id === activeNodeId) {
        content = (
            <ContentTextEditor
                scale={ scale }
                data={ data }
                onSaveChanges={ (update) => dispatch(updateNode(id, update)) }
                style={ style }
            />
        );
    } else {
        content = (
            <div>
                { canEdit &&
                    <EditorTrigger
                        onDelete={ () => dispatch(removeNode(id))}
                        onEdit={ () => dispatch(openEditor('NODE', id)) }
                    />
                }
                <ContentTextContent content={ data } style={ style } scale={ scale } />
            </div>
        );
    }

    return (
        <div className="content-wrapper">
            { content }
        </div>
    );
});

const ContentTextContent = ({ style, scale, content }) => {
    const [ displayOverflowWarning, setDisplayOverFlowWarning ] = useState(false);
    const fontSize = (scale * style.fontSize) + 'pt';
    let wrapperNode = useRef();

    const wrapperRef = useCallback(node => {
        wrapperNode.current = node;
    }, []);

    useEffect(() => {
        if (wrapperNode.current) {
            console.log(wrapperNode.current.clientHeight, wrapperNode.current.scrollHeight)
            if (wrapperNode.current.clientHeight < wrapperNode.current.scrollHeight) {
                setDisplayOverFlowWarning(true);
            } else {
                setDisplayOverFlowWarning(false);
            }
        } else {
            setDisplayOverFlowWarning(false);
        }
    }, [scale])

    return (
        <div
            className="content-text-wrapper"
            ref={ wrapperRef }
        >
            { displayOverflowWarning && (
                <Alert
                    className="content-text-overflow-warning"
                    severity="warning"
                >
                </Alert>
            )}
            <p
                style={{ ...style, fontSize }}
                className={ "content-text" }
            >
                { content }
            </p>
        </div>
    );
}

export const ContentImage = connect(null)(({
    canEdit,
    data,
    filename,
    dispatch,
    id
}) => {
    return (
        <div className="content-wrapper">
            { canEdit &&
                <EditorTrigger
                    onDelete={ () => dispatch(removeNode(id))}
                    onEdit={ () => dispatch(openEditor('IMAGE', id)) }/>
            }
            <img src={ filename ? `${process.env.REACT_APP_IMAGES_BASE_URL}/${filename}` : data }
                alt=""
                className={ "content-text" } />
        </div>
    );
});
