import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { fetchMetaData } from 'actions/metadata';
import { refreshLogin, logoutClient } from 'actions/auth';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#275598',
      },
      secondary: {
        main: '#b5038d',
      },
    },
    typography: {
        fontFamily: [
        //   'Comfortaa',
          'Lato',
          'sans-serif'
        ].join(','),
    },
});

store.dispatch(fetchMetaData());
if (localStorage.getItem('token')) {
    store.dispatch(refreshLogin());
} else {
    store.dispatch(logoutClient());
}

const jsx = (
    <Provider store={ store }>
        <ThemeProvider theme={ theme }>
            <App />
        </ThemeProvider>
    </Provider>
);

ReactDOM.render(jsx , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
