import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routers/routes';
import ListItem from './ListItem';
import Expandable from './Expandable';
import HorizontalList from './HorizontalList';
import FilterForm from './FilterForm';
import GridList from 'components/layout/GridList';
import { editCard } from '../../actions/cardEditor';
import CardViewerDialog from './CardViewerDialog';
import './css/CardTab.css';
import {
    getPublishedCards,
    getHiddenCards,
    getLatestCards,
    publishCard,
    publishHiddenCard,
    deletePublicCard,
    hideCard
} from 'services/cardService';
import { AppLoader } from 'utils/AppLoader';
import Alert from '@material-ui/lab/Alert';
import useFilters from 'hooks/useFilters';
import { TablePagination } from '@material-ui/core';
import usePagination from 'hooks/usePagination';
import LeadoHorizontalRule from './LeadoHorizontalRule';

function CardTab() {
    const history = useHistory();
    const [isFetching, setIsFetching] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [isFetchingLatest, setIsFetchingLatest] = useState(true);
    const [selectedCard, setSelectedCard] = useState();
    const [filters, setFilters] = useState({});
    const [cards, setCards] = useState([]);
    const [latestCards, setLatestCards] = useState([]);
    const [error, setError] = useState('');
    const filterCards = useFilters();
    const dispatch = useDispatch();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        initRowsPerPage,
    } = usePagination({
        page: 0,
        rowsPerPage: 64,
    });

    const getLatest = useCallback(async () => {
        setIsFetchingLatest(true);
        try {
            const latestCards = await getLatestCards();
            setLatestCards(latestCards);
            setIsFetchingLatest(false);
        } catch (error) {
            setError('Viimeisimpiä LeaDo-kortteja ei voitu hakea. Yritä myöhemmin uudelleen');
            setIsFetchingLatest(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            setIsFetching(true);
            try {
                const cards = !filters.status ? await getPublishedCards() : await getHiddenCards();
                setCards(cards);
                setIsFetching(false);
            } catch (error) {
                setError('LeaDo-kortteja ei voitu hakea. Yritä myöhemmin uudelleen');
                setIsFetching(false);
            }
        })();
        getLatest();
    }, [filters.status, getLatest]);

    useEffect(() => {
        handleChangePage(null, 0);
    }, [filters, handleChangePage]);

    const visibleCards = filterCards(cards, filters);
    const gridContent = visibleCards.map((card) => (
        <ListItem key={card.id} card={card} handleViewCard={(card) => setSelectedCard(card)} />
    ));
    const contentLength = gridContent.length;

    return (
        <div className="padding">
            <AppLoader active={isFetching || isFetchingLatest} />
            {error && (
                <Alert className="mb-3" severity="error">
                    {error}
                </Alert>
            )}
            { successMessage && <Alert className="mb-2" severity="success">{ successMessage }</Alert>}
            <div>
                <h4>Uusimmat LeaDo-kortit</h4>
                <div className="user-item-dashboard">
                    <HorizontalList loading={isFetchingLatest} height={200} itemWidth={150}>
                        {latestCards.map((card) => (
                            <ListItem
                                key={card.id}
                                card={card}
                                handleViewCard={(card) => setSelectedCard(card)}
                            />
                        ))}
                    </HorizontalList>
                </div>
            </div>
            <div className="mt-3">
                <h2>Kaikki LeaDo-kortit</h2>
                <Expandable label="Suodata kortteja">
                    <FilterForm
                        onChange={({ values }) => setFilters(values)}
                        hiddenFilter={ true }
                    />
                </Expandable>
                <GridList
                    rowLength={8}
                    minItemWidth="150px"
                    currentPage={page}
                    cardsPerPage={rowsPerPage}
                >
                    {gridContent}
                </GridList>
                <table className="grid-list-pagination">
                    <tbody>
                        <tr>
                            <TablePagination
                                labelRowsPerPage="Näytä sivulla"
                                page={page}
                                count={contentLength}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                //labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                                labelDisplayedRows={({ from, to, count, page }) =>
                                    `${page + 1}/${Math.ceil(count / rowsPerPage)}`
                                }
                                rowsPerPageOptions={[initRowsPerPage]}
                            />
                        </tr>
                    </tbody>
                </table>
                <CardViewerDialog
                    open={!!selectedCard}
                    cardId={selectedCard && selectedCard.id}
                    cardStatus={selectedCard && selectedCard.status}
                    onlyHidden={filters.onlyHidden}
                    onlyPending={filters.onlyPending}
                    onClose={() => setSelectedCard(null)}
                    onEdit={(card) => {
                        dispatch(editCard(card));
                        history.push(routes.EDIT_CARD);
                    }}
                    onPublish={(published) => {
                        setSuccessMessage(`Kortti ${ published.title } on julkaistu opettajille.`);
                        if (filters.status) {
                            setCards(cards.filter(pathway => pathway.id !== published.id));
                        }
                        getLatest();
                    }}
                    onHide={(hidden) => {
                        setCards(cards.filter(card => card.id !== hidden.id));
                        setLatestCards(latestCards.filter(card => card.id !== hidden.id));
                        setSuccessMessage(`Kortti ${ hidden.title } on piilotettu opettajilta.`);
                    }}
                    onDelete={(deleted) => {
                        setCards(cards.filter(card => card.id !== deleted.id));
                        setLatestCards(latestCards.filter(card => card.id !== deleted.id));
                        setSuccessMessage(`Kortti ${ deleted.title } poistettu onnistuneesti!`);
                    }}
                />
            </div>
        </div>
    );
}

export default CardTab;
