import React from 'react';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import PDFCard from '../pdf/PDFCard';

class PDFDownloadDialog extends React.Component {
    render() {
        const {
            cards,
            competences,
            onClose,
            open,
            metadata
        } = this.props;

        const cardsWithColors = cards.map((card) => {
            const competence = competences.find(competence => competence.id === card.competence);
            const primaryColor = competence ? competence.color : '#aaa';
            const secondaryColor = competence ? competence.secondaryColor : '#ddd';
            return {
                ...card,
                primaryColor,
                secondaryColor
            }
        });
        return (
            <Dialog
                open={open}
                maxWidth="xs">
                <DialogContent>

                    <PDFDownloadLink
                        document={
                            <PDFCard
                                cards={cardsWithColors}
                                metadata={metadata}
                            />
                        }>
                        {({ blob, url, loading, error }) => {
                            if (error) {
                                console.error('PDF download error:', error);
                                return 'Error generating PDF';
                            }
                            return loading ? 'Luodaan PDF-tiedosto...' : 'Lataa PDF-tiedosto';
                        }}
                    </PDFDownloadLink>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}>
                        Sulje
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        competences: state.metadata.competences,
        metadata: state.metadata
    }
};

export default connect(mapStateToProps)(PDFDownloadDialog);
