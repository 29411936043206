import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Button
} from '@material-ui/core';
import './EditCardForm.css';
import { updateCard } from '../../actions/cardEditor';
import CompetenceSelect from './forms/CompetenceSelect';
import SubjectSelect from './forms/SubjectSelect';
import GradeSelect from './forms/GradeSelect';
import MetacognitiveSelect from './forms/MetacognitiveSelect';
import GroupSizeSelect from './forms/GroupSizeSelect';

function EditCardForm({
    card,
    editor
}) {
    const dispatch = useDispatch();

    const disabled = !!editor;
    return (
        <div className="card-data-editor">
            <h3>Kortin tiedot</h3>
            <CompetenceSelect
                disabled={ disabled }
                onChange={ (competence) => dispatch(updateCard({ competence }, true)) }
                value={ card.competence || '' }
            />
            <SubjectSelect
                disabled={ disabled }
                onChange={ (subjects) => dispatch(updateCard({ subjects }, true)) }
                value={ card.subjects || '' }
            />
            <GradeSelect
                disabled={ disabled }
                onChange={ (grades) => dispatch(updateCard({ grades }, true)) }
                value={ card.grades || '' }
            />
            <MetacognitiveSelect
                disabled={ disabled }
                onChange={ (metacognitiveTool) => dispatch(updateCard({ metacognitiveTool }, true)) }
                value={ card.metacognitiveTool || '' }
            />
            <GroupSizeSelect
                disabled={ disabled }
                onChange={ (group) => dispatch(updateCard({ group }, true)) }
                value={ card.group || '' }
            />
        </div>
    );
}

export default EditCardForm;