import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import './css/Tabbed.css';

export function Tabbed({
    cardTab,
    cardPendingTab,
    pathwaysTab,
    pathwaysPendingTab
}) {

    const [activeTab, setActiveTab] = useState('cards');

    const content = (tab) => {
      let content = cardTab;
      switch (tab) {
        case 'cards':
          content = cardTab;
          break;
        case 'cards-pending':
          content = cardPendingTab;
          break;
        case 'pathways':
          content = pathwaysTab;
          break;
        case 'pathways-pending':
          content = pathwaysPendingTab;
          break;
        default:
          content = cardTab;
          break;
      }
      return content;
    }

    return (
        <>
            <Nav
              className="tabbed"
              variant="tabs"
              activeKey={ activeTab }
              fill
              justify
            >
              <Nav.Item className="tabbed-cards">
                <Nav.Link
                  eventKey="cards"
                  onSelect={ () => setActiveTab('cards')}
                >Kortit</Nav.Link>
              </Nav.Item>
              {cardPendingTab &&
                <Nav.Item className="tabbed-cards">
                  <Nav.Link
                    eventKey="cards-pending"
                    onSelect={ () => setActiveTab('cards-pending')}
                  >Kortit pending</Nav.Link>
                </Nav.Item>
              }
              <Nav.Item className="tabbed-pathways">
                <Nav.Link
                  eventKey="pathways"
                  onSelect={ () => setActiveTab('pathways') }
                >Oppimispolut</Nav.Link>
              </Nav.Item>
              {pathwaysPendingTab &&
                <Nav.Item className="tabbed-cards">
                  <Nav.Link
                    eventKey="pathways-pending"
                    onSelect={ () => setActiveTab('pathways-pending')}
                  >Oppimispolut pending</Nav.Link>
                </Nav.Item>
              }
            </Nav>
            { content(activeTab) }
        </>
    );
}