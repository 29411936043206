export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_END = 'AUTH_REQUEST_END';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CONFIRM_LOGOUT = 'CONFIRM_LOGOUT';
export const CANCEL_LOGOUT = 'CANCEL_LOGOUT';
