import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Link,
    useLocation
} from 'react-router-dom';
import {
    Button,
    Navbar,
    Nav,
    Container
} from 'react-bootstrap';
import { routes } from '../../routers/routes';
import './css/NavBar.css';
import { withPermissions } from 'utils/withPermissions';
import { logoutClient } from 'actions/auth';
import logo from 'assets/images/logo.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function NavBar() {
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <Container
			style={{
				paddingLeft: '0px',
				paddingRight: '0px'
			}}
			fluid
		>
        <Navbar
            className="leado-navbar"
            bg="dark"
            variant="dark"
        >
            <Navbar.Brand href={ routes.HOME }>
                <img
                    src={ logo }
                    className="leado-navbar-logo"
                    alt="LeaDo logo"
                />
            </Navbar.Brand>
                <Nav
                    activeKey={ location.pathname }
                >
                    <NavPrivate>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.DASHBOARD }
                            to={ routes.DASHBOARD }
                        >Etusivu</Nav.Link>
                    </NavPrivate>
                    <NavPrivate>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.LIBRARY }
                            to={ routes.LIBRARY }
                        >Oma kirjasto</Nav.Link>
                    </NavPrivate>
                    <NavPrivate>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.LEADO }
                            to={ routes.LEADO }
                        >LeaDo-kirjasto</Nav.Link>
                    </NavPrivate>
                    <NavPrivate>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.SUPPORT }
                            to={ routes.SUPPORT }
                        >Tuki</Nav.Link>
                    </NavPrivate>
                    <NavAdmin>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.TEACHERS }
                            to={ routes.TEACHERS }
                        >Opettajat</Nav.Link>
                    </NavAdmin>
                    <NavPrivate>
                        <Nav.Link
                            as={ Link }
                            eventKey={ routes.PROFILE }
                            to={ routes.PROFILE }
                        >Oma tili</Nav.Link>
                    </NavPrivate>
                    <NavPrivate className="logout-item">
                        <Nav.Link
                            onClick={ () => {
                                dispatch(logoutClient());
                            }}
                        >
                            <ExitToAppIcon />
                        </Nav.Link>
                    </NavPrivate>
                </Nav>
            </Navbar>
        </Container>
    )
}

export default NavBar;

const NavAdmin = withPermissions(Nav.Item, null, ['ADMIN']);
const NavPrivate = withPermissions(Nav.Item, null, ['ADMIN', 'TEACHER']);
const NavTeacher = withPermissions(Nav.Item, null, ['TEACHER']);