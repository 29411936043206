const initialState = {
    isFetching: false
};

export function metadata(state = initialState, action) {
    switch(action.type) {
        case 'FETCH_META_DATA_BEGIN': {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'FETCH_META_DATA_SUCCESS': {
            const data = action.data;
            const isFetching = false;
            return {
                ...state,
                ...data,
                isFetching
            }
        }
        case 'FETCH_META_DATA_FAILURE': {
            const isFetching = false;
            return {
                ...state,
                isFetching
            }
        }
        default: {
            return state;
        }
    }
}