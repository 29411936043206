import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    TablePagination
} from '@material-ui/core';
import { routes } from '../../routers/routes';
import FilterForm from './FilterForm';
import { editPathway } from '../../actions/pathwayEditor';
import PathwayViewerDialog from './PathwayViewerDialog';
import CardViewerDialog from './CardViewerDialog';
import Expandable from './Expandable';
import PathwayItem from './PathwayItem';
import HorizontalList from './HorizontalList';
import {
    getPublishedPathways,
    getLatestPathways,
    getHiddenPathways
} from 'services/pathwayService';
import usePagination from 'hooks/usePagination';
import { AppLoader } from 'utils/AppLoader';
import GridList from './GridList';
import useFilters from 'hooks/useFilters';
import Alert from '@material-ui/lab/Alert';
import LeadoHorizontalRule from './LeadoHorizontalRule';

const TAG = '[PathwaysTab]';

function PathwaysTab() {
    const [viewPathway, setViewPathway] = useState(null);
    const [viewCard, setViewCard] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [pathways, setPathways] = useState([]);
    const [latestPathways, setLatestPathways] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingLatest, setIsFetchingLatest] = useState(true);
    const [error, setError] = useState('');
    const [filters, setFilters] = useState({});
    const filterPathways = useFilters();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        initRowsPerPage,
    } = usePagination({
        page: 0,
        rowsPerPage: 64,
    });

    const fetchPublicPathways = useCallback(async () => {
        setIsFetching(true);
        try {
            const pathways = await getPublishedPathways();
            setPathways(pathways);
            setIsFetching(false);
        } catch(error) {
            setError('LeaDo-oppimispolkuja ei voitu hakea. Yritä myöhemmin uudelleen');
            setIsFetching(false);
        }
    }, []);

    const fetchHiddenPathways = useCallback(async () => {
        setIsFetching(true);
        try {
            const pathways = await getHiddenPathways();
            setPathways(pathways);
            setIsFetching(false);
        } catch(error) {
            setError('LeaDo-oppimispolkuja ei voitu hakea. Yritä myöhemmin uudelleen');
            setIsFetching(false);
        }
    }, []);

    const fetchLatestPathways = useCallback(async () => {
        setIsFetching(true);
        try {
            const latestPathways = await getLatestPathways();
            setLatestPathways(latestPathways);
            setIsFetchingLatest(false);
        } catch (error) {
            setError('Viimeisimpiä LeaDo-oppimispolkuja ei voitu hakea. Yritä myöhemmin uudelleen');
            setIsFetchingLatest(false);
        }
    }, []);

    useEffect(() => {
        !filters.status ? fetchPublicPathways() : fetchHiddenPathways();
        fetchLatestPathways();
    }, [fetchPublicPathways, fetchHiddenPathways, fetchLatestPathways, filters.status]);


    const visiblePathways = filterPathways(pathways, filters);
    const gridContent = visiblePathways.map((pathway) => (
        <PathwayItem
            key={pathway.id}
            pathway={pathway}
            handleViewPathway={(viewPathway) => setViewPathway(viewPathway.id)}
        />
    ));
    const contentLength = gridContent.length;

    return (
        <div className="padding">
            <AppLoader active={isFetching || isFetchingLatest} />
                {error && (
                    <Alert className="mb-3" severity="error">
                        {error}
                    </Alert>
                )}
                { successMessage && <Alert className="mb-2" severity="success">{ successMessage }</Alert>}
                <div>
                    <h4>Uusimmat LeaDo-oppimispolut</h4>
                    <div className="user-item-dashboard">
                        <HorizontalList loading={isFetchingLatest} height={200} itemWidth={150}>
                            {latestPathways.map((pathway) => (
                                <PathwayItem
                                    key={pathway.id}
                                    pathway={pathway}
                                    handleViewPathway={(pathway) => setViewPathway(pathway.id)}
                                />
                            ))}
                        </HorizontalList>
                    </div>
                </div>
            <div className="mt-3">
                <h2>Kaikki LeaDo-oppimispolut</h2>
                <Expandable label="Suodata oppimispolkuja">
                    <FilterForm
                        onChange={({ values }) => setFilters(values)}
                        hiddenFilter={ true }
                    />
                </Expandable>
                <GridList
                    rowLength={8}
                    minItemWidth="150px"
                    currentPage={page}
                    cardsPerPage={rowsPerPage}
                >
                    {gridContent}
                </GridList>
                <table className="grid-list-pagination">
                    <tbody>
                        <tr>
                            <TablePagination
                                labelRowsPerPage="Näytä sivulla"
                                page={page}
                                count={contentLength}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                //labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                                labelDisplayedRows={({ from, to, count, page }) =>
                                    `${page + 1}/${Math.ceil(count / rowsPerPage)}`
                                }
                                rowsPerPageOptions={[initRowsPerPage]}
                            />
                        </tr>
                    </tbody>
                </table>
            </div>
            <PathwayViewerDialog
                open={!!viewPathway}
                pathwayId={viewPathway}
                onClose={() => setViewPathway(null)}
                onEdit={(pathway) => {
                    dispatch(editPathway(pathway));
                    history.push(routes.EDIT_PATHWAY);
                }}
                onViewCard={(viewCard) => setViewCard(viewCard.id)}
                onPublish={(published) => {
                    setSuccessMessage(`Polku ${ published.title } on julkaistu opettajille.`);
                    if (filters.status) {
                        setPathways(pathways.filter(pathway => pathway.id !== published.id));
                    }
                }}
                onHide={(hidden) => {
                    setPathways(pathways.filter(pathway => pathway.id !== hidden.id));
                    setSuccessMessage(`Polku ${ hidden.title } on piilotettu opettajilta.`);
                }}
                onDelete={(deleted) => {
                    setPathways(pathways.filter(pathway => pathway.id !== deleted.id));
                    setSuccessMessage(`Polku ${ deleted.title } poistettu onnistuneesti!`);
                } }
                onClone={() => console.log('cloned pathway')}
                onSuggest={(suggested, pathway) => {
                  if (pathway.status === 'REJECTED'){
                    let array = pathways.filter(pathwayItem => pathwayItem.id !== pathway.id)
                    array = array.concat(suggested);
                    setPathways(array);
                  }else {
                    setPathways(pathways.concat(suggested));
                  } 
                  setSuccessMessage(`Polku ${ suggested.title } on lähetetty adminille tarkasteltavaksi.`);
              }}
            />
            { viewCard &&
                <CardViewerDialog
                    open={ !!viewCard }
                    cardId={ viewCard }
                    viewOnly={ true }
                    onClose={ () => setViewCard(null) }
                />
            }
        </div>
    )
}

export default PathwaysTab;