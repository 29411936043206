import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ListItem from './ListItem';
import PDFDownloadDialog from '../layout/PDFDownloadDialog';
import ConfirmationDialog from './ConfirmationDialog';
import GridList from './GridList';
import EmptyListItem from './EmptyListItem';
import AppLoader from './AppLoader';
import { withPermissions } from 'utils/withPermissions';
import useUser from 'hooks/useUser';
import {
    publishHiddenPathway,
    deletePublicPathway,
    hidePathway,
    clonePathway,
    suggestPathway,
    publishPathway,
    rejectPathway,
    deletePathway,
    getPathway
} from 'services/pathwayService';
import { getCard } from 'services/cardService';
import { duplicatePathway } from 'reducers/userPathways';
import { getUser } from 'services/userService';
import './css/Dialog.css';
import { MultipleSelect } from 'components/editor/forms/FormComponents';

function PathwayViewerDialog({
    open,
    onClose,
    onEdit,
    onViewCard,
    onDelete,
    onSuggest,
    onPublish,
    onReject,
    onHide,
    onClone,
    pathwayId
}) {
    const user = useUser();
    const uid = user.id;
    const [pathway, setPathway] = useState();
    const [owner, setOwner] = useState();
    const [cards, setCards] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingCards, setIsFetchingCards] = useState(false);
    const [showSuggestConfirmation, setShowSuggestConfirmation] = useState(false);
    const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
    const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
    const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
    const [showHideConfirmation, setShowHideConfirmation] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPDFDialog, setShowPDFDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [selectedCriterias, setSelectedCriterias] = useState([]);

    // We should move these to database
    const criteriaList = [
        {value: 1, label: 'Epäselvä ohjeistus'},
        {value: 2, label: 'Epäselvät kuvat'},
        {value: 3, label: 'Epäselvät videot'},
        {value: 4, label: 'Rikkinäiset tai epäloogiset linkit'},
        {value: 5, label: 'Epäselvät hakuvalinnat'},
        {value: 6, label: 'Epälooginen kokonaisuus'}
    ];

    useEffect(() => {
        if (!pathwayId) {
            setPathway(null);
            return;
        }
        (async () => {
            try {
                setIsFetching(true);
                const pathway = await getPathway(pathwayId);
                if (isAdmin && pathway.status === 'PENDING') {
                    const user = await getUser(pathway.owner);
                    setOwner(user);
                }
                setPathway(pathway);
                setIsFetching(false);
            } catch(error) {
                console.error(error);
                setIsFetching(false);
            }
        })();
    }, [pathwayId]);

    useEffect(() => {
        if (!pathway) {
            setCards([]);
            return;
        }
        (async () => {
            try {
                setIsFetchingCards(true);
                const cards = await Promise.all(pathway.cards.map(card => {
                    if (!card) {
                        return null;
                    }
                    return getCard(card.id);
                }).filter(card => !!card));
                setCards(cards);
                setIsFetchingCards(false);
            } catch(error) {
                console.error(error);
                setIsFetchingCards(false);
            }
        })();
    }, [pathway]);

    async function handlePathwayAction(action, formData) {
        try {
            setIsFetching(true);
            const response = formData ? await action(pathway.id, formData) : await action(pathway.id);
            setIsFetching(false);
            return response;
        } catch (error) {
            console.error(error);
            setIsFetching(false);
        }
    }

    function handleCreatePDF() {
        onCloseMenu();
        setShowPDFDialog(true);
    }

    function onCloseMenu() {
        setAnchorEl(null);
    };

    const MenuItemAdmin = withPermissions(MenuItem, null, ['ADMIN']);
    const isAdmin = user.roles.includes('ADMIN');
    const isUserPathway = pathway && pathway.owner === uid;
    const hasRejectedCards = pathway && pathway.cards.filter(card => card && card.status === "REJECTED").length !== 0;

    const privateMenunContent = pathway && pathway.status === "PENDING" ? (
        <span>
            <MenuItem onClick={ () => {
                onCloseMenu();
                const pathwayCopy = duplicatePathway(pathway);
                onEdit(pathwayCopy);
            }}>Kopioi luonnokseksi
            </MenuItem>
        </span>
    ) : (
        <span>
            <MenuItem onClick={ () => {
                onCloseMenu();
                onEdit(pathway);
            }}>Muokkaa
            </MenuItem>
            <MenuItem onClick={ () => {
                onCloseMenu();
                const pathwayCopy = duplicatePathway(pathway);
                onEdit(pathwayCopy);
            }}>Kopioi luonnokseksi
            </MenuItem>
            { isAdmin ? (
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowPublishConfirmation(true);
                }}>Julkaise
                </MenuItemAdmin>
            ) : (
                <MenuItem onClick={ () => {
                    onCloseMenu();
                    setShowSuggestConfirmation(true);
                }}>Ehdota julkaistavaksi
                </MenuItem>
            ) }
            <MenuItem onClick={ () => {
                onCloseMenu();
                setShowConfirmation(true);
            }}>Poista
            </MenuItem>
        </span>
    );
    
    const publicMenuContent = pathway && (
        <span>
            <MenuItem onClick={ async () => {
                onCloseMenu();
                await handlePathwayAction(clonePathway);
                onClone(pathway);
            }}>Tallenna omaan kirjastoon
            </MenuItem>
            <MenuItemAdmin onClick={ () => {
                onCloseMenu();
                onEdit(pathway);
            }}>Muokkaa
            </MenuItemAdmin>
            { pathway.status === 'PUBLISHED' &&
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowHideConfirmation(true);
                }}>Piilota
                </MenuItemAdmin>
            }
            { pathway.status === 'HIDDEN' &&
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowPublishConfirmation(true);
                }}>Julkaise
                </MenuItemAdmin>
            }
            { pathway.status === 'PENDING' ? [
                <MenuItemAdmin
                    disabled={ hasRejectedCards }
                    onClick={ () => {
                        onCloseMenu();
                        setShowAcceptConfirmation(true);
                }}>Hyväksy
                </MenuItemAdmin>,
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowRejectConfirmation(true);
                }}>Hylkää
                </MenuItemAdmin>
            ] :
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowConfirmation(true);
                }}>Poista
                </MenuItemAdmin>   
            }
        </span>
    );

    return (
        <Dialog
            scroll="body"
            fullWidth
            maxWidth="sm"
            open={ open }
            onExit={ () => setShowConfirmation(false) }
            onClose={ onClose }
        >
            <AppLoader active={ isFetching || isFetchingCards } />
            <DialogActions>
                { owner ? (
                    <div>
                        <span>{ owner.firstName } { owner.lastName } - { owner.email }</span>
                    </div>
                ) : (
                    <span></span>
                ) }
                <div>
                    <Menu
                        id="action-menu"
                        anchorEl={ anchorEl }
                        anchorOrigin={{ horizontal: -100, vertical: 'top' }}
                        keepMounted
                        open={ !!anchorEl }
                        onClose={ onCloseMenu }
                    >
                        <MenuItem onClick={ handleCreatePDF }>Luo PDF</MenuItem>
                        { isUserPathway ? privateMenunContent : publicMenuContent }
                    </Menu>
                    <IconButton
                        size="small"
                        onClick={ (event) => setAnchorEl(event.currentTarget) }
                    >
                        <MoreHoriz />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={ onClose }
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogActions>
            <DialogContent
                dividers={ true }
            >
                { pathway &&
                    <span>
                        <h2 style={{ marginTop: 0 }}>{ pathway.title || 'Ei otsikkoa' }</h2>
                        <GridList
                            rowLength={ 3 }
                            >
                            { pathway.cards.map((card, index) => {
                                return (
                                    <div
                                        key={ index }
                                        style={{
                                            position: 'relative'
                                        }}>
                                        { !!card ?
                                            <ListItem
                                                key={ card.id }
                                                card={ card }
                                                handleViewCard={ (card) => onViewCard(card) }
                                            /> :
                                            <EmptyListItem key="empty-item" disableAdd={ true } />
                                        }
                                    </div>)
                            })}
                        </GridList>
                    </span>
                }

            </DialogContent>
            { pathway && cards &&
                <PDFDownloadDialog
                    cards={ cards }
                    open={ showPDFDialog }
                    onClose={ () => setShowPDFDialog(false) }
                />
            }
            { pathway && pathway.owner === uid &&
                <ConfirmationDialog
                    open={ showSuggestConfirmation }
                    confirmText="Julkaise"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowSuggestConfirmation(false);
                        const response = await handlePathwayAction(suggestPathway);
                        let pendingPathway = await getPathway(response);
                        let count = 0;
                        pendingPathway.cards.forEach(card => card ? count++ : null);
                        pendingPathway.cards = count;
                        onSuggest(pendingPathway, pathway);
                        onClose();
                    } }
                    onCancel={ () => setShowSuggestConfirmation(false) }
                >
                    Haluatko varmasti ehdottaa oppimispolkua { pathway.title } julkaistavaksi?
                </ConfirmationDialog>
            }
            { pathway &&
                <ConfirmationDialog
                    open={ showPublishConfirmation }
                    confirmText="Julkaise"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowPublishConfirmation(false);
                        await handlePathwayAction(pathway.status === 'HIDDEN' ? publishHiddenPathway : publishPathway);
                        onPublish(pathway);
                        onClose();
                    } }
                    onCancel={ () => setShowPublishConfirmation(false) }
                >
                    Haluatko varmasti julkaista oppimispolun { pathway.title }?
                </ConfirmationDialog>
            }
            { pathway &&
                <ConfirmationDialog
                    open={ showHideConfirmation }
                    confirmText="Piilota"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowHideConfirmation(false);
                        await handlePathwayAction(hidePathway);
                        onHide(pathway);
                        onClose();
                    } }
                    onCancel={ () => setShowHideConfirmation(false) }
                >
                    Haluatko varmasti piilottaa oppimispolun { pathway.title }?
                </ConfirmationDialog>
            }
            { pathway && isAdmin &&
                <ConfirmationDialog
                    open={ showAcceptConfirmation }
                    confirmText="Hyväksy"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowAcceptConfirmation(false);
                        await handlePathwayAction(publishPathway);
                        onPublish(pathway);
                        onClose();
                    } }
                    onCancel={ () => setShowAcceptConfirmation(false)}
                >
                    Haluatko varmasti hyväksyä oppimispolun { pathway.title }?
                </ConfirmationDialog>
            }
            { pathway && isAdmin &&
                <ConfirmationDialog
                    open={ showRejectConfirmation }
                    disabledConfirm={ !selectedCriterias.length }
                    confirmText="Hylkää"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowRejectConfirmation(false);
                        await handlePathwayAction(rejectPathway, selectedCriterias);
                        setSelectedCriterias([]);
                        onReject(pathway);
                        onClose();
                    } }
                    onCancel={ () => setShowRejectConfirmation(false)}
                >
                    <p>Haluatko varmasti hylätä oppimispolun { pathway.title }?</p>
                    <MultipleSelect
                        label="Hylkäämisperusteet *"
                        value={selectedCriterias}
                        options={criteriaList}
                        onChange={(values) => setSelectedCriterias(values) }
                    />
                </ConfirmationDialog>
            }
            { pathway && <ConfirmationDialog
                open={ showConfirmation }
                confirmText="Poista"
                cancelText="Peruuta"
                onConfirm={ async () => {
                    setShowConfirmation(false);
                    await handlePathwayAction(pathway.status === 'PUBLISHED' ? deletePublicPathway : deletePathway)
                    onDelete(pathway);
                    onClose();
                } }
                onCancel={ () => setShowConfirmation(false) }
                >
                Haluatko varmasti poistaa oppimispolun { pathway.title }?
            </ConfirmationDialog>
            }
        </Dialog>
    );
}

export default PathwayViewerDialog;