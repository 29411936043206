import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routers/routes';
import { editCard } from '../../actions/cardEditor';
import FilterForm from './FilterForm';
import ListItem from './ListItem';
import GridList from './GridList';
import Expandable from './Expandable';
import EmptyListItem from './EmptyListItem';
import CardViewerDialog from './CardViewerDialog';
import Alert from '@material-ui/lab/Alert';
import {
    getMyCards,
    getPublishedCards,
    getHiddenCards,
    getPendingCards,
    getRejectedCards,
    getPrivateCards
} from 'services/cardService';
import { AppLoader } from 'utils/AppLoader';
import useFilters from 'hooks/useFilters';
import { useDispatch } from 'react-redux';
import { TablePagination } from '@material-ui/core';
import usePagination from 'hooks/usePagination';

const TAG = '[CardLibraryTab]';

function CardLibraryTab({ published = false }) {
    const history = useHistory();
    const [isFetching, setIsFetching] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedCard, setSelectedCard] = useState();
    const [filters, setFilters] = useState({});
    const [cards, setCards] = useState([]);
    const [error, setError] = useState('');
    const filterCards = useFilters();
    const dispatch = useDispatch();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        initRowsPerPage,
    } = usePagination({
        page: 0,
        rowsPerPage: 64,
    });

    useEffect(() => {
        (async () => {
            setIsFetching(true);
            try {
                let action = getMyCards;
                let status = '';

                if (published) {
                    status = filters.onlyHidden
                    ? 'HIDDEN'
                    : filters.onlyPending
                    ? 'PENDING'
                    : 'PUBLISHED'
                } else {
                    status = filters.status;
                }

                switch (status) {
                    case 'HIDDEN':
                        action = getHiddenCards;
                        break;
                    case 'PRIVATE':
                        action = getPrivateCards;
                        break;
                    case 'PENDING':
                        action = getPendingCards;
                        break;
                    case 'REJECTED':
                        action = getRejectedCards;
                        break;
                    case 'PUBLISHED':
                        action = getPublishedCards;
                        break;
                    default:
                        action = getMyCards;
                        break;
                }

                const cards = await action();
                setCards(cards);
                setIsFetching(false);
            } catch (error) {
                setError('Kortteja ei voitu hakea. Yritä myöhemmin uudelleen');
                setIsFetching(false);
            }
        })();
    }, [published, filters.status, filters.onlyHidden, filters.onlyPending]);

    useEffect(() => {
        handleChangePage(null, 0);
    }, [filters, handleChangePage]);

    const visibleCards = filterCards(cards, { ...filters, orderBy: 'updatedAt', order: 'desc' });
    const gridContent = visibleCards.map((card) => (
        <ListItem key={card.id} card={card} handleViewCard={(card) => setSelectedCard(card)} />
    ));
    if (!published) {
        gridContent.unshift(
            <EmptyListItem key="empty-item" handleAddItem={() => history.push(routes.TEMPLATE_PICKER)} />
        );
    }

    const contentLength = gridContent.length;

    return (
        <div>
            <AppLoader active={isFetching} />
            {error && (
                <Alert className="mb-3" severity="error">
                    {error}
                </Alert>
            )}
            <Expandable label="Suodata kortteja">
                <FilterForm
                    onChange={({ values }) => setFilters(values)}
                    hiddenFilter={ published }
                />
            </Expandable>
            { successMessage && <Alert className="mb-2" severity="success">{ successMessage }</Alert>}
            <GridList
                rowLength={8}
                minItemWidth="150px"
                currentPage={page}
                cardsPerPage={rowsPerPage}
            >
                {gridContent}
            </GridList>
            <table className="grid-list-pagination">
                <tbody>
                    <tr>
                        <TablePagination
                            labelRowsPerPage="Näytä sivulla"
                            page={page}
                            count={contentLength}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            //labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                            labelDisplayedRows={({ from, to, count, page }) =>
                                `${page + 1}/${Math.ceil(count / rowsPerPage)}`
                            }
                            rowsPerPageOptions={[initRowsPerPage]}
                        />
                    </tr>
                </tbody>
            </table>
            <CardViewerDialog
                open={!!selectedCard}
                cardId={selectedCard && selectedCard.id}
                cardStatus={selectedCard && selectedCard.status}
                onlyHidden={filters.onlyHidden}
                onlyPending={filters.onlyPending}
                onClose={() => setSelectedCard(null)}
                onEdit={(card) => {
                    dispatch(editCard(card));
                    history.push(routes.EDIT_CARD);
                }}
                onSuggest={(suggested, card) => {
                    if (card.status === 'REJECTED'){
                      let array = cards.filter(cardItem => cardItem.id !== card.id)
                      array = array.concat(suggested);
                      setCards(array);
                    }else {
                      setCards(cards.concat(suggested));
                    } 
                    setSuccessMessage(`Kortti ${ suggested.title } on lähetetty adminille tarkasteltavaksi.`);
                }}
                onPublish={(published) => {
                    if (filters.onlyHidden || filters.onlyPending) {
                        setCards(cards.filter(pathway => pathway.id !== published.id));
                    }
                    setSuccessMessage(`Kortti ${ published.title } on julkaistu opettajille.`);
                }}
                onReject={(rejected) => {
                    setCards(cards.filter(pathway => pathway.id !== rejected.id));
                    setSuccessMessage(`Kortti ${ rejected.title } on hylätty.`);
                }}
                onHide={(hidden) => {
                    setCards(cards.filter(pathway => pathway.id !== hidden.id));
                    setSuccessMessage(`Kortti ${ hidden.title } on piilotettu opettajilta.`);
                }}
                onDelete={(deleted) => {
                    setCards(cards.filter(pathway => pathway.id !== deleted.id));
                    setSuccessMessage(`Kortti ${ deleted.title } poistettu onnistuneesti!`);
                }}
            />
        </div>
    );
}

export default CardLibraryTab;
