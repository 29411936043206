import { cloneDeep } from 'lodash';
import { getVisibleCards } from '../reducers/utils';

export const addCard = (id) => {
    return {
        type: 'RADD_CARD',
        id
    }
}

export const createPathway = (author) => {
    return {
        type: 'CREATE_PATHWAY',
        author
    };
}

export const editPathway = (pathway) => {
    return {
        type: 'EDIT_PATHWAY',
        pathway
    }
};

export const updateUserPathway = (update) => {
    return {
        type: 'UPDATE_PATHWAY',
        update
    }
};

export const cleanPathwayEditor = () => {
    return {
        type: 'CLEAN_PATHWAY'
    }
};

export const undoChanges = () => {
    return {
        type: 'UNDO_CHANGES'
    }
};

export const closePathwayEditor = () => {
    return {
        type: 'CLOSE_PATHWAY_EDITOR'
    }
};