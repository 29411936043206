import React, { useState } from 'react';
import { withPermissions } from 'utils/withPermissions';
import useForm from 'hooks/useForm';
import { validateEditProfile } from 'validation/validate';
import { Button, TextField } from '@material-ui/core';
import { AppLoader } from 'utils/AppLoader';
import { onUpdateProfileSuccess } from 'actions/auth';
import { updateMe } from 'services/authService';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const TAG = '[ProfileEditor]';

const ProfileEditor = ({
    user,
    onClose,
    onUpdate
}) => {
    const dispatch = useDispatch();
    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(onSubmit, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email
    }, validateEditProfile);

    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');

    async function onSubmit({ values }) {
        console.log(TAG, values);
        try {
            setIsFetching(true);
            const updatedUser = await updateMe(values);
            dispatch(onUpdateProfileSuccess(updatedUser));
            setIsFetching(false);
            onUpdate();
            onClose();
        } catch(error) {
            setError('Tapahtui virhe. Ole hyvä ja yritä myöhemmin uudelleen');
            setIsFetching(false);
        }
    }

    return (
        <>
            <AppLoader isFetching={ isFetching } />
            { error && <Alert className="mb-3" severity="error">{ error }</Alert> }
            <form
                className="d-flex flex-column create-teacher-form"
                onSubmit={ handleSubmit }
            >
            <TextField
                error={ !!errors.firstName }
                helperText={ errors.firstName }
                name="firstName"
                value={ values.firstName || '' }
                onChange={ handleChange }
                size="small"
                label="Etunimi"
                variant="outlined"
            />
            <TextField
                error={ !!errors.lastName }
                helperText={ errors.lastName }
                name="lastName"
                value={ values.lastName || '' }
                onChange={ handleChange }
                size="small"
                label="Sukunimi"
                variant="outlined"
            />
            <TextField
                error={ !!errors.email }
                helperText={ errors.email }
                name="email"
                value={ values.email || '' }
                onChange={ handleChange }
                size="small"
                label="Sähköpostiosoite"
                variant="outlined"
            />
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={ onClose }
                    type="button"
                >
                    Peruuta
                </Button>
                <Button
                    className="ml-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Tallenna
                </Button>
            </div>
        </form>
        </>
    );
}

export default withPermissions(ProfileEditor, null, ['ADMIN']);