import React from 'react';
import { connect } from 'react-redux';
import { convertToRaw, ContentState, Editor, EditorState} from 'draft-js';
import { Fab } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { closeNodeEditor, undoChanges } from '../../actions/cardEditor';
import './TitleEditor.css';

class TitleEditor extends React.Component {
  constructor(props) {
    super(props);
    const editorState = !!this.props.data ? this.initializeWithText(this.props.data) : EditorState.createEmpty();
    this.state = {
        editorState,
    };
    this.editorRef = React.createRef();
  };

  componentDidMount = () => {
    this.editorRef.current.focus();
  };

  initializeWithText = (string) => {
    return EditorState.createWithContent(ContentState.createFromText(string))
  };

  convertContentToStringAndDispatch = () => {
    const editorState = this.state.editorState;
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const blockText = blocks.map((block) => block.text);
    const data = blockText.join('\n');
    this.props.dispatch(this.props.onSaveChanges(data));
    this.props.dispatch(closeNodeEditor());
  };

  undoChanges = () => {
    this.props.dispatch(undoChanges());
    this.props.dispatch(closeNodeEditor());
  };

  render() {
    return (
      <div style={{ fontSize: this.props.fontSize, height: '100%' }}>
        <Editor ref={ this.editorRef }
          placeholder="Kirjoita tähän..."
          editorState={this.state.editorState}
          onChange={ (editorState) => this.setState({
            editorState
          })}
        />
        <div className="title-text-editor-buttons">
            <Fab color="primary" size="small"
              onClick={ this.convertContentToStringAndDispatch }><CheckCircleOutlineIcon /></Fab>
        </div>
      </div>
    );
  }
}

export default connect(null)(TitleEditor);