import {
    filter,
    orderBy as sort
} from 'lodash';

const useFilters = () => {
    return (cards, { competence, subject, grade, metacognitive, group, text, orderBy = 'title', order = 'asc' }) => {
        const filteredCards = filter(cards, (card) => {
            const competenceMatch = !competence || card.competence === competence;
            const subjectMatch = !subject || card.subjects.includes(subject);
            const gradeMatch = !grade || card.grades.includes(grade);
            const metacognitiveMatch = !metacognitive || card.metacognitiveTool === metacognitive;
            const groupMatch = !group || card.group === group;
            const titleMatch = !text || !(text.length > 2) || card.title.toLowerCase().includes(text.toLowerCase());
            return competenceMatch && subjectMatch && gradeMatch && metacognitiveMatch && groupMatch && titleMatch;
        });
        return sort(filteredCards, orderBy, order);
    };
}

export default useFilters;