import React from 'react'
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import AppLoader from './AppLoader';
import './css/HorizontalList.css';

class HorizontalList extends React.Component {
    constructor() {
        super();
        this.listRef = React.createRef();
        this.state = {
            disableLeft: true,
            disableRight: true,
            scrolling: false
        };
    }

    componentDidMount() {
        this.checkChevrons();
        window.addEventListener('resize', this.checkChevrons);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkChevrons);
    }

    componentDidUpdate(prevProps) {
        if (this.props.children.length !== prevProps.children.length) {
            this.checkChevrons();
        }
    };

    checkChevrons = () => {
        const list = this.listRef.current;
        const disableLeft = list.scrollLeft === 0;
        const disableRight = list.scrollWidth - (this.props.itemSpacing || 10) - list.scrollLeft <= list.clientWidth;
        this.setState({
            disableLeft,
            disableRight
        });
    };

    scrollX = (direction) => {
        const list = this.listRef.current;
        const width = list.getBoundingClientRect().width;
        const itemWidth = this.props.itemWidth + (this.props.itemSpacing || 10);
        const leftOverSpace = width % itemWidth;
        const scrollAmount = direction * (width - leftOverSpace);
        const scrollLeft = list.scrollLeft;
        const left = Math.max(scrollLeft + scrollAmount, 0);
        list.scrollTo({
            top: 0,
            left,
            behavior: 'smooth'
        });
    };

    scrollRight = () => {
        this.scrollX(1);
    };

    scrollLeft = () => {
        this.scrollX(-1);
    };

    handleListScroll = (event) => {
        this.checkChevrons();
    };

    handleMouseDown = (event) => {
        this.startScrolling();
    };

    handleMouseMove = (event) => {
        if (!this.state.scrolling) {
            return;
        }
        const list = this.listRef.current;
        const left = list.scrollLeft - 0.8 * event.movementX;
        list.scrollLeft = left;
    };

    startScrolling = () => {
        this.setState({
            scrolling: true
        });
    };

    stopScrolling = () => {
        this.setState({
            scrolling: false
        });
    };

    render() {
        const {
            height,
            loading,
            itemWidth,
            itemSpacing = 10,
            children
        } = this.props;

        const items = children.map((child, index) => (
            <div key={ index } className="horizontal-list-item-wrapper"
                style={{
                    width: `${ itemWidth }px`,
                    marginRight: `${ itemSpacing }px`
                }}
            >
                { child }
            </div>)
        );

        return (
            <div className="horizontal-list-wrapper">
                <IconButton
                    disabled={ this.state.disableLeft }
                    onClick={ () => this.scrollLeft() }
                    className="horizontal-list-scroll-button"
                    variant="contained"
                    color="primary"
                >
                    <ChevronLeft fontSize="large" />
                </IconButton>
                <div className="horizontal-list-frame"
                    onMouseDown={ this.handleMouseDown }
                    onMouseUp={ this.stopScrolling }
                    onMouseMove={ this.handleMouseMove }
                    onMouseLeave={ this.stopScrolling }
                    onScroll={ this.handleListScroll }
                    ref={ this.listRef }>
                    { loading && <AppLoader type="component" /> }
                    <div className="horizontal-list" style={{ height }}>
                        { items }
                    </div>
                </div>
                <IconButton
                    disabled={ this.state.disableRight }
                    onClick={ () => this.scrollRight() }
                    className="horizontal-list-scroll-button"
                    variant="contained"
                    color="primary"
                >
                    <ChevronRight fontSize="large" />
                </IconButton>
            </div>
        )
    };
}

export default HorizontalList;