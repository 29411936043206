import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';

const PrivateRoute = ({
    children,
    ...rest
}) => {
    const user = useSelector(state => state.auth.user);
    return (
        <Route { ...rest } render={ ({ location }) => {
            return user ?
                (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: routes.LOGIN,
                            state: { from: location }
                        }}
                    />
                );
            }}
        />
    )
};

export default PrivateRoute;
