import template_1 from '../../assets/images/templates/card_template_1.png';
import template_2 from '../../assets/images/templates/card_template_2.png';
import template_3 from '../../assets/images/templates/card_template_3.png';
import template_4 from '../../assets/images/templates/card_template_4.png';
import template_5 from '../../assets/images/templates/card_template_5.png';
import template_6 from '../../assets/images/templates/card_template_6.png';

import book from '../../assets/images/tools/book.png';
import laptop from '../../assets/images/tools/laptop.png';
import eraser from '../../assets/images/tools/eraser.png';
import headphones from '../../assets/images/tools/headphones.png';
import pencil from '../../assets/images/tools/pencil.png';
import phone from '../../assets/images/tools/phone.png';
import scissors from '../../assets/images/tools/scissors.png';
import tablet from '../../assets/images/tools/tablet.png';
import glue from '../../assets/images/tools/glue.png';
import photocopy from '../../assets/images/tools/photocopy.png';
import triangle from '../../assets/images/tools/triangle.png';
import images from '../../assets/images/tools/images.png';
import v_aakkoskortit from '../../assets/images/tools/v_aakkoskortit.png';
import v_aapinen from '../../assets/images/tools/v_aapinen.png';
import v_harjoituskirja from '../../assets/images/tools/v_harjoituskirja.png';
import v_harppi from '../../assets/images/tools/v_harppi.png';
import v_havuoksa from '../../assets/images/tools/v_havuoksa.png';
import v_hyppynaru from '../../assets/images/tools/v_hyppynaru.png';
import v_kaatunutpuu from '../../assets/images/tools/v_kaatunutpuu.png';
import v_kahvakuula from '../../assets/images/tools/v_kahvakuula.png';
import v_kamera from '../../assets/images/tools/v_kamera.png';
import v_kansio from '../../assets/images/tools/v_kansio.png';
import v_kanto from '../../assets/images/tools/v_kanto.png';
import v_kapy from '../../assets/images/tools/v_kapy.png';
import v_kello from '../../assets/images/tools/v_kello.png';
import v_keppi from '../../assets/images/tools/v_keppi.png';
import v_kiikarit from '../../assets/images/tools/v_kiikarit.png';
import v_kirja from '../../assets/images/tools/v_kirja.png';
import v_kirjainkortit from '../../assets/images/tools/v_kirjainkortit.png';
import v_kuorimaveitsi from '../../assets/images/tools/v_kuorimaveitsi.png';
import v_leadoteline from '../../assets/images/tools/v_leadoteline.png';
import v_lego from '../../assets/images/tools/v_lego.png';
import v_lehti from '../../assets/images/tools/v_lehti.png';
import v_lehtioksa from '../../assets/images/tools/v_lehtioksa.png';
import v_liitutaulu from '../../assets/images/tools/v_liitutaulu.png';
import v_luuppi from '../../assets/images/tools/v_luuppi.png';
import v_mikrofoni from '../../assets/images/tools/v_mikrofoni.png';
import v_muovailuvaha from '../../assets/images/tools/v_muovailuvaha.png';
import v_nopat from '../../assets/images/tools/v_nopat.png';
import v_numerokortit from '../../assets/images/tools/v_numerokortit.png';
import v_nuotti from '../../assets/images/tools/v_nuotti.png';
import v_paperi from '../../assets/images/tools/v_paperi.png';
import v_peli from '../../assets/images/tools/v_peli.png';
import v_puomit from '../../assets/images/tools/v_puomit.png';
import v_sadetakki from '../../assets/images/tools/v_sadetakki.png';
import v_suurennuslasi from '../../assets/images/tools/v_suurennuslasi.png';
import v_tiimalasi from '../../assets/images/tools/v_tiimalasi.png';
import v_tussi from '../../assets/images/tools/v_tussi.png';
import v_varinopat from '../../assets/images/tools/v_varinopat.png';

import single from '../../assets/images/icons/single.png';
import pairs from '../../assets/images/icons/pairs.png';
import teams from '../../assets/images/icons/teams.png';

export const templateImages = {
    template_1,
    template_2,
    template_3,
    template_4,
    template_5,
    template_6,
};

export const toolIcons = {
    book,
    laptop,
    eraser,
    headphones,
    pencil,
    phone,
    scissors,
    tablet,
    glue,
    photocopy,
    images,
    triangle,
    v_aakkoskortit,
    v_aapinen,
    v_harjoituskirja,
    v_harppi,
    v_havuoksa,
    v_hyppynaru,
    v_kaatunutpuu,
    v_kahvakuula,
    v_kamera,
    v_kansio,
    v_kanto,
    v_kapy,
    v_kello,
    v_keppi,
    v_kiikarit,
    v_kirja,
    v_kirjainkortit,
    v_kuorimaveitsi,
    v_leadoteline,
    v_lego,
    v_lehti,
    v_lehtioksa,
    v_liitutaulu,
    v_luuppi,
    v_mikrofoni,
    v_muovailuvaha,
    v_nopat,
    v_numerokortit,
    v_nuotti,
    v_paperi,
    v_peli,
    v_puomit,
    v_sadetakki,
    v_suurennuslasi,
    v_tiimalasi,
    v_tussi,
    v_varinopat,
};

export const groupIcons = {
    single,
    pairs,
    teams,
};
