import React from 'react';
import './css/GridList.css';

const GridList = ({
    children,
    rowLength = 3,
    spacing = 6,
    minItemWidth = '100px',
    wrap = true,
    currentPage,
    cardsPerPage,
}) => {
    const itemWidth = 100 / rowLength;
    const style = {
        flexBasis: `${itemWidth}%`,
        padding: spacing,
        minWidth: minItemWidth,
    };

    if (cardsPerPage) {
        children.splice(0, currentPage * cardsPerPage);
        if (children.length > cardsPerPage) {
            children.splice(cardsPerPage, children.length - cardsPerPage);
        }
    }

    return (
        <div className="grid-list">
            {children.map((child) => {
                return (
                    <div key={child.key} className="grid-list-item-wrapper" style={style}>
                        <div className="grid-list-item-aspect-ratio">
                            <div className="grid-list-item">{child}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GridList;
