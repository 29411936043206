import React from 'react';
import { Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    contentImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain'
    }
});

class PDFContentImage extends React.Component {
    render() {
        return (
            <Image style={ styles.contentImage }
                // debug={ true }
                src={ this.props.data === null ? `${process.env.REACT_APP_IMAGES_BASE_URL}/${this.props.filename}` : this.props.data } />
        );
    }
};

export default PDFContentImage;