import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { AccessTime, HighlightOff } from '@material-ui/icons';
import './css/ListItem.css';
import logo from 'assets/images/logo_medium.png';

const PathwayItem = ({ pathway, dispatch, handleViewPathway }) => {
    return (
        <div
            className="list-item"
            style={{
                backgroundColor: 'white',
                borderColor: 'blue'
            }}
        >
            <div className="list-item-title">{ pathway.title }</div>
            { pathway.status === "PENDING" && (
                <div className="list-item-logo"><AccessTime fontSize='large' htmlColor='#fdd736' /></div>
            ) }
            { pathway.status === "REJECTED" && (
                <div className="list-item-logo"><HighlightOff fontSize='large' htmlColor='#ff1644'/></div>
            ) }
            { pathway.status === 'PUBLISHED' && (
                <div className="list-item-logo"><img draggable="false" src={ logo } alt="leado-logo"/></div>
            ) }
            <div>Kortteja { pathway.cards }</div>
            <div className="list-item-actions">
                <span>
                    <Button variant="outlined" size="small" onClick={ () => handleViewPathway(pathway) }
                        style={{
                            border: '1px solid blue'
                        }}>
                        Näytä
                    </Button>
                </span>
            </div>

        </div>
    );
};

export default connect(null)(PathwayItem);