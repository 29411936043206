import React, { useState } from 'react';
import TeacherForm from '../TeacherForm';
import { DialogTitle, DialogContent } from '@material-ui/core';
import useForm from 'hooks/useForm';
import { validateEditTeacher } from 'validation/validate';
import { updateUser } from 'services/userService';
import { AppLoader } from 'utils/AppLoader';
import Alert from '@material-ui/lab/Alert';

const TAG = '[TeacherEditor]';

const TeacherEditor = ({
    onAddSchool,
    onClose,
    onUpdate,
    schools,
    teacher
}) => {
    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(onSubmit, {
        firstName: teacher.firstName,
        lastName: teacher.lastName,
        email: teacher.email,
        school: teacher.school
    }, validateEditTeacher);
    const [error, setError] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    async function onSubmit({ values }) {
        const update = {
            ...values,
            schoolId: values.school ? values.school.id : null
        };
        delete update.school;
        try {
            setIsFetching(true);
            await updateUser(teacher.id, update);
            setIsFetching(false);
            onUpdate();
            onClose()
        } catch(error) {
            if (error.response.data.message === 'UserEmailUnique') {
                setError('Sähköpostiosoite on jo käytössä.');
            } else {
                setError('Tapahtui virhe. Ole hyvä ja yritä myöhemmin uudelleen.');
            }
            setIsFetching(false);
        }
    }

    console.log(error);

    return (
    <>
        <AppLoader active={ isFetching } />
        <DialogTitle>
            Muokkaa opettajaa { teacher.firstName } { teacher.lastName }
        </DialogTitle>
        <DialogContent>
            { error && <Alert className="mb-3" severity="error">{ error }</Alert> }
            <TeacherForm
                errors={ errors }
                values={ values }
                edit={ true }
                onCancel={ onClose }
                onSubmit={ handleSubmit }
                onChange={ handleChange }
                schools={ schools }
                onAddSchool={ onAddSchool }
            />
        </DialogContent>
    </>
    )
}

export default TeacherEditor;