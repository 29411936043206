import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import QRCode from 'qrcode';
import './ImageEditorDialog.css';

function QREditorDialog({ open, onClose, onSubmit }) {
    const [data, setData] = useState(null);
    const [completed, setCompleted] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [link, setLink] = useState('');

    useEffect(() => {
        setData(null);
        setCompleted(0);
        setUploading(false);
        setLink('');
    }, [open]);

    async function updateQR() {
        const qrCode = await generateQR(link);

        let imgType = '';
        let imgTypeArray = qrCode.match(/^data:image\/(\w+);.*/i);
        if(imgTypeArray !== null) {
            imgType = imgTypeArray[1];
        } else {
            console.error("Error! Couldn't parse image type!");
            return;
        }

        const qrData = {
          filename: null,
          data: qrCode,
          type: imgType
        }
        
        onSubmit(qrData);
    };        
    
    async function generateQR (text) {
      try {
        const qrCode = await QRCode.toDataURL(text)
        return qrCode;
      } catch (err) {
        console.error(err)
      }
    };

    

    return (
        <Dialog open={open}>
            <DialogTitle>Lisää linkki tai teksti</DialogTitle>
            <DialogContent>
                <div className="image-input-wrapper">
                    <input
                        type="text"
                        onChange={e => setLink(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    disabled={ link.length < 3 }
                    onClick={ () => updateQR() }
                >
                    Valmis
                </Button>
                <Button
                    variant="outlined"
                    disabled={ uploading }
                    onClick={ onClose }
                >
                    Peruuta
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default QREditorDialog;
