import React, { useState, useEffect } from 'react';
import { Prompt, useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ConfirmationDialog from '../../components/layout/ConfirmationDialog';
import CardEditor from '../../components/editor/CardEditor';
import AppLoader from '../../components/layout/AppLoader';
import { closeEditor, editCard, } from '../../actions/cardEditor';
import { createCard, updateCard, getCard, getPendingCard } from 'services/cardService';
import { routes } from 'routers/routes';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const TAG = '[CardEditorPage]';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    }
  });

function CardEditorPage() {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const dispatch = useDispatch();
    const card = useSelector((state) => state.cardEditor.card);
    const updates = useSelector((state) => state.cardEditor.updates);
    const editor = useSelector((state) => state.cardEditor.editor);
    const isFetching = useSelector((state) => state.cardEditor.isFetching);
    const [isSaving, setIsSaving] = useState(false);
    const visibleCard = useSelector((state) => ({
        ...state.cardEditor.card,
        ...state.cardEditor.updates
    }));
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        return () => {
            dispatch(closeEditor());
        }
    }, [dispatch]);

    async function handleSaveCard() {

        function handleError(error) {
            if (error.response.status === 413) {
                setError('Korttia ei voitu tallentaa. Lisäämäsi kuvatiedostot ovat liian suuria. Pienennä kuvatiedostojen kokoa ja yritä uudelleen.');
            } else {
                setError('Korttia ei voitu tallentaa. Yritä myöhemmin uudelleen.');
            }
            setSuccess(false);
        }

        if (card && card.id) {
            try {
                setIsSaving(true);
                const updatedCardId = await updateCard(card.id, visibleCard);
                const updatedCard = card.status === 'PENDING'
                    ? await getPendingCard(updatedCardId)
                    : await getCard(updatedCardId);
                dispatch(editCard(updatedCard));
                setError(null);
                setSuccess(true);
                setIsSaving(false);
            } catch(error) {
                console.error(error);
                handleError(error);
                setIsSaving(false);
            }
        } else if (card) {
            try {
                setIsSaving(true);
                const insertedCard = await createCard(visibleCard);
                dispatch(editCard(insertedCard));
                setSuccess(true);
                setError(null);
                setIsSaving(false);
            } catch(error) {
                console.error(error);
                handleError(error);
                setIsSaving(false);
            }
        } else {
            console.error(TAG, 'no card to save');
        }
    };

    function handleCloseEditor() {
        history.goBack();
    };

    const dirty = !(isEmpty(updates));

    if (!card) {
        return <Redirect to={ routes.TEMPLATE_PICKER } />
    }

    return (
        <div className="app-wrapper">
            <AppLoader active={ isFetching || isSaving } />
            {error && (
                <Alert className="mb-3" severity="error">
                    {error}
                </Alert>
            )}
            { success && <Alert className="mb-3" severity="success">Kortti on tallennettu onnistuneesti!</Alert>}
            <Grid container>
                <Grid item xs={ 8 }>
                <Typography
                    variant="h4"
                    component="h1"
                    className="mb-5"
                >
                    Muokkaa korttia
                </Typography>
                </Grid>
                <Grid
                    item
                    xs={ 4 }
                    className={ classes.buttons }
                >
                    <Button
                        disabled={ isFetching || !!editor }
                        variant="contained"
                        color="secondary"
                        onClick={ handleCloseEditor }>
                        Sulje
                    </Button>
                    { !!card.content.length &&
                        <Button
                            className="ml-3"
                            disabled={ isFetching || !!editor || !dirty }
                            variant="contained"
                            color="primary"
                            onClick={ handleSaveCard }
                        >
                            Tallenna
                        </Button>
                    }
                </Grid>
            </Grid>
            <Prompt
                when={ dirty }
                message="Kortissa on tallentamattomia muutoksia, jotka häviävät suljettaessa. Haluatko varmasti sulkea muokkauksen?"
            />
            <CardEditor
                card={ visibleCard }
                dirty={ dirty }
                isFetching={ isFetching }
                onClose={ handleCloseEditor }
                onSave={ handleSaveCard }
            />
            <ConfirmationDialog
                open={ showConfirmation }
                confirmText="Sulje"
                cancelText="Peruuta"
                onConfirm={ () => {
                    setShowConfirmation(false);
                    history.goBack();
                } }
                onCancel={ () => setShowConfirmation(false) }
                >
                Kortissa on tallentamattomia muutoksia, jotka häviävät suljettaessa. Haluatko varmasti sulkea muokkauksen?
            </ConfirmationDialog>
        </div>
    );
}

export default CardEditorPage;