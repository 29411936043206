import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
	Route,
	Redirect
} from 'react-router-dom';
import PrivateRoute from 'routers/PrivateRoute';
import DashboardPage from 'pages/DashboardPage'
import LibraryPage from 'pages/LibraryPage'
import LoginPage from 'pages/LoginPage';
import SupportPage from 'pages/SupportPage';
import ProfilePage from 'pages/ProfilePage';
import TemplatePickerPage from 'pages/TemplatePickerPage';
import CardEditorPage from 'pages/CardEditorPage';
import PathwayEditorPage from 'pages/PathwayEditorPage';
import { routes } from 'routers/routes';
import Layout from 'components/layout/Layout';
import { Typography } from '@material-ui/core';
import { withPermissions } from 'utils/withPermissions';
import TeachersPage from 'pages/TeachersPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ScrollToTop from 'utils/ScrollToTop';
import Page from 'components/layout/Page';
import useUser from 'hooks/useUser';

const AdminRoute = withPermissions(PrivateRoute, <Redirect to={ routes.DASHBOARD } />, ['ADMIN']);

const AppRouter = () => {
	const user = useUser();
	const isAdmin = user && user.roles.includes('ADMIN');

    return (
		<Router>
      		<ScrollToTop />
			<Switch>
				<Route exact path={ routes.LOGIN }>
					<Layout className="LoginPage">
						<LoginPage />
					</Layout>
				</Route>
				<Route exact path={ routes.HOME }>
					<Layout>
						<Redirect to={ routes.DASHBOARD }/>
					</Layout>
				</Route>
				<PrivateRoute path={ routes.LIBRARY }>
					<Layout>
						<Page title="Oma kirjasto">
							<LibraryPage />
						</Page>
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.LEADO } >
					<Layout>
						<Page title="LeaDo-kirjasto">
							<LibraryPage published={ true } />
						</Page>
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.PROFILE } >
					<Layout>
						<Page title="Oma tili">
							<ProfilePage />
						</Page>
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.SUPPORT } >
					<Layout>
						<Page title="Tuki ja ohjeet">
							<SupportPage />
						</Page>
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.TEMPLATE_PICKER } >
					<Layout>
						<TemplatePickerPage />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.EDIT_CARD } >
					<Layout>
						<CardEditorPage />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.DASHBOARD } >
					<Layout>
						<Page title="Tervetuloa LeaDoon!">
							<DashboardPage />
						</Page>
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ routes.EDIT_PATHWAY } >
					<Layout>
						<PathwayEditorPage />
					</Layout>
				</PrivateRoute>
				<AdminRoute path={ `${ routes.TEACHERS }/:teacherId?` }>
					<Layout className="TeachersPage">
						<Page title="Opettajat">
							<TeachersPage />
						</Page>
					</Layout>
				</AdminRoute>
				<Route exact path={ routes.FORGOT_PASSWORD }>
					<Layout>
						<ForgotPasswordPage />
					</Layout>
				</Route>
				<Route exact path={ `${ routes.RESET_PASSWORD }/:id/:token` }>
					<Layout>
						<ResetPasswordPage />
					</Layout>
				</Route>
				<Route>
					<Layout>
						<Typography variant="h3" component="h1">Sivua ei löydy</Typography>
					</Layout>
				</Route>
			</Switch>
		</Router>
    )
};

export default AppRouter;
