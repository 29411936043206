import { axiosApi as api } from 'api';

export async function getTeachers() {
    const response = await api.get('/users/teachers');
    return response.data.users;
}

export async function getUser(id) {
    const response = await api.get(`/users/${ id }`);
    return response.data.user;
}

export async function deleteUser(id) {
    return api.delete('/users/' + id);
}

export async function updateUser(id, update) {
    return api.put('/users/' + id, update);
}