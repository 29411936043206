import React from 'react';
import { useSelector } from 'react-redux';
import CardTab from 'components/layout/CardTab';
import PathwaysTab from 'components/layout/PathwaysTab';
import AppLoader from 'components/layout/AppLoader';
import { Tabbed } from 'components/layout/Tabbed';

function DashboardPage() {
    const isFetching = useSelector(state => state.metadata.isFetching);
    return (
      <div className="app-wrapper">
        <AppLoader active={ isFetching } />
        <Tabbed
          cardTab={ <CardTab /> }
          pathwaysTab={ <PathwaysTab /> }
        />
      </div>
    );
}

export default DashboardPage;