import { axiosApi as api } from 'api';

export async function getMyCards() {
    const response = await api.get('/users/me/cards');
    return response.data.cards;
}

export async function getPrivateCards() {
    const response = await api.get('/users/me/cards/private');
    return response.data.cards;
}

export async function getPendingCard(id) {
    const response = await api.get(`/cards/${ id }/pending`);
    return response.data;
}

export async function getPendingCards() {
    const response = await api.get('/cards/pending');
    return response.data.cards;
}

export async function getRejectedCard(id) {
    const response = await api.get(`/cards/${ id }/rejected`);
    return response.data;
}

export async function getRejectedCards() {
    const response = await api.get('/cards/rejected');
    return response.data.cards;
}

export async function getPublishedCards() {
    const response = await api.get('/cards/published');
    return response.data.cards;
}

export async function getHiddenCard(id) {
    const response = await api.get(`/cards/${ id }/hidden`);
    return response.data;
}

export async function getHiddenCards() {
    const response = await api.get('/cards/hidden');
    return response.data.cards;
}

export async function getLatestCards() {
    const response = await api.get('/cards/published/latest');
    return response.data.cards;
}

export async function getCard(id) {
    const response = await api.get(`/cards/${ id }`);
    return response.data;
}

export async function createCard(card) {
    const response = await api.post('/users/me/cards', card);
    return response.data.card;
}

export async function updateCard(id, update) {
    const response = await api.put(`/users/me/cards/${ id }`, update);
    return response.data.id;
}


export async function copyCard(id) {
    const response = await api.post(`/cards/${ id }/clone`);
    return response.data;
}

export async function suggestCard(id) {
    const response = await api.post(`/users/me/cards/${ id }/suggest`);
    return response.data;
}

export async function publishCard(id) {
    const response = await api.post(`/users/me/cards/${ id }/publish`);
    return response.data;
}

export async function rejectCard(id, formData) {
    const response = await api.put(`/cards/${ id }/reject`, formData);
    return response.data;
}

export async function publishHiddenCard(id) {
    const response = await api.put(`/cards/${ id }/publish`);
    return response.data;
}

export async function hideCard(id) {
    const response = await api.put(`/cards/${ id }/hide`);
    return response.data;
}

export async function deleteCard(id) {
    const response = await api.delete(`/users/me/cards/${ id }`);
    return response.data;
}

export async function deletePublicCard(id) {
    const response = await api.delete(`/cards/${ id }`);
    return response.data;
}

export async function cloneCard(id) {
    const response = await api.post(`/cards/${ id }/clone`);
    return response.data.id;
}
