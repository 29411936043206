import React from 'react';
import {
    Button,
    TextField
} from '@material-ui/core';
import './LoginForm.css';
import useForm from 'hooks/useForm';
import { validateLogin } from 'validation/validate';

export default function LoginForm({
    onLogin
}) {
    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(onLogin, {}, validateLogin);

    return (
        <form className="login-form d-flex flex-column" onSubmit={ handleSubmit }>
            <TextField
                error={ !!errors.email }
                helperText={ errors.email }
                name="email"
                value={ values.email || '' }
                onChange={ handleChange }
                size="small"
                label="Käyttäjänimi"
                variant="outlined"
            />
            <TextField
                error={ !!errors.password }
                helperText={ errors.password }
                name="password"
                value={ values.password || '' }
                onChange={ handleChange }
                size="small"
                type="password"
                label="Salasana"
                variant="outlined"
            />
            <Button
                variant="contained"
                color="primary"
                type="submit"
            >
                Kirjaudu sisään
            </Button>
        </form>
    );
};