const initialState = {
    isFetching: false,
    templates: []
};

export function templates(state = initialState, action) {
    switch(action.type) {
        case 'FETCH_TEMPLATES_BEGIN': {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'FETCH_TEMPLATES_SUCCESS': {
            const templates = action.templates || state.templates.slice();
            return {
                ...state,
                templates,
                isFetching: false
            }
        }
        case 'FETCH_TEMPLATES_FAILURE': {
            return {
                ...state,
                isFetching: false
            }
        }
        default:
            return state;
    }
}