import React from 'react';
import VideoEmbed from '../VideoEmbed';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function VideoItem({
    title,
    videoId
}) {
    return (
        <Grid
            item
            xs={ 12 }
            md={ 6 }
        >
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography variant="h6" component="h3" gutterBottom={ true }>{ title }</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <VideoEmbed title={ title } videoId={ videoId } />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}