export function loader(state = { loading: false }, action) {
    switch(action.type) {
        case 'LOAD_APP': {
            return {
                loading: true
            };
        }
        case 'ON_DONE_LOADING': {
            return {
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}