import React from 'react';
import { useSelector } from 'react-redux';
import { Button, IconButton, InputAdornment, MenuItem, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { SingleSelect } from '../editor/forms/FormComponents';
import useForm from 'hooks/useForm';
import './css/FilterForm.css';
import { withPermissions } from 'utils/withPermissions';

function FilterForm({ defaultValues, onChange, hiddenFilter }) {
    const { values, handleChange, handleSubmit, resetField, resetForm } = useForm(
        onChange,
        defaultValues || {
            text: '',
            competence: '',
            group: '',
            subject: '',
            grade: '',
            metacognitive: '',
            status: '',
            onlyHidden: false,
            onlyPending: false,
            orderBy: 'title',
            order: 'asc',
        },
        false,
        {
            submitOnChange: true,
        }
    );
    const metadata = useSelector((state) => state.metadata);
    const AdminFormControlLabel = withPermissions(FormControlLabel, null, ['ADMIN']);

    return (
        <form className="search-cards-form-wrapper" onSubmit={handleSubmit}>
            <TextField
                className="search-cards-form-item-first"
                value={values.text}
                size="small"
                label="Otsikko"
                variant="outlined"
                name="text"
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size="small" onClick={() => resetField('text')}>
                                <Cancel />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <SingleSelect
                className="search-cards-form-item"
                label="Laaja-alaiset osaamistavoitteet"
                disabled={false}
                name="competence"
                value={values.competence}
                onChange={handleChange}
            >
                <MenuItem value={''}>Ei rajausta</MenuItem>
                {metadata.competences.map((competence) => (
                    <MenuItem key={competence.id} value={competence.id}>
                        {`${competence.name}`}
                    </MenuItem>
                ))}
            </SingleSelect>
            <SingleSelect
                className="search-cards-form-item"
                label="Oppiaine"
                disabled={false}
                name="subject"
                value={values.subject}
                onChange={handleChange}
            >
                <MenuItem value={''}>Ei rajausta</MenuItem>
                {metadata.subjects.map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                        {subject.name}
                    </MenuItem>
                ))}
            </SingleSelect>
            <SingleSelect
                className="search-cards-form-item"
                label="Luokka-aste"
                disabled={false}
                value={values.grade}
                name="grade"
                onChange={handleChange}
            >
                <MenuItem value={''}>Ei rajausta</MenuItem>
                { metadata.grades.map((grade) => (
                    <MenuItem key={ grade.id } value={ grade.id }>
                        { grade.name }
                    </MenuItem>
                ))}
            </SingleSelect>
            <SingleSelect
                className="search-cards-form-item"
                label="Metakognitiiviset työkalut"
                disabled={false}
                value={values.metacognitive}
                name="metacognitive"
                onChange={handleChange}
            >
                <MenuItem value={''}>Ei rajausta</MenuItem>
                {metadata.metacognitiveTools.map((metacognitiveTool) => (
                    <MenuItem key={metacognitiveTool.id} value={metacognitiveTool.id}>
                        {`${metacognitiveTool.name}`}
                    </MenuItem>
                ))}
            </SingleSelect>
            <SingleSelect
                className="search-cards-form-item"
                label="Ryhmäkoko"
                disabled={false}
                name="group"
                value={values.group}
                onChange={handleChange}
            >
                <MenuItem value={''}>Ei rajausta</MenuItem>
                {metadata.groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                        {`${group.name}`}
                    </MenuItem>
                ))}
            </SingleSelect>
            { !hiddenFilter &&
                <SingleSelect
                    className="search-cards-form-item"
                    label="Tila"
                    disabled={false}
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                >
                    <MenuItem value={''}>Ei rajausta</MenuItem>
                    <MenuItem value={'PRIVATE'}>Yksityiset</MenuItem>
                    <MenuItem value={'PENDING'}>Käsittelyssä</MenuItem>
                    <MenuItem value={'REJECTED'}>Hylätyt</MenuItem>
                    <MenuItem value={'PUBLISHED'}>Julkaistut</MenuItem>
                </SingleSelect>
            }
            <SingleSelect
                className="search-cards-form-item"
                label="Järjestä"
                disabled={false}
                name="orderBy"
                value={values.orderBy}
                onChange={handleChange}
            >
                <MenuItem value="title">Otsikko</MenuItem>
                <MenuItem value="lastUpdated">Muokattu viimeksi</MenuItem>
            </SingleSelect>
            { hiddenFilter && [
                <AdminFormControlLabel
                    control={
                        <Checkbox
                            disabled={false}
                            name="onlyHidden"
                            checked={!!values.onlyHidden}
                            onChange={handleChange}
                        />
                    }
                    label="Näytä vain piilotetut"
                />,
                <AdminFormControlLabel
                    control={
                        <Checkbox
                            disabled={false}
                            name="onlyPending"
                            checked={!!values.onlyPending}
                            onChange={handleChange}
                        />
                    }
                    label="Näytä vain käsittelyssä"
                />
            ] }
            <Button className="search-cards-form-item-last" variant="outlined" onClick={resetForm}>
                Tyhjennä
            </Button>
        </form>
    );
}

export default FilterForm;
