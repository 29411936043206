import {
    cloneDeep,
    filter,
    find,
    isEqual,
    map
} from 'lodash';

const initialState = {
    activeNodeId: null,
    card: null,
    updates: {},
    editor: null,
    toolToEdit: null,
    undoData: null,
    dirty: false
};

const newCard = {
    title: "",
    subtitle: "",
    grades: [],
    subjects: [],
    tools:[],
    content: []
}

export function cardEditor(state = initialState, action) {
    switch(action.type) {
        case 'ADD_NODE': {
            const contentToUpdate = state.updates.content || state.card.content;
            const cardContent = cloneDeep(contentToUpdate);
            cardContent.push(action.node);
            const content = map(cardContent, (node) => {
                if (node.id === action.node.parent) {
                    node.children.push(action.node.id);
                }
                return node;
            });
            return {
                ...state,
                updates: {
                    ...state.updates,
                    content
                }
            };
        }
        case 'REMOVE_NODE': {
            const dirty = true;
            const contentToUpdate = state.updates.content || state.card.content;
            const cardContent = cloneDeep(contentToUpdate);
            const nodeToRemove = find(cardContent, (node) => node.id === action.id);
            let content = filter(cardContent, (node) => node.id !== action.id);
            content = map(content, (node) => {
                if (node.id === nodeToRemove.parent) {
                    const children = filter(node.children, (child) => child !== action.id);
                    return {
                        ...node,
                        children
                    }
                }
                return node;
            });
            return {
                ...state,
                dirty,
                updates: {
                    ...state.updates,
                    content
                }
            };
        }
        case 'REMOVE_TOOL': {
            const dirty = true;
            const toolsToUpdate = state.updates.tools || state.card.tools;
            let tools = toolsToUpdate.slice();
            tools.splice(action.index, 1);
            return {
                ...state,
                dirty,
                updates: {
                    ...state.updates,
                    tools
                }
            }
        }
        case 'REMOVE_DIFFICULTY_LEVEL': {
            const dirty = true;
            const rowId = action.data;
            const contentToUpdate = state.updates.content || state.card.content;
            const content = map(cloneDeep(contentToUpdate), (component) => {
                if (component.id === rowId) {
                    let updatedComponent = {
                        ...component,
                        props: {
                            ...component.props,
                            difficultyLevel: null
                        }
                    }
                    return updatedComponent;
                }
                return component;
            });
            return {
                ...state,
                dirty,
                updates: {
                    ...state.updates,
                    content
                }
            };
        }
        case 'CREATE_CARD': {
            const card = {
                ...newCard
            };
            return {
                ...state,
                card
            };
        }
        case 'USE_TEMPLATE': {
            const content = action.template.content;
            return {
                ...state,
                card: {
                    ...state.card,
                    content
                }
            }
        }
        case 'EDIT_CARD': {
            const card = action.card;
            const updates = {};
            return {
                ...state,
                card,
                updates
            };
        }
        case 'OPEN_EDITOR': {
            const undoData = cloneDeep(state.card);
            const editor = action.editorType;
            const activeNodeId = [ 'NODE', 'IMAGE', 'ROW', 'QR' ].includes(editor) ? action.data : null;
            const toolToEdit = editor === 'TOOL' && typeof action.data === 'number' ? action.data : null;
            return {
                ...state,
                editor,
                activeNodeId,
                toolToEdit,
                undoData
            }
        }
        case 'UPDATE_CARD': {
            const dirty = action.isDirty || state.dirty;
            const update = action.update;
            return {
                ...state,
                dirty,
                updates: {
                    ...state.updates,
                    ...update
                }
            };
        }
        case 'UPDATE_NODE': {
            const contentToUpdate = state.updates.content || state.card.content;
            const content = map(cloneDeep(contentToUpdate), (component) => {
                if (component.id === state.activeNodeId) {
                    let updatedComponent = {
                        ...component,
                        props: {
                            ...component.props,
                            ...action.update
                        }
                    }
                    return updatedComponent;
                }
                return component;
            });
            return {
                ...state,
                updates: {
                    ...state.updates,
                    content
                }
            };
        }
        case 'CLEAN_CARD': {
            const dirty = false;
            return {
                ...state,
                dirty
            };
        }
        case 'UNDO_CHANGES': {
            const updates = cloneDeep(state.undoData);
            return {
                ...state,
                updates
            };
        }
        case 'CLOSE_NODE_EDITOR':
        case 'CLOSE_CARD_DATA_EDITOR': {
            const dirty = !isEqual(state.card, state.undoData) || state.dirty;
            return {
                ...state,
                dirty,
                editor: null,
                activeNodeId: null,
                undoData: null,
                toolToEdit: null
            }
        }
        case 'CLOSE_EDITOR': {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
}