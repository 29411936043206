import React from 'react';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import CardHeader from './CardHeader';
import CardContent from './CardContent';
import CardFooter from './CardFooter';
import './Card.css';

class Card extends React.Component {
    referenceWidths = {
        A5: 420
    };
    constructor() {
        super();
        this.wrapperRef = React.createRef();
        this.state = {
            cardScale: 1,
            referenceWidth: 420
        };
        this.updateCardScale = this.updateCardScale.bind(this);
    };

    componentDidMount() {
        this.updateCardScale();
        window.addEventListener('resize', this.updateCardScale);
    };

    updateCardScale() {
        const cardWidth = this.wrapperRef.current.getBoundingClientRect().width / 1.333;
        const cardScale = cardWidth/this.referenceWidths[this.props.paperSize];
        this.setState({
            cardScale
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCardScale);
    };

    render() {
        const {
            competence,
            edit,
            editor
        } = this.props;
        let passedProps = pick(this.props, [
            'activeNodeId',
            'card',
            'edit',
            'editor',
            'metadata'
        ]);
        const canEdit = edit && !editor;
        const scale = this.state.cardScale;
        const primaryColor = competence ? competence.color : '#aaa';
        const secondaryColor = competence ? competence.secondaryColor : '#ddd';
        passedProps = {
            ...passedProps,
            canEdit,
            primaryColor,
            scale,
            secondaryColor
        };
        return (
            <div ref={ this.wrapperRef } className="leado-card-wrapper" style={{ minWidth: "300px" }}>
                <div className="leado-card-aspect-ratio-box shadow">
                    <div className="leado-card">
                        <CardHeader { ...passedProps } />
                        <CardContent { ...passedProps } />
                        <CardFooter { ...passedProps } />
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => {
    const competence = state.metadata.competences.find(competence => competence.id === ownProps.card.competence);
    return {
        competence,
        activeNodeId: state.cardEditor.activeNodeId,
        editor: state.cardEditor.editor,
        metadata: state.metadata
    };
};

export default connect(mapStateToProps)(Card);