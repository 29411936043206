import React from 'react';
import { connect } from 'react-redux';
import {
    MultipleSelect
} from './FormComponents';

const SubjectSelect = ({ subjects, disabled, value, onChange }) => {
    const values = value.map(subjectId => {
        const subject = subjects.find(item => item.id === subjectId);
        return subject.id;
    });
    const options = subjects.map((subject) => {
        const { id, name } = subject;
        return {
            label: name,
            value: id
        };
    });
    return (
        <MultipleSelect label="Oppiaine"
            disabled={ disabled }
            value={ values }
            options={ options }
            onChange={ (values) => {
                const subjectList = values.map(value => subjects.find(item => item.id === value).id);
                onChange(subjectList);
            }} />
    )
}

const mapStateToProps = (state) => {
    return {
        subjects: state.metadata.subjects
    }
}

export default connect(mapStateToProps)(SubjectSelect);


