import React from 'react';
import { Typography } from '@material-ui/core';
import LeadoHorizontalRule from '../LeadoHorizontalRule';

export default function Page({
    children,
    title,
    className = ''
}) {
    return (
        <div className="page-wrapper">
            <Typography variant="h4" component="h1" gutterBottom>{ title }</Typography>
            <LeadoHorizontalRule />
            { children }
        </div>
    )
}