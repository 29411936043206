import React from 'react';
import { connect } from 'react-redux';
import {
    MultipleSelect
} from './FormComponents';

const GradeSelect = ({ grades, disabled, value, onChange }) => {
    const options = grades.map((grade) => {
        const { id, name } = grade;
        return {
            label: name,
            value: id
        };
    });
    return (
        <MultipleSelect
            label="Luokka-aste"
            disabled={ disabled }
            value={ value }
            options={ options }
            onChange={ onChange }
        />
    )
}

const mapStateToProps = (state) => {
    return {
        grades: state.metadata.grades
    }
}

export default connect(mapStateToProps)(GradeSelect);