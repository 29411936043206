import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { find, filter, toUpper } from 'lodash';
import PDFRow from './PDFRow';
import PDFColumn from './PDFColumn';
import PDFContentImage from './PDFContentImage';
import PDFContentText from './PDFContentText';

const styles = StyleSheet.create({
    content: {
        position: 'relative',
        height: '70%',
        padding: '2% 0',
        flexDirection: 'column'
    },
    copyright: {
        position: 'absolute',
        right: '-37',
        bottom: '53',
        lineHeight: 0,
        transform: 'rotate(-90deg)',
        fontSize: '8'
    }
});

class PDFContent extends React.Component {

    components = {
      Row: PDFRow,
      Column: PDFColumn,
      ContentImage: PDFContentImage,
      ContentText: PDFContentText
    }

    constructor() {
      super();
      this.createElement = this.createElement.bind(this);
    }

    createElement(node) {
        if (typeof node === 'string') {
            return node;
        }
        let children = [];
        if (node.children.length) {
            children = node.children.map((id) => {
                const child = find(this.props.card.content, component => component.id === id);
                return this.createElement(child)
            });
        }
        let component;
        if (toUpper(node.type[0]) === node.type[0]) {
            let TagName = this.components[node.type];
            component = (
                <TagName key={ node.id } { ...node.props } secondaryColor={ this.props.card.secondaryColor }>{ children }</TagName>
            )
        }
        return component;
    };

    render() {
        const content = filter(this.props.card.content, (node) => !node.parent);
        return (
            <View style={ styles.content }
                // debug={ true }
            >
                { content.map(node => this.createElement(node)) }
                <Text
                    style={ styles.copyright }
                >
                    &copy; LeaDo, Kopiointi kielletty.
                </Text>
            </View>
        );
    }
};

export default PDFContent;