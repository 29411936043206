import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    CONFIRM_LOGOUT,
    CANCEL_LOGOUT
} from '../actions/actions'

const initialState = {
    user: null,
    showConfirmLogout: false,
    isRefreshing: true
};

export const auth = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case AUTH_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case AUTH_REQUEST_END: {
            return {
                ...state,
                isFetching: false
            };
        }
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS: {
            const { user } = action;
            return {
                ...state,
                user,
                isRefreshing: false
            };
        }
        case CONFIRM_LOGOUT: {
            return {
                ...state,
                showConfirmLogout: true
            }
        }
        case CANCEL_LOGOUT: {
            return {
                ...state,
                showConfirmLogout: false
            }
        }
        case SIGNUP_FAILURE:
        case LOGIN_FAILURE:
        case LOGOUT_CLIENT: {
            localStorage.removeItem('token');
            return {
                ...initialState,
                isRefreshing: false
            };
        }
        case UPDATE_PROFILE_SUCCESS: {
            const { user } = action;
            return {
                ...state,
                user
            };
        }
        default: {
            return state;
        }
    }
}