import React, { useState } from 'react';
import useForm from 'hooks/useForm';
import { validateEditPassword } from 'validation/validate';
import { Button, TextField, Typography } from '@material-ui/core';
import { AppLoader } from 'utils/AppLoader';
import Alert from '@material-ui/lab/Alert';
import { updatePassword } from 'services/authService';

export default function PasswordEditor({ user }) {
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        resetForm
    } = useForm(onSubmit, {
        previousPassword: '',
        password: '',
        passwordConfirm: ''
    }, validateEditPassword);

    const [successMessage, setSuccessMessage] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');

    async function onSubmit({ values }) {
        setError(null);
        setSuccessMessage(null);
        try {
            setIsFetching(true);
            await updatePassword(values);
            resetForm();
            setSuccessMessage('Salasana vaihdettu!')
            setIsFetching(false);
        } catch(error) {
            if (error.response && error.response.status === 403) {
                setError('Salasanan vaihto epäonnistui. Ole hyvä ja tarkista salasana.');
            } else {
                setError('Tapahtui virhe. Ole hyvä ja yritä myöhemmin uudelleen');
            }
            resetForm();
            setIsFetching(false);
        }
    }

    return (
        <div style={{ marginTop: '3rem' }}>
            <AppLoader active={ isFetching } component={false} />
            <Typography
                variant="h4"
                component="h1"
                className="mb-3"
            >
                Salasanan vaihto
            </Typography>
            { error &&
                <Alert
                    className="mb-3"
                    severity="error"
                >
                    {error}
                </Alert>
            }
            { successMessage &&
                <Alert
                    className="mb-3"
                    severity="success"
                >
                    {successMessage}
                </Alert>
            }
            <form className="d-flex flex-column" style={{ maxWidth: '300px' }} onSubmit={ handleSubmit }>
                <TextField
                    error={ !!errors.previousPassword }
                    helperText={ errors.previousPassword }
                    name="previousPassword"
                    value={ values.previousPassword || '' }
                    onChange={ handleChange }
                    size="small"
                    label="Nykyinen salasana"
                    variant="outlined"
                    type="password"
                    inputProps={{
                        maxLength: 100
                    }}
                />
                <TextField
                    error={ !!errors.password }
                    helperText={ errors.password }
                    name="password"
                    value={ values.password || '' }
                    onChange={ handleChange }
                    size="small"
                    label="Uusi salasana"
                    variant="outlined"
                    type="password"
                    inputProps={{
                        maxLength: 100
                    }}
                />
                <TextField
                    error={ !!errors.passwordConfirm }
                    helperText={ errors.passwordConfirm }
                    name="passwordConfirm"
                    value={ values.passwordConfirm || '' }
                    onChange={ handleChange }
                    size="small"
                    label="Salasana uudestaan"
                    variant="outlined"
                    type="password"
                    inputProps={{
                        maxLength: 100
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Lähetä
                </Button>
            </form>
        </div>
    )
}
