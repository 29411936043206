import uuid from 'uuid/v4';

const addNode = (node) => {
    return {
        type: 'ADD_NODE',
        node
    }
};

export const addQRNode = (parent) => {
    const node = {
      id: uuid(),
      type: 'ContentImage',
      props: {
          data: null
      },
      parent,
      children: []
    }

    return (dispatch) => {
        dispatch(addNode(node));
        dispatch(openEditor('QR', node.id));
    };
};

export const addTextNode = (parent) => {
    const node = {
        id: uuid(),
        type: 'ContentText',
        props: {
            style: {
                fontSize: 12
            },
            data: null
        },
        parent,
        children: []
    };

    return (dispatch) => {
        dispatch(addNode(node));
        dispatch(openEditor('NODE', node.id));
    };
};

export const addImageNode = (parent) => {
    const node = {
        id: uuid(),
        type: 'ContentImage',
        props: {
            data: null
        },
        parent,
        children: []
    };

    return (dispatch) => {
        dispatch(addNode(node));
        dispatch(openEditor('IMAGE', node.id));
    };
};

export const removeNode = (id) => {
    return {
        type: 'REMOVE_NODE',
        id
    }
}

export const editCard = (card) => {
    return {
        type: 'EDIT_CARD',
        card
    }
};

export const activateTemplate = (template) => {
    return {
        type: 'USE_TEMPLATE',
        template
    }
};

export const createCard = (author) => {
    return {
        type: 'CREATE_CARD',
        author
    };
}

export const openEditor = (editorType, data) => {
    return {
        type: 'OPEN_EDITOR',
        editorType,
        data
    }
};

export const removeTool = (index) => {
    return {
        type: 'REMOVE_TOOL',
        index
    }
};


export const removeDifficultyLevel = (data) => {
    return {
        type: 'REMOVE_DIFFICULTY_LEVEL',
        data
    }
};

export const updateCard = (update, isDirty = false) => {
    return {
        type: 'UPDATE_CARD',
        update,
        isDirty
    }
};

export const updateNode = (id, update) => {
    return {
        type: 'UPDATE_NODE',
        id,
        update
    }
};

export const cleanCardEditor = () => {
    return {
        type: 'CLEAN_CARD'
    }
};

export const undoChanges = () => {
    return {
        type: 'UNDO_CHANGES'
    }
};

export const closeNodeEditor = () => {
    return {
        type: 'CLOSE_NODE_EDITOR'
    }
};

export const closeEditor = () => {
    return {
        type: 'CLOSE_EDITOR'
    }
};