const defaultCardStyles = {
    header: {
        titleFontSize: 19,
        subtitleFontSize: 12,
        competenceFontSize: 30,
        studentGroupFontSize: 20,
        bottomBorderSize: 5
    }
};

export default defaultCardStyles;