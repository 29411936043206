import { useState, useCallback } from 'react';
import { useEffect } from 'react';

const TAG = '[useForm]';

const useForm = (
    callback = () => {},
    defaultValues,
    validate,
    options = {}
) => {
    const [values, setValues] = useState(defaultValues || {});
    const [errors, setErrors] = useState({});

    const resetForm = useCallback(() => {
        setValues(defaultValues || {});
        setErrors({});
    }, [defaultValues]);

    const resetField = useCallback((name) => {
        setValues({
            ...values,
            [name]: defaultValues ? defaultValues[name] : null
        });
    }, [values, defaultValues]);

    const handleSubmit = useCallback(async (event) => {
        if (event && event.preventDefault) event.preventDefault();
        if (!validate) {
            callback({
                values,
                resetForm
            });
            return;
        }
        const validationErrors = await validate(values);
        if (Object.keys(validationErrors).length === 0) {
            callback({
                values,
                resetForm
            });
        }
        setErrors(validationErrors);
    }, [values, resetForm, callback, validate]);

    const handleChange = (event) => {
        if (event.persist) {
            event.persist();
        }
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        const updates = { [name]: value };

        if (name === "onlyHidden" && !!values.onlyPending) {
            updates.onlyPending = false;
        }
        if (name === "onlyPending" && !!values.onlyHidden) {
            updates.onlyHidden = false;
        }

        setValues(values => ({ ...values, ...updates }));
    };

    useEffect(() => {
        if (options.submitOnChange) {
            handleSubmit();
        }
    }, [handleSubmit, options.submitOnChange, values])

    return {
        errors,
        handleChange,
        handleSubmit,
        resetField,
        resetForm,
        values
    }
};

export default useForm;