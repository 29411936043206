import React from 'react';
import {
    View,
    StyleSheet,
    Image
} from '@react-pdf/renderer';
import { toolIcons } from '../Card/images';

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '15%',
        padding: '2%'
    },
    footerImage: {
        margin: '0 1%',
        height: '100%'
    }
});

function PDFFooter({ card, metadata }) {
    return (
        <View style={ [
            styles.footer,
            { backgroundColor: card.secondaryColor }
        ] }>
            { card.tools.map((toolId) => {
                const tool = metadata.tools.find(tool => tool.id === toolId);
                return <Image key={ tool.id } style={ styles.footerImage } src={ toolIcons[tool.icon] }></Image>
            })}
        </View>
    );
};

export default PDFFooter;