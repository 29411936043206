import React from 'react';
import cardStyles from '../Card/defaultCardStyles';
import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { groupIcons } from '../Card/images';

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '15%'
      },
      iconSection: {
        flexBasis: '13%',
        flexGrow: '0',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      competenceText: {
        color: 'white',
        fontSize: cardStyles.header.competenceFontSize + 'pt'
      },
      titles: {
        height: '100%',
        flexGrow: '1',
        paddingLeft: '2%',
        paddingTop: '2%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
      },
      studentGroupText: {
        fontSize: cardStyles.header.studentGroupFontSize + 'pt'
      },
      rightMargin: {
        borderBottom: cardStyles.header.bottomBorderSize + 'pt solid white',
        height: '100%',
        flexBasis: '4%',
        flexGrow: '0'
      },
      title: {
        fontSize: cardStyles.header.titleFontSize + 'pt',
        height: '50%'
      },
      subtitle: {
        fontSize: cardStyles.header.subtitleFontSize + 'pt',
        height: '50%'
      },
      headerIcon: {
        width: '100%',
        height: 'auto'
      }
});

const PDFHeader = ({ card, metadata }) => {
  const primaryColor = card.primaryColor;
  const secondaryColor = card.secondaryColor;
  const group = metadata.groups.find(group => group.id === card.group);
  const competence = metadata.competences.find(item => item.id === card.competence);
  const metacognitiveTool = metadata.metacognitiveTools.find(item => item.id === card.metacognitiveTool);

  return (
      <View style={ [
        styles.header,
        {
          borderBottom: cardStyles.header.bottomBorderSize + 'pt solid ' + primaryColor,
          backgroundColor: secondaryColor
        }
      ] }>
          <View style={ [
            styles.iconSection,
            { backgroundColor: primaryColor }
          ]}>
              { competence && <Text style={ styles.competenceText }>{ competence.label }</Text> }
          </View>
          <View style={ styles.titles }>
              <Text style={ styles.title }>{ card.title }</Text>
              <Text style={ styles.subtitle }>{ card.subtitle }</Text>
          </View>
          <View style={ styles.iconSection }>
            { group && <Image key={ group.id } style={ styles.headerIcon } src={ groupIcons[group.icon] }></Image> }
          </View>
          <View style={ styles.iconSection }>
            { metacognitiveTool && <Text style={ styles.studentGroupText }>{ metacognitiveTool.label }</Text> }
          </View>
      </View>
  );
};

export default PDFHeader;