import { axiosApi as api } from 'api';

export async function login(credentials) {
    const response = await api.post('/auth/login', credentials);
    return response.data;
}

export async function refreshLogin() {
    try {
        const response = await api.get('/auth/refresh');
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export async function updateMe(update) {
    await api.put('/users/me', update);
    const { user } = await getUser();
    return user;
}

export async function getUser() {
    const response = await api.get('/users/me');
    return response.data;
}

export async function updatePassword(update) {
    await api.put(`/users/me/password`, update);
}

export async function createTeacher(data) {
    await api.post('/auth/teacher-invitation', data)
}

export async function initPasswordReset(value) {
    await api.post('/auth/init-password-reset', value);
}

export async function validatePasswordReset(id, token) {
    await api.get(`/auth/password-reset/${ id }/${ token }`);
}

export async function resetPassword(id, token, value) {
    await api.put(`/auth/password-reset/${ id }/${ token }`, value);
}
