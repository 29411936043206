import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import './css/Expandable.css';

const Expandable = ({ children, label }) => {
    return (
        <div className="expandable-wrapper">
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>{ label }</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    { children }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
};

export default Expandable;