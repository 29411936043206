import React from 'react';
import { Spinner } from 'react-bootstrap';
import './css/AppLoader.css';

const AppLoader = ({
    active = false
}) => {
    const loader = active ?
        <div className={ `app-loader` }>
            <Spinner
                animation="border"
                role="status"
                variant="light"
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div> :
        null;
    return loader;
};

export default AppLoader;