import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Grid, Typography, makeStyles, List, ListItem, IconButton, ListItemSecondaryAction, Divider, Button, InputLabel, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ConfirmationDialog from 'components/layout/ConfirmationDialog';
import { createSubscription, getSubscriptionsByUserId, deleteSubscription, getMySubscriptions } from 'services/subscriptionService';
import { AppLoader } from 'utils/AppLoader';
import AddIcon from '@material-ui/icons/Add';
import useForm from 'hooks/useForm';
import useDateWithTimezone from 'hooks/useDateWithTimezone';
import { validateSubscription } from 'validation/validate';
import './TeacherSubscriptions.css';

const TAG = '[TeacherSubscriptions]';

const useStyles = makeStyles({
    root: {
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative'
    },
    addButton: {
        float: 'right'
    },
    subscriptionForm: {
        width: '100%'
    }
});

const TeacherSubscriptions = ({
    teacher,
    onUpdate,
    role = 'ADMIN',
    single = false
}) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const classes = useStyles();
    const [subscriptionToDelete, setSubscriptionToDelete] = useState();
    const [showAddSubscription, setShowAddSubscription] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState('');
    const dateWithTimezone = useDateWithTimezone();
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        resetForm
    } = useForm(onSubmit, {}, validateSubscription);

    async function onSubmit({
        values
    }) {
        try {
            setIsFetching(true);
            const data = {
                expireAt: dateWithTimezone(values.expireAt),
            }
            await createSubscription(teacher.id, data);
            setShowAddSubscription(false);
            resetForm();
            setIsFetching(false);
            onUpdate();
        } catch(error) {
            setIsFetching(false);
            setError(error);
        }
    }

    const getSubscriptions = useCallback(async (id) => {
        try {
            setIsFetching(true);
            const action = role === 'ADMIN' ? getSubscriptionsByUserId : getMySubscriptions;
            const subscriptions = await action(id);
            setSubscriptions(subscriptions);
            setIsFetching(false);
        } catch (error) {
            setError('Tilauksia ei voitu hakea');
            setIsFetching(false);
        }
    }, [role]);

    useEffect(() => {
        getSubscriptions(teacher.id);
    }, [teacher, getSubscriptions]);

    const showAdminUI = role === 'ADMIN' && onUpdate;

    const visibleSubscriptions = single ? subscriptions.slice(0, 1) : subscriptions;

    return (
        <Paper className={ classes.root }>
            <AppLoader active={ isFetching } component={ true } />
            { showAdminUI && (
                <Button
                    className={ classes.addButton }
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="small"
                    disabled={ showAddSubscription }
                    startIcon={ <AddIcon /> }
                    onClick={ () => setShowAddSubscription(true) }
                >
                    Lisää tilaus
                </Button>
            )
            }

            <Typography variant="subtitle1">{ single ? 'Voimassa oleva tilaus' : 'Voimassa olevat tilaukset' }</Typography>

            { showAdminUI && showAddSubscription && (
                <form className="subscription-form" onSubmit={ handleSubmit }>
                            <InputLabel id="expire-at-label">Tilauksen päättymispäivämäärä</InputLabel>
                            <TextField
                                labelId="expire-at-label"
                                type="date"
                                error={ !!errors.expireAt }
                                helperText={ errors.expireAt }
                                name="expireAt"
                                value={ values.expireAt || '' }
                                onChange={ handleChange }
                                size="small"
                                variant="outlined"
                            />
                            <div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={ () => {
                                        setShowAddSubscription(false);
                                        resetForm();
                                    } }
                                    type="button"
                                >
                                    Peruuta
                                </Button>
                                <Button
                                    className="ml-2"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Tallenna
                                </Button>
                            </div>
                </form>
            )}
            { visibleSubscriptions.length > 0 ?
                <List className="mt-3">
                    { visibleSubscriptions.map((subscription) => {
                        const expiresAt = new Date(subscription.expireAt).toLocaleDateString();
                        return (
                            <ListItem key={ subscription.id }>
                                Päättyy { expiresAt }
                                { showAdminUI && (
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="Poista"
                                            onClick={ () => setSubscriptionToDelete(subscription.id) }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )
                                }
                            </ListItem>
                        );
                    }) }
                </List> : <p>Ei voimassa olevia tilauksia</p>
            }
            { showAdminUI && (
                <ConfirmationDialog
                    open={ !!subscriptionToDelete }
                    onConfirm={ async () => {
                        await deleteSubscription(subscriptionToDelete);
                        onUpdate();
                        setSubscriptionToDelete(null);
                    }}
                    onCancel={ () => setSubscriptionToDelete(null) }
                >
                    Haluatko varmasti poistaa tilauksen?
                </ConfirmationDialog>
            )}
        </Paper>
    )
}

export default TeacherSubscriptions;