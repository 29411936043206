import React, { useState } from 'react';
import StarIcon from '@material-ui/icons/Star';
import 'components/LevelIndicator/LevelIndicator.css';


export default function LevelIndicatorEditor({
    scale,
    onSelect
}) {
    const [hover, setHover] = useState(null);
    const fontSize = scale * 24;
    let stars = [];
    for (let i = 1; i < 4; i++) {
        stars.push(
            <StarIcon
                className="star-selector"
                style={{
                    margin: '5% 0',
                    padding: '0',
                    color: hover >= i ? 'gold' : 'gray',
                    fontSize: fontSize + 'pt'
                }}
                onClick={ () => onSelect({
                    difficultyLevel: i
                }) }
                onMouseOver={ () => setHover(i) }
                onMouseOut={ () => setHover(null) }

            >
            </StarIcon>
        );
    }
    return (
        <div className="level-indicator">
            { stars }
        </div>
    );
};