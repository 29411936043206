import React from 'react';
import { useSelector } from 'react-redux';
import {
    MenuItem
} from '@material-ui/core';
import {
    SingleSelect
} from './FormComponents';

function MetacognitiveSelect({
    children,
    disabled,
    value,
    onChange
}) {
    let metacognitiveTools = useSelector(state => state.metadata.metacognitiveTools);
    metacognitiveTools = [ { id: null, name: 'Ei valittua metakognitiivista työkalua' }, ...metacognitiveTools ];
    return (
        <SingleSelect label="Metakognitiiviset työkalut"
            disabled={ disabled }
            value={ value }
            onChange={ (event) => onChange(event.target.value) }>
                { children }
                { metacognitiveTools.map((metacognitiveTool) => (
                    <MenuItem
                        key={ metacognitiveTool.id }
                        value={ metacognitiveTool.id }>
                        { `${ metacognitiveTool.name }` }
                    </MenuItem>))
                }
        </SingleSelect>
    )
}

export default MetacognitiveSelect;