import {
    filter,
    orderBy as sort,
    cloneDeep
} from 'lodash';

const initialState = {
    pathways: [],
    isFetching: false
};

export function getVisiblePathways(pathways, { competence, subject, grade, metacognitive, group, text, orderBy = 'title', order = 'asc' }) {
    const filteredPathways = filter(pathways, (card) => {
        const competenceMatch = !competence || card.competence === competence;
        const subjectMatch = !subject || card.subjects.includes(subject);
        const gradeMatch = !grade || card.grades.includes(grade);
        const metacognitiveMatch = !metacognitive || card.metacognitiveTool === metacognitive;
        const groupMatch = !group || card.group === group;
        const titleMatch = !text || !(text.length > 2) || card.title.toLowerCase().includes(text.toLowerCase());
        return competenceMatch && subjectMatch && gradeMatch && metacognitiveMatch && groupMatch && titleMatch;
    });
    const sortedPathways = sort(filteredPathways, orderBy, order);
    return sortedPathways;
};

export const duplicatePathway = (pathway) => {
    const pathwayCopy = cloneDeep(pathway);
    delete pathwayCopy.id;
    delete pathwayCopy.createdAt;
    delete pathwayCopy.updatedAt;
    pathwayCopy.title = `${ pathwayCopy.title } (Kopio)`;
    return pathwayCopy;
};

export function userPathways(state = initialState, action) {
    switch(action.type) {
        case 'DELETE_USER_PATHWAY_BEGIN' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'DELETE_USER_PATHWAY_SUCCESS' : {
            console.log('[userPathways] onDeleteUserPathwaySuccess', action.id);
            const pathways = state.pathways.filter((pathway) => pathway.id !== action.id);
            return {
                ...state,
                pathways,
                isFetching: false
            }
        }
        case 'DELETE_USER_PATHWAY_FAILURE' : {
            return {
                ...state,
                isFetching: false
            }
        }
        case 'FETCH_USER_PATHWAYS_BEGIN': {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'FETCH_USER_PATHWAYS_SUCCESS': {
            console.log('[userPathways] onFetchUserPathwaysSuccess', action.pathways);
            const pathways = action.pathways || state.pathways.slice();
            return {
                ...state,
                pathways,
                isFetching: false
            }
        }
        case 'FETCH_USER_PATHWAYS_FAILURE': {
            return {
                ...state,
                isFetching: false
            }
        }
        case 'FETCH_PUBLISHED_PATHWAYS': {
            return {
                ...state,
                isFetchingPublishedPathways: true
            }
        }
        case 'FETCH_PUBLISHED_PATHWAYS_SUCCESS': {
            const publishedPathways = action.pathways || state.pathways.slice();
            return {
                ...state,
                publishedPathways,
                isFetchingPublishedPathways: false
            }
        }
        case 'INSERT_USER_PATHWAY_BEGIN' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'INSERT_USER_PATHWAY_SUCCESS': {
            console.log('[userPathways] onInsertUserPathwaySuccess', action.pathway);
            const pathway = action.pathway;
            let pathways = state.pathways.slice();
            pathways.push(pathway);
            return {
                ...state,
                pathways,
                isFetching: false
            };
        }
        case 'INSERT_USER_PATHWAY_FAILURE' : {
            return {
                ...state,
                isFetching: false
            }
        }
        case 'UPDATE_USER_PATHWAY_BEGIN' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'UPDATE_USER_PATHWAY_SUCCESS' : {
            console.log('[userPathways] onUpdateUserPathwaySuccess', action.pathway);
            const pathway = action.pathway;
            let pathways = state.pathways.slice();
            pathways = filter(pathways, (userPathway) => userPathway.id !== pathway.id );
            pathways.push(pathway);
            return {
                ...state,
                pathways,
                isFetching: false
            };
        }
        case 'UPDATE_USER_PATHWAY_FAILURE' : {
            return {
                ...state,
                isFetching: false
            }
        }
        default:
            return state;
    }
}