import { axiosApi as api } from 'api';

export async function getSchools() {
    const response = await api.get('/schools');
    return response.data.schools;
}

export async function createSchool(data) {
    return await api.post('/schools', data);
}

export async function deleteSchool(id) {
    return api.delete('/schools/' + id);
}