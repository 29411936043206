export const error = (
    state = { error: null },
    action
) => {
    const { error } = action;
    if (error) {
        return {
            error
        };
    }
    switch(action.type) {
        case 'DISMISS_ERROR': {
            return {
                error: null
            }
        }
        default: {
            return state;
        }
    }
}