import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    Chip,
    MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const singleSelectStyles = makeStyles({
    root: {
        width: '300px'
    }
});

export const SingleSelect = ({
    children,
    disabled,
    label,
    value,
    onChange,
    name
}) => {
    const classes = singleSelectStyles();
    return (
        <FormControl>
            <InputLabel id={ `${ label }-select-label`}>{ label }</InputLabel>
            <Select
                name={ name }
                labelId={ `${ label }-select-label`}
                id={ `${ label }-select`}
                classes={{
                    root: classes.root
                }}
                disabled={ disabled }
                value={value}
                onChange={ onChange }
            >
                { children }
            </Select>
        </FormControl>
    )
};

const multipleSelectStyles = makeStyles({
    root: {
        width: '300px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
});

export const MultipleSelect = ({
    disabled,
    label,
    onChange,
    options,
    value
}) => {
    const classes = multipleSelectStyles();
    return (
        <FormControl>
            <InputLabel id={ `${ label }-select-label`}>{ label }</InputLabel>
            <Select
                labelId={ `${ label }-select-label`}
                id={ `${ label }-select`}
                classes={{
                    root: classes.root
                }}
                disabled={ disabled }
                multiple={ true }
                value={value}
                onChange={ (event) => onChange(event.target.value) }
                renderValue={selected => (
                    <div className={classes.chips}>
                        {
                            selected.map(value => {
                                const item = options.find(option => option.value === value);
                                return <Chip key={value} label={ item.label } className={classes.chip} />;
                            })
                        }
                    </div>
                )}
            >
                { options.map((option) => (
                    <MenuItem
                        key={ option.value }
                        value={ option.value }
                    >
                        { option.label }
                    </MenuItem>))
                }
            </Select>
        </FormControl>
    );
};