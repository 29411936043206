import React from 'react';
import {
    Fab,
    makeStyles
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import './EditorTrigger.css';

const useStyles = makeStyles({
    buttonRoot: {
        margin: '0 .1rem'
    }
});

function EditorTrigger({
    disableDelete = false,
    disableEdit = false,
    onDelete,
    onEdit
}) {
    const classes = useStyles();
    return (
        <div className="editor-trigger">
            { !disableEdit && <Fab color="primary"
                size="small"
                onClick={ onEdit }><Edit fontSize="small"/></Fab>
            }
            { !disableDelete && <Fab color="secondary"
                classes={{
                    root: classes.buttonRoot
                }}
                size="small"
                onClick={ onDelete }><Delete fontSize="small"/></Fab>
            }
        </div>
    )
};

export default EditorTrigger;