import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_SUPPORT_BASE_URL,
});

api.interceptors.request.use(function (config) {
    config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${ localStorage.getItem('token') }`
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export async function getFile(uri) {
    const response = await api.get(uri, {
        responseType: 'blob'
    });
    return response.data;
}
