import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    InputLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useForm from 'hooks/useForm';
import { validateCreateTeacher } from 'validation/validate';
import Alert from '@material-ui/lab/Alert';
import { AppLoader } from 'utils/AppLoader';
import TeacherForm from '../TeacherForm';
import { createTeacher } from 'services/authService';
import useDateWithTimezone from 'hooks/useDateWithTimezone';

const TAG = '[CreateTeacherDialog]';

const CreateTeacherDialog = ({
    schools,
    onClose,
    onCreateSuccess,
    open,
    onAddSchool,
    ...rest
}) => {
    const [isFetching, setIsFetching] = useState(false);
    // const [schools, setSchools] = useState([]);
    const [error, setError] = useState('');
    const dateWithTimezone = useDateWithTimezone();
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        resetForm
    } = useForm(onSubmit, {}, validateCreateTeacher);

    // const fetchSchools = useCallback(async () => {
    //     setIsFetching(true);
    //     try {
    //       const schools = await getSchools();
    //       setSchools(schools);
    //       setIsFetching(false);
    //     } catch(error) {
    //       setError('Kouluja ei voitu hakea. Yritä myöhemmin uudelleen');
    //       setIsFetching(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     fetchSchools();
    // }, [fetchSchools])

    function handleClose() {
        onClose();
        setError('');
        resetForm();
    }

    async function onSubmit({
        values
    }) {
        const teacher = {
            ...values
        };
        if (teacher.school) {
            teacher.schoolId = values.school.id;
        }
        delete teacher.school;
        teacher.subscriptionExpiryDate = dateWithTimezone(teacher.subscriptionExpiryDate);
        try {
            setIsFetching(true);
            setError('');
            await createTeacher(teacher);
            setIsFetching(false);
            onCreateSuccess(values);
            handleClose();
        } catch(error) {
            if (error.response.data.message === 'UserEmailUnique') {
                setError('Sähköpostiosoite on jo käytössä.');
            } else {
                setError('Tapahtui virhe. Ole hyvä ja yritä myöhemmin uudelleen.');
            }
            setIsFetching(false);
        }
    }

    return (
        <Dialog
            fullWidth={ true }
            open={ open }
            scroll="body"
            { ...rest }
        >
            <AppLoader active={ isFetching } />
            <DialogTitle>
                Lisää uusi opettaja
            </DialogTitle>
            <DialogContent>
                { error &&
                    <Alert
                        className="mb-3"
                        severity="error"
                    >
                        { error }
                    </Alert>
                }
                <TeacherForm
                    errors={ errors }
                    onChange={ handleChange }
                    onSubmit={ handleSubmit }
                    onCancel={ handleClose }
                    schools={ schools }
                    values={ values }
                    onAddSchool={ onAddSchool }
                />
            </DialogContent>
        </Dialog>
    );
};

export default CreateTeacherDialog;