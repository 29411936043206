const clearedFilters = {
    text: "",
    competence: "",
    group: "",
    subject: "",
    grade: "",
    metacognitive: "",
    orderBy: 'title',
    order: 'asc'
}

const initialState = {
    userCards: {
        ...clearedFilters
    },
    publishedCards: {
        ...clearedFilters
    },
    userPathways: {
        ...clearedFilters
    },
    publishedPathways: {
        ...clearedFilters
    }
};

export function filters(state = initialState, action) {
    switch(action.type) {
        case 'UPDATE_USER_CARD_FILTERS': {
            const updates = action.filters;
            if (updates.orderBy) {
                if (updates.orderBy === 'lastUpdated') {
                    updates.order = 'desc';
                } else {
                    updates.order = 'asc';
                }
            }
            return {
                ...state,
                userCards: {
                    ...state.userCards,
                    ...updates
                }
            }
        }
        case 'UPDATE_PUBLISHED_CARD_FILTERS': {
            const updates = action.filters;
            if (updates.orderBy) {
                if (updates.orderBy === 'lastUpdated') {
                    updates.order = 'desc';
                } else {
                    updates.order = 'asc';
                }
            }
            return {
                ...state,
                publishedCards: {
                    ...state.publishedCards,
                    ...updates
                }
            }
        }
        case 'CLEAR_USER_CARD_FILTERS': {
            return {
                ...state,
                userCards: initialState.userCards
            }
        }
        case 'CLEAR_PUBLISHED_CARD_FILTERS': {
            return {
                ...state,
                publishedCards: initialState.publishedCards
            }
        }
        case 'UPDATE_USER_PATHWAY_FILTERS': {
            const updates = action.filters;
            if (updates.orderBy) {
                if (updates.orderBy === 'lastUpdated') {
                    updates.order = 'desc';
                } else {
                    updates.order = 'asc';
                }
            }
            return {
                ...state,
                userPathways: {
                    ...state.userPathways,
                    ...updates
                }
            }
        }
        case 'UPDATE_PUBLISHED_PATHWAY_FILTERS': {
            const updates = action.filters;
            if (updates.orderBy) {
                if (updates.orderBy === 'lastUpdated') {
                    updates.order = 'desc';
                } else {
                    updates.order = 'asc';
                }
            }
            return {
                ...state,
                publishedPathways: {
                    ...state.publishedPathways,
                    ...updates
                }
            }
        }
        case 'CLEAR_USER_PATHWAY_FILTERS': {
            return {
                ...state,
                userPathways: initialState.userPathways
            }
        }
        case 'CLEAR_PUBLISHED_PATHWAY_FILTERS': {
            return {
                ...state,
                publishedPathways: initialState.publishedPathways
            }
        }
        case 'RESET_FILTERS': {
            return initialState;
        }
        default: {
            return state;
        }
    }
};