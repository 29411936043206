import React from 'react';
import AppRouter from 'routers/AppRouter';
import './App.css';
import 'draft-js/dist/Draft.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import { AppLoader } from 'utils/AppLoader';

const App = () => {
  const isRefreshing = useSelector(state => state.auth.isRefreshing);
  const isFetchingMetadata = useSelector(state => state.metadata.isFetching);

  if (isRefreshing || isFetchingMetadata) {
    return <AppLoader active={ true } />
  }

  return <AppRouter />;
}

export default App;
