import React from 'react';
import { useSelector } from 'react-redux';
import {
    MenuItem
} from '@material-ui/core';
import {
    SingleSelect
} from './FormComponents';

function CompetenceSelect({
    children,
    disabled,
    value,
    onChange
}) {
    let competences = useSelector(state => state.metadata.competences);
    competences = [ { id: null, name: 'Ei valittua osaamistavoitetta', label: '' }, ...competences ];
    return (
        <SingleSelect
            label="Laaja-alaiset osaamistavoitteet"
            disabled={ disabled }
            value={ value }
            onChange={ (event) => onChange(event.target.value) }>
                { children }
                { competences.map((competence) => (
                    <MenuItem
                        key={ competence.id }
                        value={ competence.id }>
                        { `${ competence.label } ${ competence.name }` }
                    </MenuItem>))
                }
        </SingleSelect>
    )
}

export default CompetenceSelect;