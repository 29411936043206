import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { AccessTime, HighlightOff } from '@material-ui/icons';
import './css/ListItem.css';
import logo from 'assets/images/logo_medium.png';

const ListItem = ({
    card,
    competence,
    draggable = false,
    droppable = false,
    disabled,
    onDragOver,
    onDragStart,
    onDragExit,
    onDrop,
    onDragEnd,
    handleViewCard
    }) => {
    const secondaryColor = competence.color || '#ddd';
    if (disabled) {
        draggable = false;
        droppable = false;
    }
    return (
        <div draggable={ draggable.toString() }
            droppable={ droppable.toString() }
            className={`list-item${ draggable ? ' draggable' : ''}`}
            onDragStart={ onDragStart }
            onDragExit={ onDragExit }
            onDragLeave={ onDragExit }
            onDragOver={ onDragOver }
            onDrop={ onDrop }
            onDragEnd={ onDragEnd }
            style={{
                backgroundColor: 'white',
                borderColor: secondaryColor
            }}>
            { disabled &&
                <div className="list-item-disabled"></div>
            }
            <div className="list-item-title">{ card.title }</div>
            { card.status === 'PENDING' && (
                <div className="list-item-logo"><AccessTime fontSize='large' htmlColor='#fdd736' /></div>
            ) }
            { card.status === 'REJECTED' && (
                <div className="list-item-logo"><HighlightOff fontSize='large' htmlColor='#ff1644'/></div>
            ) }
            { card.status === 'PUBLISHED' && (
                <div className="list-item-logo"><img draggable="false" src={ logo } alt="leado-logo"/></div>
            ) }
            <div className="list-item-actions">
                <span>
                    <Button
                        style={{
                            border: '1px solid ' + secondaryColor
                        }}
                        variant="outlined"
                        size="small"
                        onClick={ () => handleViewCard(card) }
                    >
                        Näytä
                    </Button>
                </span>
            </div>

        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const competence = state.metadata.competences.find(competence => competence.id === ownProps.card.competence) || {};
    return {
        competence
    }
};

export default connect(mapStateToProps)(ListItem);