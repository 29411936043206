import React from 'react';
import { Text } from '@react-pdf/renderer';
import reactStringReplace from 'react-string-replace';

class PDFContentText extends React.Component {
    render() {
        // This is done to turn the string into an array of strings to render spaces correctly. react-pdf has no implementation for css white-space property.
        let data = reactStringReplace(this.props.data, ' ', () => ' ');

        return (
            <Text
                style={ [
                    {
                        fontSize: this.props.style.fontSize + 'pt',
                        lineHeight: this.props.style.lineHeight || '1.5',
                        letterSpacing: 0
                    }
                ] }
                // debug={ true }
            >
                { data }
            </Text>
        );
    }
};

export default PDFContentText;