import * as yup from 'yup';

export const createTeacherSchema = yup.object().shape({
    firstName: yup.string().required('Ole hyvä ja syötä etunimi'),
    lastName: yup.string().required('Ole hyvä ja syötä sukunimi'),
    email: yup.string().email('Ole hyvä ja syötä hyväksytty sähköpostiosoite').required('Ole hyvä ja syötä sähköpostiosoite'),
    subscriptionExpiryDate: yup.date('Ole hyvä ja valitse päivämäärä').required('Ole hyvä ja valitse päivämäärä')
});

export const editTeacherSchema = yup.object().shape({
    firstName: yup.string().required('Ole hyvä ja syötä etunimi'),
    lastName: yup.string().required('Ole hyvä ja syötä sukunimi'),
    email: yup.string().email('Ole hyvä ja syötä hyväksytty sähköpostiosoite').required('Ole hyvä ja syötä sähköpostiosoite')
});

export const editPasswordSchema = yup.object().shape({
    previousPassword: yup.string().required('Ole hyvä ja täytä vaadittu kenttä'),
    password: yup.string()
        .min(6, 'Salasanan pitää olla vähintään 6 merkin mittainen')
        .required('Ole hyvä ja täytä vaadittu kenttä'),
    passwordConfirm: yup.string()
        .oneOf([ yup.ref('password') ], 'Salasanat eivät täsmää')
        .min(6, 'Salasanan pitää olla vähintään 6 merkin mittainen')
        .required('Ole hyvä ja täytä vaadittu kenttä')
});