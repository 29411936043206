import React from 'react';
import background from '../../assets/images/juomuvaaka.png';

const LeadoHorizontalRule = ({
    height = '4px',
    margin = '2rem auto',
    width = '100%'
}) => {
    return (
        <div style={{
            width,
            height,
            backgroundImage: `url(${ background })`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            margin
        }}></div>
    );
};

export default LeadoHorizontalRule;