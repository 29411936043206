import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationDialog from 'components/layout/ConfirmationDialog';
import { useEffect } from 'react';
import { AppLoader } from 'utils/AppLoader';
import { deleteUser, updateUser } from 'services/userService';
import TeacherEditor from '../TeacherEditor';
import TeacherSubscriptions from 'components/TeacherSubscriptions';

const useStyles = makeStyles({
    deleteButton: {
        marginLeft: '2rem'
    }
});

const TeacherDialog = ({
    onClose,
    onAddSchool,
    onDeleteSuccess,
    onUpdate,
    schools,
    teacher,
    ...rest
}) => {
    const classes = useStyles();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [edit, setEdit] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setShowDeleteConfirmation(false);
    }, [teacher]);

    async function handleDelete(id) {
        try {
          setIsFetching(true);
          await deleteUser(id);
          onDeleteSuccess(teacher);
          onClose();
          setIsFetching(false);
        } catch(error) {
          setIsFetching(false);
        }
    }

    async function handleToggleSuspend() {
        try {
            setIsFetching(true);
            await updateUser(teacher.id, {
                suspended: !teacher.suspended ? true : false
            });
            onUpdate();
            setIsFetching(false);
        } catch(error) {
            setIsFetching(false);
        }
    }

    let content = null;
    if (teacher && edit) {
        content = <TeacherEditor onAddSchool={ onAddSchool } onUpdate={ onUpdate } teacher={ teacher } onClose={ () => setEdit(false) } schools={ schools } />
    } else if (teacher) {
        content = (
            <>
                <DialogTitle>
                    { teacher.firstName } { teacher.lastName }
                    <Button
                        size="small"
                        className={ classes.deleteButton }
                        variant="contained"
                        color="primary"
                        onClick={ () => setEdit(true) }>
                        Muokkaa
                    </Button>
                    <Button
                        size="small"
                        className={ classes.deleteButton }
                        variant="contained"
                        color="secondary"
                        onClick={ handleToggleSuspend } >
                        { teacher.suspended ? 'Avaa tili' : 'Lukitse tili' }
                    </Button>
                    <Button
                        size="small"
                        className={ classes.deleteButton }
                        variant="contained"
                        color="secondary"
                        onClick={ () => setShowDeleteConfirmation(true) }>
                        Poista
                    </Button>
                </DialogTitle>
                <DialogContent>
                    { teacher.suspended && <Chip className="mb-3" label="Tili lukittu" color="secondary" /> }
                    <Typography variant="subtitle2" gutterBottom>
                        Sähköposti
                    </Typography>
                    <Typography variant="body1" gutterBottom>{ teacher.email }</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Koulu
                    </Typography>
                    <Typography variant="body1">{ teacher.school ? teacher.school.name : 'Koulua ei valittu' }</Typography>
                    <TeacherSubscriptions onUpdate={ onUpdate } teacher={ teacher } />
                </DialogContent>
            </>
        )
    }

    return (
        <Dialog fullWidth={ true } { ...rest }>
            <AppLoader active={ isFetching } />
            { content }
            <DialogActions>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={ () => {
                        onClose();
                        setEdit(false);
                    } }>
                    Sulje
                </Button>
            </DialogActions>
            <ConfirmationDialog
                open={ showDeleteConfirmation }
                onConfirm={ () => {
                    if (!teacher) {
                        console.warn('Trying to delete teacher: undefined');
                    }
                    setShowDeleteConfirmation(false);
                    handleDelete(teacher.id);
                }}
                onCancel={ () => setShowDeleteConfirmation(false) }
            >
                Haluatko varmasti poistaa opettajan { teacher ? ` ${ teacher.firstName } ${ teacher.lastName }` : '' }?
            </ConfirmationDialog>
        </Dialog>
    );
};

export default TeacherDialog;