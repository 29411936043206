export const routes = {
    HOME: `/`,
    DASHBOARD: `/dashboard`,
    DASHBOARD_PATHWAYS: `/dashboard/pathways`,
    LIBRARY: `/library`,
    LIBRARY_PATHWAYS: `/library/pathways`,
    LEADO: `/leado`,
    LEADO_PATHWAYS: `/leado/pathways`,
    PROFILE: `/profile`,
    SUPPORT: `/support`,
    TEMPLATE_PICKER: `/template_picker`,
    EDIT_CARD: `/edit_card`,
    EDIT_PATHWAY: `/edit_pathway`,
    LOGIN: `/login`,
    TEACHERS: '/teachers',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password'
};
