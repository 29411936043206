import {
    cloneDeep
} from 'lodash';

const initialState = {
    pathway: null,
    undoData: null,
    dirty: false
};

const newPathway = {
        title: '',
        cards: [],
        grades: [],
        subjects: []
}

export function pathwayEditor(state = initialState, action) {
    switch(action.type) {
        case 'CREATE_PATHWAY': {
            const author = action.author;
            const pathway = {
                ...newPathway,
                author
            };
            pathway.cards.length = 9;
            pathway.cards.fill(null);
            return {
                ...state,
                pathway
            };
        }
        case 'EDIT_PATHWAY': {
            const pathway = cloneDeep(action.pathway);
            return {
                ...state,
                pathway
            };
        }
        case 'UPDATE_PATHWAY': {
            const dirty = true;
            const update = action.update;
            const pathway = {
                ...state.pathway,
                ...update
            };
            return {
                ...state,
                dirty,
                pathway
            };
        }
        case 'REMOVE_CARD': {
            const dirty = true;
            let cards = state.card.cards.slice();
            cards.splice(action.index, 1);
            return {
                ...state,
                dirty,
                pathway: {
                    ...state.pathway,
                    cards
                }
            }
        }
        case 'CLEAN_PATHWAY': {
            const dirty = false;
            return {
                ...state,
                dirty
            };
        }
        case 'UNDO_CHANGES': {
            const pathway = cloneDeep(state.undoData);
            return {
                ...state,
                pathway
            };
        }
        case 'CLOSE_PATHWAY_EDITOR': {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
}