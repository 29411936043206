import React from 'react';
import { find, map, pick, toUpper } from 'lodash';
import './CardContent.css';
import { filter } from 'lodash';
import { Row, Column, ContentImage, ContentText } from 'components/Card/CardComponents';

class CardContent extends React.Component {
    createElement = (node) => {
        const passedProps = pick(this.props, [
            'activeNodeId',
            'canEdit',
            'editor',
            'scale',
            'secondaryColor'
        ]);

        passedProps.id = node.id;
        if (typeof node === 'string') {
            return node;
        }
        let children = [];
        if (node.children.length) {
            children = node.children.map((id) => {
                const child = find(this.props.card.content, component => component.id === id);
                return this.createElement(child)
            });
        }
        let component;
        if (toUpper(node.type[0]) === node.type[0]) {
            let TagName = components[node.type];
            component = (
                <TagName key={ node.id }
                    { ...passedProps }
                    { ...node.props }
                    >
                    { children }
                </TagName>
            )
        }
        return component;
    };

    render() {
        const content = filter(this.props.card.content, (node) => !node.parent);
        const edit = this.props.edit;
        return (
            <div className="card-content">
                { map(content, node => (this.createElement(node))) }
                <div className={ `leado-copyright${ edit ? ' smaller' : '' }` }>
                    &copy; LeaDo, Kopiointi kielletty.
                </div>
            </div>
        );
    }
}

const components = {
    Row,
    Column,
    ContentImage,
    ContentText
};

export default CardContent;
