import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, Redirect } from 'react-router-dom';
import { debounce } from 'lodash';
import {
    Button,
    Fab,
    MenuItem,
    TextField,
    Grid,
    Typography,
    makeStyles,
    TablePagination
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import {
    cleanPathwayEditor,
    closePathwayEditor,
    editPathway,
    updateUserPathway
} from 'actions/pathwayEditor';
import AppLoader from 'components/layout/AppLoader';
import ListItem from 'components/layout/ListItem';
import ConfirmationDialog from 'components/layout/ConfirmationDialog';
import EmptyListItem from 'components/layout/EmptyListItem';
import FilterForm from 'components/layout/FilterForm';
import GridList from 'components/layout/GridList';
import CardViewerDialog from 'components/layout/CardViewerDialog';
import CompetenceSelect from 'components/editor/forms/CompetenceSelect';
import SubjectSelect from 'components/editor/forms/SubjectSelect';
import GradeSelect from 'components/editor/forms/GradeSelect';
import MetacognitiveSelect from 'components/editor/forms/MetacognitiveSelect';
import GroupSizeSelect from 'components/editor/forms/GroupSizeSelect';
import './PathwayEditorPage.css';
import { SingleSelect } from 'components/editor/forms/FormComponents';
import Expandable from 'components/layout/Expandable';
import { getPublishedCards, getMyCards } from 'services/cardService';
import { insertPathway, getPathway, updatePathway } from 'services/pathwayService';
import { routes } from 'routers/routes';
import useFilters from 'hooks/useFilters';
import usePagination from 'hooks/usePagination';

const TAG = '[PathwayEditorPage]';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    }
  });

function PathwayEditorPage() {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [dragged, setDragged] = useState(null);
    const [viewCard, setViewCard] = useState(null);
    const [cardSelector, setCardSelector] = useState('USER');
    const [userCards, setUserCards] = useState([]);
    const [publishedCards, setPublishedCards] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingUserCards, setIsFetchingUserCards] = useState(true);
    const [isFetchingPublishedCards, setIsFetchingPublishedCards] = useState(true);
    const [filters, setFilters] = useState({});
    const pathway = useSelector(state => state.pathwayEditor.pathway);
    const [title, setTitle] = useState(pathway ? pathway.title : '');
    const dirty = useSelector(state => state.pathwayEditor.dirty);
    const [error, setError] = useState('');
    const filterCards = useFilters();
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const debouncedHandleTitleUpdate = debounce(handleTitleUpdate, 150);

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        initRowsPerPage,
    } = usePagination({
        page: 0,
        rowsPerPage: 36,
    });

    useEffect(() => {
        (async () => {
            setIsFetchingUserCards(true);
            try {
                const cards = await getMyCards();
                setUserCards(cards);
                setIsFetchingUserCards(false);
            } catch (error) {
                setError('Käyttäjän kortteja ei voitu hakea. Yritä myöhemmin uudelleen');
                setIsFetchingUserCards(false);
            }
        })();
        (async () => {
            setIsFetchingPublishedCards(true);
            try {
                const cards = await getPublishedCards();
                setPublishedCards(cards);
                setIsFetchingPublishedCards(false);
            } catch (error) {
                setError('LeaDo-kortteja ei voitu hakea. Yritä myöhemmin uudelleen');
                setIsFetchingPublishedCards(false);
            }
        })();
        return () => {
            dispatch(closePathwayEditor());
        }
    }, [dispatch]);

    function handleTitleChange(event) {
        const title = event.target.value;
        setTitle(title);
        debouncedHandleTitleUpdate(title)
    };

    function handleTitleUpdate(title) {
        dispatch(updateUserPathway({ title }))
    };

    function handleCardDragStart(event, type, dragged) {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', type);
        setDragged(dragged);
    };

    function handleCardDragOver(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    function handleCardDrop(event, target) {
        event.preventDefault();
        const action =  event.dataTransfer.getData('text/plain');
        const cards = pathway.cards;
        if (action === 'MOVE') {
            if (target === dragged) { return; }
            [ cards[target], cards[dragged] ] = [ cards[dragged], cards[target] ];
            dispatch(updateUserPathway({ cards }));
        } else if (action === 'ADD') {
            cards[target] = dragged;
            dispatch(updateUserPathway({ cards }));
        }
        setDragged(null);
    };

    function handleDragEnd(event) {
        event.preventDefault();
        setDragged(null)
    };

    function handleRemoveCard(index) {
        const cards = pathway.cards;
        cards[index] = null;
        dispatch(updateUserPathway({ cards }));
    };

    async function handleSavePathway() {
        try {
            setIsFetching(true);
            const id = pathway.id ? await updatePathway(pathway.id, pathway) : await insertPathway(pathway);
            const result = await getPathway(id);
            dispatch(editPathway(result));
            dispatch(cleanPathwayEditor());
            setIsFetching(false);
        } catch(error) {
            setIsFetching(false);
            console.error(error);
        }
    };

    function handleCloseEditor() {
        history.goBack();
    };

    const cards = cardSelector === 'PUBLISHED' ? publishedCards : userCards;
    const visibleCards = filterCards(cards, filters);
    const gridContent = visibleCards.map((card) => (
        <ListItem
            disabled={ pathway.cards.map(card => !!card ? card.id : card).includes(card.id) }
            draggable={ true }
            onDragStart={ (event) => handleCardDragStart(event, 'ADD', card) }
            onDragEnd={ handleDragEnd }
            handleViewCard={ (viewCard) => setViewCard(viewCard.id) }
            key={ card.id }
            card={ card }
        />
    ));
    const contentLength = gridContent.length;

    if (!pathway) {
        return <Redirect to={ routes.LIBRARY } />
    }

    return (
        <div className="app-wrapper">
            {  <AppLoader active={ (isFetchingUserCards || isFetchingPublishedCards || isFetching) } /> }
            <Prompt when={ dirty } message="Oppimispolussa on tallentamattomia muutoksia, jotka häviävät suljettaessa. Haluatko varmasti sulkea muokkauksen?" />
            <Grid container>
                <Grid item xs={ 8 }>
                <Typography
                    variant="h4"
                    component="h1"
                    className="mb-5"
                >
                    Muokkaa oppimispolkua
                </Typography>
                </Grid>
                <Grid
                    item
                    xs={ 4 }
                    className={ classes.buttons }
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={ handleCloseEditor }
                    >Sulje</Button>
                     <Button
                        className="ml-3"
                        disabled={ !dirty }
                        variant="contained"
                        color="primary"
                        onClick={ handleSavePathway }
                    >Tallenna</Button>
                </Grid>
            </Grid>
            <div className="pathway-editor-content">
                <div className="pathway-editor-left">
                    <GridList
                        key="pathway-cards"
                        rowLength={ 3 }
                    >
                        {
                            pathway.cards.map((card, index) => {
                                return (
                                    <div key={ index } style={{
                                            position: 'relative'
                                        }}>
                                        { !!card &&
                                            <span className="remove-card-button">
                                                <Fab
                                                    color="secondary"
                                                    size="small"
                                                    onClick={ () => handleRemoveCard(index) }>
                                                    <Delete fontSize="small"/>
                                                </Fab>
                                            </span>
                                                }
                                        { !!card ? (
                                            <ListItem
                                                draggable={ true }
                                                droppable={ true }
                                                onDragOver={ (event) => handleCardDragOver(event) }
                                                onDragStart={ (event) => handleCardDragStart(event, 'MOVE', index) }
                                                onDrop={ (event) => handleCardDrop(event, index ) }
                                                onDragEnd={ handleDragEnd }
                                                handleViewCard={ (viewCard) => setViewCard(viewCard.id) }
                                                card={ card }
                                            />) :
                                            <EmptyListItem
                                                key={ `empty-item-${ index }`}
                                                disableAdd={ true }
                                                droppable={ true }
                                                onDragOver={ (event) => handleCardDragOver(event, index) }
                                                onDrop={ (event) => handleCardDrop(event, index ) }
                                            />
                                        }
                                    </div>)
                            })
                        }
                    </GridList>
                </div>
                <div className="pathway-editor-right">
                    <div className="pathway-editor-form">
                        <TextField
                            label="Otsikko"
                            placeholder="Otsikko"
                            value={ title }
                            onChange={ handleTitleChange }
                        />
                        <CompetenceSelect
                            onChange={ (competence) => dispatch(updateUserPathway({ competence })) }
                            value={ pathway.competence }
                        />
                        <SubjectSelect
                            onChange={ (subjects) => dispatch(updateUserPathway({ subjects })) }
                            value={ pathway.subjects }
                        />
                        <GradeSelect
                            onChange={ (grades) => dispatch(updateUserPathway({ grades })) }
                            value={ pathway.grades }
                        />
                        <MetacognitiveSelect
                            onChange={ (metacognitiveTool) => dispatch(updateUserPathway({ metacognitiveTool })) }
                            value={ pathway.metacognitiveTool }
                        />
                        <GroupSizeSelect
                            onChange={ (group) => dispatch(updateUserPathway({ group })) }
                            value={ pathway.group }
                        />
                    </div>
                    <Typography
                        variant="h5"
                        component="h2"
                        className="mb-3 mt-5"
                    >
                        Selaa kortteja
                    </Typography>
                    <SingleSelect
                        label="Näytä"
                        value={ cardSelector }
                        onChange={ (event) => setCardSelector(event.target.value)}
                    >
                        <MenuItem value={ 'USER'}>Omat kortit</MenuItem>
                        <MenuItem value={ 'PUBLISHED'}>LeaDo-kortit</MenuItem>
                    </SingleSelect>
                    <Expandable label="Suodata kortteja">
                        <FilterForm
                            onChange={({ values }) => setFilters(values)}
                            hiddenFilter={ false }
                        />
                    </Expandable>
                    <GridList
                        key="cards"
                        rowLength={6}
                        minItemWidth="150px"
                        currentPage={page}
                        cardsPerPage={rowsPerPage}
                    >
                        {gridContent}
                    </GridList>
                    <table className="grid-list-pagination">
                        <tbody>
                            <tr>
                                <TablePagination
                                    labelRowsPerPage="Näytä sivulla"
                                    page={page}
                                    count={contentLength}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    //labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                                    labelDisplayedRows={({ from, to, count, page }) =>
                                        `${page + 1}/${Math.ceil(count / rowsPerPage)}`
                                    }
                                    rowsPerPageOptions={[initRowsPerPage]}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
                <ConfirmationDialog
                    open={ showConfirmation }
                    confirmText="Sulje"
                    cancelText="Peruuta"
                    onConfirm={ () => history.goBack() }
                    onCancel={ () => setShowConfirmation(false) }
                >
                Oppimispolussa on tallentamattomia muutoksia, jotka häviävät suljettaessa. Haluatko varmasti sulkea muokkauksen?
            </ConfirmationDialog>
            { viewCard &&
                <CardViewerDialog
                    open={ !!viewCard }
                    cardId={ viewCard }
                    viewOnly={ true }
                    onClose={ () => setViewCard(null) }
                />
            }
        </div>
    );
}


export default PathwayEditorPage;