import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import './LevelIndicator.css';

export default function LevelIndicator({
    level,
    scale
}) {
    if (!level || !Number.isInteger(level)) {
        return null;
    }
    const fontSize = scale * 24;
    const stars = [];
    stars.length = level;
    stars.fill(<StarIcon style={{
        color: 'gold',
        margin: '5% 0',
        fontSize: fontSize + 'pt'
    }} />);
    return (
        <div className="level-indicator">
            { stars }
        </div>
    );
};