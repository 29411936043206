import React from 'react';
import './css/EmptyListItem.css';
import AddCircle from '@material-ui/icons/AddCircle';
import {
    IconButton
} from '@material-ui/core';

function EmptyListItem({
    droppable = false,
    disableAdd = false,
    handleAddItem,
    onDragOver,
    onDrop
    }) {
    return (
        <div className="empty-list-item"
            droppable={ droppable.toString() }
            onDragOver={ onDragOver }
            onDrop={ onDrop }
            >
            { !disableAdd &&
                <IconButton
                    onClick={ handleAddItem }
                >
                    <AddCircle fontSize="large" />
                </IconButton>
            }
        </div>
    );
}

export default EmptyListItem;