import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../Card';
import EditCardForm from './EditCardForm';
import ImageEditorDialog from './ImageEditorDialog';
import ToolEditorDialog from './ToolEditorDialog';
import QREditorDialog from './QREditorDialog';
import './CardEditor.css';
import { updateNode, closeNodeEditor, removeNode } from 'actions/cardEditor';

function CardEditor({ card }) {
    const dispatch = useDispatch();
    const editor = useSelector(state => state.cardEditor.editor);
    const activeNodeId = useSelector(state => state.cardEditor.activeNodeId);

    function handleSubmitImage(data) {
        if (activeNodeId) {
            dispatch(updateNode(activeNodeId, data));
        }
        dispatch(closeNodeEditor());
    }

    function handleCloseImageEditor() {
        const node = card.content.find(node => node.id === activeNodeId);
        if (node && !node.props.data) {
            dispatch(removeNode(activeNodeId));
        }
        dispatch(closeNodeEditor());
    }

    return (
        <div className="card-editor-wrapper">
            <div className="card-editor">
                <div className="card-editor-left">
                    <EditCardForm card={ card } editor={ editor } />
                </div>
                <Card card={ card }
                    edit={ true }
                    paperSize="A5"
                />
            </div>
            <ImageEditorDialog open={ editor === 'IMAGE' } card={ card } onClose={ handleCloseImageEditor } onSubmit={ handleSubmitImage } />
            <ToolEditorDialog open={ editor === 'TOOL' } card={ card } />
            <QREditorDialog open={ editor === 'QR' } card={ card } onClose={ handleCloseImageEditor } onSubmit={ handleSubmitImage }  />
        </div>
    );
};

export default CardEditor;