import { get } from '../api';

const onFetchMetaData = () => {
    console.log('[metadata] onFetchMetaData');
    return {
        type: 'FETCH_META_DATA_BEGIN'
    }
};

const onFetchMetaDataSuccess = (data) => {
    console.log('[metadata] onFetchMetaDataSuccess', data);
    return {
        type: 'FETCH_META_DATA_SUCCESS',
        data
    };
};

const onFetchMetaDataFailure = (error) => {
    console.log('[metadata] onFetchMetaDataFailure', error);
    return {
        type: 'FETCH_META_DATA_ERROR',
        error
    };
};

export function fetchMetaData() {
    return async (dispatch) => {
        dispatch(onFetchMetaData());
        try {
            const metadata = await get('/cards/metadata');
            dispatch(onFetchMetaDataSuccess(metadata));
        } catch(error) {
            dispatch(onFetchMetaDataFailure(error));
        }
    };
};