import React from 'react';
import { connect } from 'react-redux';
import { openEditor, updateCard } from 'actions/cardEditor';
import './CardHeader.css';
import { groupIcons } from 'components/Card/images';
import cardStyles from 'components/Card/defaultCardStyles';
import EditorTrigger from 'components/editor/EditorTrigger';
import TitleEditor from 'components/editor/TitleEditor';

const CardHeader = ({
    canEdit,
    card,
    editor,
    metadata,
    scale,
    dispatch,
    primaryColor,
    secondaryColor}) => {

    const fontSizes = {
        title: (scale * cardStyles.header.titleFontSize) + 'pt',
        subtitle: (scale * cardStyles.header.subtitleFontSize) + 'pt',
        competence: (scale * cardStyles.header.competenceFontSize) + 'pt',
        studentGroup: (scale * cardStyles.header.studentGroupFontSize) + 'pt'
    };

    const bottomBorderSize = scale * cardStyles.header.bottomBorderSize;
    const group = metadata.groups.find(group => group.id === card.group);
    const competence = metadata.competences.find(item => item.id === card.competence);

    return (
        <div className="leado-card-header"
            style={{
                borderBottom: `${ bottomBorderSize }pt solid ${ primaryColor }`,
                backgroundColor: secondaryColor
            }}>
            <div className="leado-card-header-competence"
                style={{ fontSize: fontSizes.competence, backgroundColor: primaryColor }}
                >
                { competence && <span>{ competence.label }</span> }
            </div>
            <div className="leado-card-header-info">
                <div className="leado-card-header-title">
                    { editor === 'TITLE' &&
                        <TitleEditor fontSize={ fontSizes.title }
                            data={ card.title }
                            onSaveChanges={ (title) => dispatch(updateCard({ title }, true)) }
                        />
                    }
                    { canEdit &&
                        <EditorTrigger
                            disableDelete={ true }
                            onEdit={ () => dispatch(openEditor('TITLE')) } />
                    }
                    { editor !== 'TITLE' &&
                        <h2 className="leado-card-header-title-text" style={{ fontSize: fontSizes.title }}>
                            { card.title || ( canEdit && 'Tyhjä otsikko') }
                        </h2>
                    }
                </div>
                <div className="leado-card-header-subtitle">
                    { editor === 'SUBTITLE' &&
                        <TitleEditor fontSize={ fontSizes.subtitle }
                            data={ card.subtitle }
                            onSaveChanges={ (subtitle) => dispatch(updateCard({ subtitle }, true)) }
                        />
                    }
                    { canEdit &&
                        <EditorTrigger
                            disableDelete={ true }
                            onEdit={ () => dispatch(openEditor('SUBTITLE')) } />
                    }
                    { editor !== 'SUBTITLE' &&
                        <h5 className="leado-card-header-title-text" style={{ fontSize: fontSizes.subtitle  }}>
                            { card.subtitle || (canEdit && 'Tyhjä otsikko') }
                        </h5>
                    }
                </div>
            </div>
            <div
                className="leado-card-header-student-group"
                >
                { group && <img src={ groupIcons[group.icon] } alt={ group.name } /> }
            </div>
            <div className="leado-card-header-metacognitive"
                style={{ fontSize: fontSizes.studentGroup  }}>
                    { card.metacognitiveTool && <span>M{ card.metacognitiveTool }</span> }
            </div>
        </div>
    );
};

export default connect(null)(CardHeader);