import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions
} from '@material-ui/core';

const ConfirmationDialog = ({
    cancelText = "Peruuta",
    children,
    disabledConfirm = false,
    confirmText = "OK",
    onCancel,
    onConfirm,
    ...rest
}) => {
    return (
        <Dialog
            maxWidth="xs"
            { ...rest }
        >
            <DialogContent>
                { children }
            </DialogContent>
            <DialogActions>
                { onCancel &&
                    <Button
                        variant="outlined"
                        onClick={ onCancel }>
                        { cancelText }
                    </Button>
                }
                { onConfirm &&
                    <Button
                        autoFocus
                        variant="contained"
                        color="primary"
                        disabled={ disabledConfirm }
                        onClick={ onConfirm }>
                        { confirmText }
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;