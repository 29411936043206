import React, { useState } from 'react';
import {
    Button,
    TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useForm from 'hooks/useForm';
import { initPasswordReset } from 'services/authService';
import { AppLoader } from 'utils/AppLoader';
import './ForgotPasswordPage.css';

const ForgotPasswordPage = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    async function onSubmit({ values }) {
        try {
            setIsFetching(true);
            await initPasswordReset(values);
            setError(null);
            setSuccess(true);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            setError('Tapahtui virhe. Yritä myöhemmin uudelleen.');
        }
    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(onSubmit);

    return (
        <div className="d-flex flex-column align-items-center">
            <AppLoader active={ isFetching } component={false} />
            { success ?
                <Alert
                    className="mt-3"
                    severity="success"
                >
                    Antamaasi sähköpostiosoiteeseen on lähetetty ohjeet salasanan palautusta varten.
                </Alert>
            :
                <form className="forgot-password-form d-flex flex-column" onSubmit={ handleSubmit }>
                    <TextField
                        error={ !!errors.email }
                        helperText={ errors.email }
                        name="email"
                        value={ values.email || '' }
                        onChange={ handleChange }
                        size="small"
                        label="Kirjoita sähköpostiosoite"
                        variant="outlined"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Lähetä
                    </Button>
                    { error &&
                        <Alert
                            className="mt-3"
                            severity="error"
                        >
                            {error}
                        </Alert>
                    }
                </form>
            }
        </div>
    )
}

export default ForgotPasswordPage;