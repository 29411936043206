import React from 'react';
import { Spinner } from 'react-bootstrap';
import './css/AppLoader.css';

export function AppLoader({
    active = false,
    component = false
}) {
    
    const loader = active ?
        <div className={ `app-loader${ component ? ' component-loader' : '' }` }>
            <div>
                <Spinner
                    animation="border"
                    role="status"
                    variant={ component ? 'secondary' : 'light' }
                >
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        </div> :
        null;
    return loader;
};