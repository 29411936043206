import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    closeNodeEditor,
    updateCard
} from '../../actions/cardEditor';
import { toolIcons } from '../Card/images';
import './ToolEditorDialog.css';

class ToolEditorDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            selected: null
        };
    }

    render() {
        const {
            card,
            dispatch,
            open,
            tools,
            toolToEdit
        } = this.props;

        const availableTools = tools.filter(tool => !card.tools.includes(tool.id));
        return (
            <Dialog
                open={ open }
                onExited={ () => this.setState({ selected: null })}>
                <DialogTitle>Valitse välinesymboli</DialogTitle>
                    <DialogContent>
                        <div className="tool-options">
                            { availableTools.map(tool => {
                                const selected = this.state.selected === tool.id;
                                return (
                                    <div
                                        className={ `tool-option ${ selected ? "selected" : "" }` }
                                        key={ tool.id }
                                        onClick={ () => this.setState({ selected: tool.id }) }>
                                        <img
                                            className="tool-option-image"
                                            src={ toolIcons[tool.icon] }
                                            alt={ tool.name } />
                                    </div>
                                );
                            })}
                        </div>
                    </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        disabled={ !this.state.selected }
                        onClick={ () => {
                            const update = card.tools.slice();
                            if (typeof toolToEdit === 'number') {
                                update[toolToEdit] = this.state.selected;
                            } else {
                                update.push(this.state.selected);
                            }
                            dispatch(updateCard({ tools: update }));
                            dispatch(closeNodeEditor());
                        } }
                    >
                        Valmis
                    </Button>
                    <Button variant="outlined"
                        onClick={ () => {
                            dispatch(closeNodeEditor());
                        }}>
                        Peruuta
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        editor: state.cardEditor.editor,
        tools: state.metadata.tools,
        toolToEdit: state.cardEditor.toolToEdit
    };
}

export default connect(mapStateToProps)(ToolEditorDialog);