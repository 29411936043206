import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { duplicateCard } from 'reducers/userCards';
import AppLoader from 'components/layout/AppLoader';
import Card from 'components/Card';
import ConfirmationDialog from 'components/layout/ConfirmationDialog';
import PDFDownloadDialog from 'components/layout/PDFDownloadDialog';
import useUser from 'hooks/useUser';
import { cloneCard, getCard, getHiddenCard, publishHiddenCard, publishCard, hideCard, deletePublicCard, deleteCard, suggestCard, getPendingCard, rejectCard, getRejectedCard } from 'services/cardService';
import { withPermissions } from 'utils/withPermissions';
import { getUser } from 'services/userService';
import './css/Dialog.css';
import { MultipleSelect } from 'components/editor/forms/FormComponents';

const TAG = '[CardViewerDialog]';

const MenuItemAdmin = withPermissions(MenuItem, null, ['ADMIN']);

function CardViewerDialog({
    cardId,
    cardStatus,
    onlyHidden = false,
    onlyPending = false,
    onClose,
    onEdit,
    onClone,
    onSuggest,
    onPublish,
    onReject,
    onHide,
    onDelete,
    open,
    viewOnly = false
}) {
    const dispatch = useDispatch();
    const user = useUser();
    const [owner, setOwner] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSuggestConfirmation, setShowSuggestConfirmation] = useState(false);
    const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
    const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
    const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
    const [showHideConfirmation, setShowHideConfirmation] = useState(false);
    const [cardCopy, setCardCopy] = useState(null);
    const [card, setCard] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [showPDFDialog, setShowPDFDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCriterias, setSelectedCriterias] = useState([]);

    const criteriaList = [
        {value: 1, label: 'Epäselvä ohjeistus'},
        {value: 2, label: 'Epäselvät kuvat'},
        {value: 3, label: 'Epäselvät videot'},
        {value: 4, label: 'Rikkinäiset tai epäloogiset linkit'},
        {value: 5, label: 'Epäselvät hakuvalinnat'},
    ];

    useEffect(() => {
        if (!cardId) {
            setCard(null);
            return;
        }
        (async () => {
            try {
                setIsFetching(true);
                let cardData = null;

                if (onlyHidden) {
                    cardData = cardStatus === "HIDDEN"
                        ? await getHiddenCard(cardId)
                        : await getCard(cardId);
                } else if (onlyPending) {
                    cardData = cardStatus === "PENDING"
                        ? await getPendingCard(cardId)
                        : cardStatus === "REJECTED"
                        ? await getRejectedCard(cardId)
                        : cardStatus === "HIDDEN"
                        ? await getHiddenCard(cardId)
                        : await getCard(cardId);
                } else {
                    cardData = await getCard(cardId);
                }

                if (isAdmin && cardData.owner) {
                    const user = await getUser(cardData.owner);
                    setOwner(user);
                }

                setCard(cardData);
                setIsFetching(false);
            } catch(error) {
                console.error(error);
                setIsFetching(false);
            }
        })();
    }, [cardId, cardStatus, onlyHidden, onlyPending])

    async function handleCardAction(action, formData = null) {
        try {
            setIsFetching(true);
            const response = formData ? await action(card.id, formData) : await action(card.id);
            setIsFetching(false);
            return response;
        } catch (error) {
            console.error(error);
            setIsFetching(false);
        }
    }

    async function handleClone() {
        if (!cardId) {
            return;
        }
        try {
            setIsFetching(true);
            onCloseMenu();
            const id = await cloneCard(cardId);
            const cardCopy = await getCard(id);
            setCardCopy(cardCopy);
            setIsFetching(false);
        } catch(error) {
            console.error(error);
            setIsFetching(false);
        }
    }

    function onCloseMenu() {
        setAnchorEl(null);
    };

    const privateMenunContent = card && card.status === "PENDING" ? (
        <span>
            <MenuItem onClick={ () => {
                onCloseMenu();
                const cardCopy = duplicateCard(card);
                onEdit(cardCopy);
            }}>Kopioi luonnokseksi
            </MenuItem>
        </span>
    ) : (
        <span>
            <MenuItem onClick={ () => {
                onCloseMenu();
                const cardCopy = duplicateCard(card);
                onEdit(cardCopy);
            }}>Kopioi luonnokseksi
            </MenuItem>
            <MenuItem onClick={ () => {
                onCloseMenu();
                onEdit(card);
            }}>Muokkaa
            </MenuItem>
            {user.roles.includes('ADMIN') ?
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowPublishConfirmation(true);
                }}>Julkaise
                </MenuItemAdmin>
            :
                <MenuItem onClick={ () => {
                    onCloseMenu();
                    setShowSuggestConfirmation(true);
                }}>Ehdota julkaistavaksi
                </MenuItem>
            }
            <MenuItem onClick={ () => {
                onCloseMenu();
                setShowConfirmation(true);
            }}>Poista
            </MenuItem>
        </span>
    );

    const publicMenuContent = card && (
        <span>
            <MenuItem onClick={ handleClone }>Tallenna omaan kirjastoon</MenuItem>
            <MenuItemAdmin onClick={ () => {
                onCloseMenu();
                onEdit(card);
            }}>Muokkaa
            </MenuItemAdmin>
            { card.status === 'PUBLISHED' &&
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowHideConfirmation(true);
                }}>Piilota
                </MenuItemAdmin>
            }
            { card.status === 'HIDDEN' &&
                <MenuItemAdmin onClick={ () => {
                    setShowPublishConfirmation(true);
                    onCloseMenu();
                }}>Julkaise
                </MenuItemAdmin>
            }
            { card.status === 'PENDING' ? [
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowAcceptConfirmation(true);
                }}>Hyväksy
                </MenuItemAdmin>,
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowRejectConfirmation(true);
                }}>Hylkää
                </MenuItemAdmin>
            ] :
                <MenuItemAdmin onClick={ () => {
                    onCloseMenu();
                    setShowConfirmation(true);
                }}>Poista
                </MenuItemAdmin>    
            }
        </span>
    );

    const isAdmin = user.roles.includes('ADMIN');
    const isUserCard = card && card.owner === user.id;
    const menuContents = card && (isUserCard ? privateMenunContent : publicMenuContent);

    return (
        <Dialog
            fullWidth
            scroll="body"
            maxWidth="sm"
            open={ open }
            onExit={ () => {
                setShowConfirmation(false);
                setCardCopy(null);
            }}
            onClose={ onClose }
        >
            <AppLoader active={ isFetching } />
            <DialogActions>
                { owner ? (
                    <div>
                        <span>{ owner.firstName } { owner.lastName } - { owner.email }</span>
                    </div>
                ) : (
                    <span></span>
                ) }
                <div>
                    <Menu
                        id="action-menu"
                        anchorEl={ anchorEl }
                        anchorOrigin={{ horizontal: -100, vertical: 'top' }}
                        keepMounted
                        open={ !!anchorEl }
                        onClose={ onCloseMenu }
                    >
                        <MenuItem onClick={ () => {
                            onCloseMenu();
                            setShowPDFDialog(true);
                        }}>Luo PDF</MenuItem>
                        { !viewOnly && menuContents }
                    </Menu>
                    <IconButton
                        size="small"
                        onClick={ (event) => setAnchorEl(event.currentTarget) }
                    >
                        <MoreHoriz />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={ onClose }
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogActions>
            <DialogContent
                dividers={ true }
            >
                { card &&
                    <Card
                        card={ card }
                        edit={ false }
                        paperSize="A5"
                    />
                }
            </DialogContent>
            { !viewOnly && card && isAdmin &&
                <ConfirmationDialog
                    open={ showPublishConfirmation }
                    confirmText="Julkaise"
                    cancelText="Peruuta"
                    onConfirm={  async () => {
                        setShowPublishConfirmation(false);
                        await handleCardAction(card.status === 'HIDDEN' ? publishHiddenCard : publishCard);
                        onPublish(card);
                        onClose();
                    } }
                    onCancel={ () => setShowPublishConfirmation(false)}
                >
                    Haluatko varmasti julkaista kortin { card.title }?
                </ConfirmationDialog>
            }
            { !viewOnly && card && isUserCard &&
                <ConfirmationDialog
                    open={ showSuggestConfirmation }
                    confirmText="Ehdota julkaistavaksi"
                    cancelText="Peruuta"
                    onConfirm={  async () => {
                        setShowSuggestConfirmation(false);
                        const response = await handleCardAction(suggestCard);
                        const pendingCard = await getCard(response.id);
                        onSuggest(pendingCard, card);
                        onClose();
                    } }
                    onCancel={ () => setShowSuggestConfirmation(false)}
                >
                    Haluatko varmasti ehdottaa korttia { card.title } julkaistavaksi?
                </ConfirmationDialog>
            }
            { card &&
                <ConfirmationDialog
                    open={ showConfirmation }
                    confirmText="Poista"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowConfirmation(false);
                        await handleCardAction(card.status === 'PUBLISHED' ? deletePublicCard : deleteCard)
                        onDelete(card);
                        onClose();
                    } }
                    onCancel={ () => setShowConfirmation(false)}
                >
                    Haluatko varmasti poistaa kortin { card.title }?
                </ConfirmationDialog>
            }
            { card && onHide && isAdmin &&
                <ConfirmationDialog
                    open={ showHideConfirmation }
                    confirmText="Piilota"
                    cancelText="Peruuta"
                    onConfirm={  async () => {
                        setShowHideConfirmation(false);
                        await handleCardAction(hideCard);
                        onHide(card);
                        onClose();
                    } }
                    onCancel={ () => setShowHideConfirmation(false)}
                >
                    Haluatko varmasti piilottaa kortin { card.title }?
                </ConfirmationDialog>
            }
            { card && isAdmin &&
                <ConfirmationDialog
                    open={ showAcceptConfirmation }
                    confirmText="Hyväksy"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowAcceptConfirmation(false);
                        await handleCardAction(publishCard);
                        onPublish(card);
                        onClose();
                    } }
                    onCancel={ () => setShowAcceptConfirmation(false)}
                >
                    Haluatko varmasti hyväksyä kortin { card.title }?
                </ConfirmationDialog>
            }
            { card && isAdmin &&
                <ConfirmationDialog
                    open={ showRejectConfirmation }
                    disabledConfirm={ !selectedCriterias.length }
                    confirmText="Hylkää"
                    cancelText="Peruuta"
                    onConfirm={ async () => {
                        setShowRejectConfirmation(false);
                        await handleCardAction(rejectCard, selectedCriterias);
                        setSelectedCriterias([]);
                        onReject(card);
                        onClose();
                    } }
                    onCancel={ () => setShowRejectConfirmation(false)}
                >
                    <p>Haluatko varmasti hylätä kortin { card.title }?</p>
                    <MultipleSelect
                        label="Hylkäämisperusteet *"
                        value={selectedCriterias}
                        options={criteriaList}
                        onChange={(values) => setSelectedCriterias(values) }
                    />
                </ConfirmationDialog>
            }
            { cardCopy && <ConfirmationDialog
                    open={ !!cardCopy }
                    confirmText="Muokkaa"
                    cancelText="Sulje"
                    onConfirm={ () => {
                        setShowConfirmation(false);
                        onEdit(cardCopy);
                    } }
                    onCancel={ () => setCardCopy(null) }
                    >
                    Kortti { cardCopy.title } on tallennettu kirjastoosi. Haluatko muokata tallennettua korttia nyt?
                </ConfirmationDialog>
            }
            { card &&
                <PDFDownloadDialog
                    cards={ [ card ] }
                    open={ showPDFDialog }
                    onClose={ () => setShowPDFDialog(false) }
                />
            }
        </Dialog>
    );
}

export default CardViewerDialog;