import React from 'react';
import { intersection } from 'lodash';
import useRoles from 'hooks/useRoles';

export function withPermissions(WrappedComponent, rejectComponent, roles) {
    return function(props) {
        const userRoles = useRoles();
        if (intersection(userRoles, roles).length > 0) {
            return <WrappedComponent {...props} />;
        }
        return rejectComponent;
    };
}