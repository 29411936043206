import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import './ImageEditorDialog.css';

function ImageEditorDialog({ open, onClose, onSubmit }) {
    const [data, setData] = useState(null);
    const [completed, setCompleted] = useState(0);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        setData(null);
        setCompleted(0);
        setUploading(false);
    }, [open]);

    function updateImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onprogress = (event) => {
            if (event.lengthComputable) {
                const completed = (event.loaded / event.total) * 100;
                setCompleted(completed);
            }
        };
        reader.onload = () => {
            let img = new Image();

            img.onload = () => {
                let data = reader.result;
                let imgType = '';
                let imgTypeArray = data.match(/^data:image\/(\w+);.*/i);
                if(imgTypeArray !== null) {
                    imgType = imgTypeArray[1];
                } else {
                    console.error("Error! Couldn't parse image type!");
                    return;
                }
                setCompleted(0);
                setUploading(false);
                setData({
                    filename: null,
                    data,
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                    type: imgType
                });
            };

            img.src = reader.result;
        };

        setUploading(true);
        reader.readAsDataURL(file);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Valitse kuva</DialogTitle>
            <DialogContent>
                <div className="image-input-wrapper">
                    <div className="image-input-preview">
                        {uploading && (
                            <div className="image-upload-loader">
                                <div className="image-upload-progress">
                                    <CircularProgress
                                        variant="determinate"
                                        value={completed}
                                    />
                                </div>
                                <div className="image-upload-cancel">
                                    <IconButton size="small">
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        { data &&
                            <img
                                style={{ maxWidth: '200px' }}
                                src={ data.data }
                                alt="Visual content"
                            />
                        }
                    </div>
                    <input
                        disabled={ uploading }
                        type="file"
                        onChange={ updateImage }
                        accept="image/png, image/jpeg"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    disabled={ uploading || !data }
                    onClick={ () => onSubmit(data) }
                >
                    Valmis
                </Button>
                <Button
                    variant="outlined"
                    disabled={ uploading }
                    onClick={ onClose }
                >
                    Peruuta
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ImageEditorDialog;
