import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  TextField,
  TableFooter,
  TablePagination,
  Button,
  InputAdornment,
  IconButton,
  makeStyles,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { AppLoader } from 'utils/AppLoader';
import useForm from 'hooks/useForm';
import usePagination from 'hooks/usePagination';
import { getTeachers } from 'services/userService';
import { Cancel } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { routes } from 'routers/routes';
import TeacherDialog from './TeacherDialog';
import CreateTeacherDialog from './CreateTeacherDialog';
import Alert from '@material-ui/lab/Alert';
import { getSchools } from 'services/schoolService';
import './TeachersPage.css';

const TAG = '[TeachersPage]';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  addButton: {
      float: 'right'
  }
});

export default function TeachersPage() {
  const classes = useStyles();
  const { teacherId } = useParams();
  const history = useHistory();
  const [teachers, setTeachers] = useState([]);
  const [schools, setSchools] = useState([]);
  const [isFetchingTeachers, setIsFetchingTeachers] = useState(false);
  const [isFetchingSchools, setIsFetchingSchools] = useState(false);
  const [search, setSearch] = useState('');
  const [school, setSchool] = useState('');
  const [showCreateTeacherDialog, setShowCreateTeacherDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const { values, handleChange, handleSubmit, resetField, resetForm } = useForm(handleSearch, {
    search: '',
  });
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination({
    page: 0,
    rowsPerPage: 10
  });

  const fetchTeachers = useCallback(async () => {
    setIsFetchingTeachers(true);
    try {
      const teachers = await getTeachers();
      setTeachers(teachers);
      setIsFetchingTeachers(false);
    } catch(error) {
      setError('Opettajia ei voitu hakea. Yritä myöhemmin uudelleen');
      setIsFetchingTeachers(false);
    }
  }, []);

  const fetchSchools = useCallback(async () => {
    setIsFetchingSchools(true);
    try {
      const schools = await getSchools();
      setSchools(schools);
      setIsFetchingSchools(false);
    } catch(error) {
      setError('Kouluja ei voitu hakea.');
      setIsFetchingSchools(false);
    }
  }, []);

  useEffect(() => {
    fetchTeachers();
    fetchSchools();
  }, [fetchSchools, fetchTeachers])



  function handleSearch({ values }) {
    handleChangePage(null, 0);
    setSearch(values.search);
    setSchool(values.school);
  }

  function handleSelectTeacher(id) {
    history.push(`${ routes.TEACHERS }/${ id }`);
  }

  const visibleTeachers = teachers.filter(teacher => {
    const nameMatch = !search || (` ${ teacher.lastName } ${ teacher.firstName }`).toLowerCase().includes(search.toLowerCase());
    const emailMatch = !search || teacher.email.toLowerCase().includes(search.toLowerCase());
    const schoolMatch = !school || teacher.schoolId === school.id;
    return (
      (nameMatch || emailMatch)
      && schoolMatch
    );
  });

  return (
    <>
      <AppLoader active={ isFetchingSchools || isFetchingTeachers } />
      <Grid container>
        <Grid item xs={ 8 }>
          <form className="search-form" onSubmit={ handleSubmit }>
            <TextField
              name="search"
              value={ values.search }
              onChange={ handleChange }
              size="small"
              label="Nimi tai sähköpostiosoite"
              variant="outlined"
              InputProps={{
                  endAdornment: <InputAdornment position="end">
                      <IconButton size="small"
                          onClick={ () => resetField('search') }
                      >
                          <Cancel />
                      </IconButton>
                  </InputAdornment>
              }}
            />
            <Autocomplete
              options={ ['', {
                id: null,
                name: 'Koulua ei valittu'
              }].concat(schools) }
              name="school"
              getOptionLabel={ (option) => {
                  if (!option) {
                      return '';
                  }
                  return option.name;
              } }
              value={ values.school || '' }
              onChange={ (event, value) => handleChange({
                  target: {
                      name: 'school',
                      value
                  }
              }) }
              renderInput={(params) => <TextField {...params} size="small" label="Koulu" variant="outlined" />}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Hae
            </Button>
          </form>
        </Grid>
        <Grid item xs={ 4 }>
          <Button
            className={ classes.addButton }
            variant="contained"
            color="primary"
            type="submit"
            startIcon={ <AddIcon /> }
            onClick={ () => setShowCreateTeacherDialog(true) }
          >
              Lisää opettaja
          </Button>
        </Grid>
      </Grid>
      { successMessage && <Alert className="mb-3" severity="success">{ successMessage }</Alert>}
      { error && <Alert className="mb-3" severity="error">{ error }</Alert> }

      <TableContainer component={ Paper }>
        <Table aria-label="Opettajat-taulukko">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nimi</StyledTableCell>
              <StyledTableCell>Sähköposti</StyledTableCell>
              <StyledTableCell>Koulu</StyledTableCell>
              <StyledTableCell>Tilaus päättyy</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { (rowsPerPage > 0
                ? visibleTeachers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : visibleTeachers
              ).map(teacher => {
                const activeSubscription = teacher.activeSubscription ?  new Date(teacher.activeSubscription).toLocaleDateString() : 'Ei voimassa';
                return (
                  <TableRow
                    key={teacher.id}
                    hover
                    onClick={ () => handleSelectTeacher(teacher.id) }
                  >
                    <TableCell>
                      { teacher.lastName } { teacher.firstName }
                    </TableCell>
                    <TableCell>{ teacher.email }</TableCell>
                    <TableCell>{ teacher.school ? teacher.school.name : 'Koulua ei valittu' }</TableCell>
                    <TableCell>{ activeSubscription }{ teacher.suspended && <Chip className="ml-3" label="Tili lukittu" color="secondary" /> }</TableCell>
                  </TableRow>
                )
            }) }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="Näytä sivulla"
                page={ page }
                count={ visibleTeachers.length }
                onChangePage={ handleChangePage }
                rowsPerPage={ rowsPerPage }
                onChangeRowsPerPage={ handleChangeRowsPerPage }
                labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                rowsPerPageOptions={[10, 20, 30]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <TeacherDialog
        onUpdate={ fetchTeachers }
        onAddSchool={ fetchSchools }
        onClose={ () => history.push(routes.TEACHERS) }
        onDeleteSuccess={ (teacher) => {
          setSuccessMessage(`Opettaja ${ teacher.firstName} ${ teacher.lastName } poistettu onnistuneesti`);
          fetchTeachers();
        }}
        teacher={ teachers.find(teacher => teacher.id === parseInt(teacherId)) }
        open={ !!teacherId }
        schools={ schools }
      />
      <CreateTeacherDialog
        open={ showCreateTeacherDialog }
        schools={ schools }
        onAddSchool={ fetchSchools }
        onClose={ () => setShowCreateTeacherDialog(false) }
        onCreateSuccess={ (teacher) => {
          setSuccessMessage(`Opettaja ${ teacher.firstName} ${ teacher.lastName } luotu onnistuneesti`);
          fetchTeachers();
        }}
      />
    </>
  );
}