import { axiosApi as api } from 'api';

const TAG = '[pathwayService]';

function formatPathways(pathways) {
    const formattedPathways = pathways.map(pathway => formatPathwayCards(pathway));
    console.log(TAG, 'formatted pathways', formattedPathways);
    return formattedPathways;
}

function formatPathwayCards(pathway) {
    const formattedPathway = {
        ...pathway
    };
    const cards = [];
    cards.length = 9;
    cards.fill(null);
    formattedPathway.cards = cards.map((card, index) => {
        return pathway.cards.find(pathwayCard => pathwayCard.index === index);
    });
    return formattedPathway;
}

function prepareCards(cards) {
    const preparedCards = cards.map((card, index) => {
        if (card) {
            return {
                ...card,
                index
            };
        }
        return null;
    });
    return preparedCards.filter(card => !!card);
}

export async function insertPathway(data) {
    const pathway = {
        ...data
    };
    pathway.cards = prepareCards(pathway.cards);
    const response = await api.post('/users/me/pathways', pathway);
    return response.data.id;
}

export async function updatePathway(id, pathway) {
    const update = {
        ...pathway
    };
    update.cards = prepareCards(update.cards);
    const response = await api.put('/pathways/' + id, update);
    return response.data.id;
}

export async function getMyPathways() {
    const response = await api.get('/users/me/pathways');
    return response.data.pathways;
}

export async function getPrivatePathways() {
    const response = await api.get('/users/me/pathways/private');
    return response.data.pathways;
}

export async function getPendingPathways() {
    const response = await api.get('/pathways/pending');
    return response.data.pathways;
}

export async function getRejectedPathways() {
    const response = await api.get('/pathways/rejected');
    return response.data.pathways;
}

export async function getPublishedPathways() {
    const response = await api.get('/pathways/published');
    return response.data.pathways;
}

export async function getLatestPathways() {
    const response = await api.get('/pathways/published/latest');
    return response.data.pathways;
}

export async function getHiddenPathways() {
    const response = await api.get('/pathways/hidden');
    return response.data.pathways;
}

export async function suggestPathway(id) {
    const response = await api.post(`/users/me/pathways/${ id }/suggest`);
    return response.data.id;
}

export async function publishPathway(id) {
    const response = await api.post(`/users/me/pathways/${ id }/publish`);
    return response.data.pathways;
}

export async function getPathway(id) {
    const response = await api.get(`/pathways/${ id }`);
    return formatPathwayCards(response.data.pathway);
}

export async function publishHiddenPathway(id) {
    const response = await api.put(`/pathways/${ id }/publish`);
    return response.data;
}

export async function hidePathway(id) {
    const response = await api.put(`/pathways/${ id }/hide`);
    return response.data.pathways;
}

export async function rejectPathway(id, formData) {
    const response = await api.put(`/pathways/${ id }/reject`, formData);
    return response.data.pathway;
}

export async function deletePathway(id) {
    const response = await api.delete(`/users/me/pathways/${ id }`);
    return response.data.pathways;
}

export async function deletePublicPathway(id) {
    const response = await api.delete(`/pathways/${ id }`);
    return response.data.pathways;
}

export async function clonePathway(id) {
    const response = await api.post(`/pathways/${ id }/clone`);
    return response.data;
}