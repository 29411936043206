import { axiosApi as api } from 'api';

export async function getSubscriptions() {
    const response = await api.get('/subscriptions');
    return response.data.subscriptions;
}

export async function getSubscriptionsByUserId(id) {
    const response = await api.get(`/users/${ id }/subscriptions`);
    return response.data.subscriptions;
}

export async function getMySubscriptions(id) {
    const response = await api.get(`/users/me/subscriptions`);
    return response.data.subscriptions;
}

export async function deleteSubscription(id) {
    return api.delete('/subscriptions/' + id);
}

export async function createSubscription(userId, data) {
    return await api.post(`/users/${ userId }/subscriptions`, data);
}