import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { AppLoader } from 'utils/AppLoader';
import useForm from 'hooks/useForm';
import { validateSchool } from 'validation/validate';
import { createSchool } from 'services/schoolService';
import Alert from '@material-ui/lab/Alert';

// const useStyles = makeStyles({
//     deleteButton: {
//         marginLeft: '2rem'
//     }
// });

const CreateSchoolDialog = ({
    onClose,
    onAdd,
    ...rest
}) => {
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        resetForm
    } = useForm(onSubmit, {
        name: ''
    }, validateSchool);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(false);

    function handleClose() {
        resetForm();
        onClose();
    }

    async function onSubmit({
        values,
        resetForm
    }) {
        try {
            setIsFetching(true);
            await createSchool(values);
            setIsFetching(false);
            onAdd();
            onClose();
        } catch(error) {
            setError('Tapahtui virhe. Yritä myöhemmin uudelleen');
            setIsFetching(false);
        }
    }

    return (
        <Dialog scroll="body" { ...rest }>
            <AppLoader active={ isFetching } />
            <DialogTitle>
                Lisää koulu
            </DialogTitle>
            <DialogContent>
                { error &&
                    <Alert
                        className="mb-3"
                        severity="error"
                    >
                        { error }
                    </Alert>
                }
                <form onSubmit={ handleSubmit }>
                    <TextField
                        error={ !!errors.name }
                        helperText={ errors.name }
                        name="name"
                        value={ values.name || '' }
                        onChange={ handleChange }
                        size="small"
                        label="Koulun nimi"
                        variant="outlined"
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            className="mr-2"
                            variant="contained"
                            color="secondary"
                            onClick={ handleClose }
                            type="button"
                        >
                            Sulje
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Tallenna
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateSchoolDialog;