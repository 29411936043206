import React from 'react';
import { connect } from 'react-redux';
import {
    openEditor,
    removeTool
} from 'actions/cardEditor';
import { toolIcons } from 'components/Card/images';
import EditorTrigger from 'components/editor/EditorTrigger';
import './ToolIcon.css';


const ToolIcon = ({ canEdit, dispatch, tool, index }) => {
    return (
        <div className="tool-icon">
            { canEdit && <EditorTrigger
                onDelete={ () => dispatch(removeTool(index))}
                onEdit={ () => dispatch(openEditor('TOOL', index))}/>
            }
            <img
                className="tool-icon-image"
                src={ toolIcons[tool.icon] }
                alt={ tool.name } />
        </div>
    );
};

export default connect(null)(ToolIcon);