import React from 'react';
import { connect } from 'react-redux';
import { convertToRaw, ContentState, Editor, EditorState} from 'draft-js';
import {
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { closeNodeEditor, undoChanges } from '../../actions/cardEditor';
import './ContentTextEditor.css';

const availableFontSizes = [
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24
];

const availableLineHeights = [
  1.0,
  1.25,
  1.5,
  1.75,
  2.0
];

class ContentTextEditor extends React.Component {
  constructor(props) {
    super(props);
    const editorState = !!this.props.data ? this.initializeWithText(this.props.data) : EditorState.createEmpty();
    this.state = {
        editorState,
        fontSize: this.props.style.fontSize,
        lineHeight: this.props.style.lineHeight
    };
    this.editorRef = React.createRef();
  };

  componentDidMount = () => {
    this.editorRef.current.focus();
  };

  initializeWithText = (string) => {
    return EditorState.createWithContent(ContentState.createFromText(string))
  };

  convertContentToStringAndDispatch = () => {
    const editorState = this.state.editorState;
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const blockText = blocks.map((block) => block.text);
    const data = blockText.join('\n');
    const fontSize = this.state.fontSize;
    const lineHeight = this.state.lineHeight;
    const style = this.props.style;
    const update = {
      data,
      style: {
        ...style,
        fontSize,
        lineHeight
      }
    }
    this.props.dispatch(this.props.onSaveChanges(update));
    this.props.dispatch(closeNodeEditor());
  };

  undoChanges = () => {
    this.props.dispatch(undoChanges());
    this.props.dispatch(closeNodeEditor());
  };

  render() {
    const scaledFontSize = (this.props.scale * this.state.fontSize) + 'pt';
    return (
      <div style={{ lineHeight: this.state.lineHeight, fontSize: scaledFontSize, height: '100%' }}>
        <Editor ref={ this.editorRef }
          placeholder="Kirjoita tähän..."
          editorState={this.state.editorState}
          onChange={ (editorState) => this.setState({
            editorState
          })}
        />
        <div className="content-text-editor-buttons">
            <FormControl>
              <InputLabel id="font-selector-label">Fonttikoko</InputLabel>
              <Select
                value={ this.state.fontSize }
                onChange={ (event) => this.setState({ fontSize: event.target.value }) }
                >
                  { availableFontSizes.map((fontSize) => <MenuItem value={ fontSize }>{ fontSize }</MenuItem>) }
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="font-selector-label">Riviväli</InputLabel>
              <Select
                value={ this.state.lineHeight }
                onChange={ (event) => this.setState({ lineHeight: event.target.value }) }
                >
                  { availableLineHeights.map((lineHeight) => <MenuItem value={ lineHeight }>{ lineHeight }</MenuItem>) }
              </Select>
            </FormControl>
            <Fab
              color="primary"
              size="small"
              onClick={ this.convertContentToStringAndDispatch }>
                <CheckCircleOutlineIcon />
            </Fab>
        </div>
      </div>
    );
  }
}

export default connect(null)(ContentTextEditor);