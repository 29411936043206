import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import star from '../../assets/images/star_gold.png';

const styles = StyleSheet.create({
    row: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        flexGrow: 0,
        justifyContent: 'flex-start',
        width: '100%',
        padding: '2% 0'
    },
    rowLeftContainer: {
        flexBasis: '7%',
        flexGrow: '0',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowContent: {
        display: 'flex',
        flexBasis: '90%',
        flexGrow: '0',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: '100%',
        justifyContent: 'flex-start'
    },
    row1_2: {
        height: '50%'
    },
    row1_3: {
        height: '33%'
    },
    levelIndicator: {
        margin: '5% 0',
        width: '24pt'
    }
});

const PDFRow = ({ children, className, difficultyLevel, secondaryColor }) => {
    const classNameStyles = className.split(' ').map((className) => styles[className]);
    const componentStyles = [ styles.row ].concat(classNameStyles);
    const stars = [];
    if (difficultyLevel && Number.isInteger(difficultyLevel)) {
        stars.length = difficultyLevel;
        stars.fill(<Image style={ styles.levelIndicator } src={ star } />);
    }
    return (
        <View style={ componentStyles }
            // debug={ true }
        >
            <View
                style={ [
                    styles.rowLeftContainer,
                    { backgroundColor: secondaryColor }
                ] }
                // debug={ true }
            >
                { stars }
            </View>
            <View
                style={ styles.rowContent }
                // debug={ true }
            >
                { children }
            </View>
        </View>
    );
};

export default PDFRow;