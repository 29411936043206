import React from 'react';
import { connect } from 'react-redux';
import './AddContentTrigger.css';
import { openEditor } from '../../actions/cardEditor';
import AddCircle from '@material-ui/icons/AddCircle';

import { IconButton } from '@material-ui/core';

function AddToolTrigger({ dispatch }) {
    return (
        <div className="content-creator">
            <IconButton size="small"
                onClick={ () => dispatch(openEditor('TOOL')) }><AddCircle /></IconButton>
        </div>
    );
}

export default connect(null)(AddToolTrigger);