import React from 'react';
import { saveAs } from 'file-saver';
import { ListItem, makeStyles } from '@material-ui/core';
import { getFile } from 'services/supportService';

const useStyles = makeStyles((theme) => ({
    item: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    },

  }));

export default function DownloadLink({
    fileName,
    label
}) {
    const classes = useStyles();

    async function handleDownload(e) {
        e.preventDefault();
        try {
            const file = await getFile(fileName);
            const documentName = label.replaceAll(' ', '_');
            saveAs(file, `${ documentName }.pdf`);
        } catch(error) {
            console.error(error);
        }
    }

    return (
        <ListItem
            className={ classes.item }
            button
            onClick={ handleDownload }
        >{ label }</ListItem>
    );
}