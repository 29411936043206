import React, { useState } from 'react';
import {
  Tabs,
  Tab
} from '@material-ui/core';
import CardLibraryTab from 'components/layout/CardLibraryTab';
import PathwayLibrary from 'components/layout/PathwayLibrary';
import { Tabbed } from 'components/layout/Tabbed';

const LibraryPage = ({
  published = false,
  pending = false
}) => {
  return (
    <div className="app-wrapper">
      <Tabbed
          key={ published }
          cardTab={ <CardLibraryTab published={ published } pending={ false } /> }
          /* cardPendingTab={ pending ? <CardLibraryTab published={ published } pending={ true } /> : null } */
          pathwaysTab={ <PathwayLibrary published={ published } pending={ false } /> }
          /* pathwaysPendingTab={ pending ? <PathwayLibrary published={ published } pending={ true } /> : null } */
        />
    </div>
  );
}

export default LibraryPage;