import React from 'react';
import { connect } from 'react-redux';
import './AddContentTrigger.css';
import { addImageNode, addTextNode, addQRNode } from '../../actions/cardEditor';
import TextFields from '@material-ui/icons/TextFields';
import Image from '@material-ui/icons/Image';
import { IconButton } from '@material-ui/core';
import QRCodeIcon from 'components/QRCodeIcon';

function AddContentTrigger({ id, dispatch }) {
    return (
        <div className="content-creator">
            <IconButton size="small"
                onClick={ () => dispatch(addTextNode(id)) }><TextFields /></IconButton>
            <div>
              <IconButton size="small"
                  onClick={ () => dispatch(addImageNode(id)) }><Image /></IconButton>
              <IconButton size="small"
                  onClick={ () => dispatch(addQRNode(id))}><QRCodeIcon /></IconButton>
            </div>
        </div>
    );
}

export default connect(null)(AddContentTrigger);