import { useSelector } from "react-redux";

const useRoles = () => {
    const userRoles = useSelector(state => {
        if (!state.auth.user) {
            return [];
        }
        return state.auth.user.roles;
    });
    return userRoles;
}

export default useRoles;