import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import PDFHeader from './PDFHeader';
import PDFFooter from './PDFFooter';
import PDFContent from './PDFContent';
import LatoRegular from '../../assets/fonts/Lato-Regular.ttf';
import LatoBold from '../../assets/fonts/Lato-Bold.ttf';

const hyphenationCallback = (word) => [word];

Font.register({
  family: 'Lato',
  fonts: [
    { src: LatoRegular }, // font-style: normal, font-weight: normal
    { src: LatoBold, fontStyle: 'bold' }
  ]
});

Font.registerHyphenationCallback(hyphenationCallback);

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'block',
    fontFamily: 'Lato'
  }
});

// Create Document Component
function PDFCard({ cards, ...rest }) {
      return (
        <Document>
            { cards.map((card) => {
              return (
                <Page size="A5"
                  style={ styles.page }
                  wrap={ false }
                  // debug={ true }
                >
                    <PDFHeader card={ card } { ...rest } />
                    <PDFContent card={ card } { ...rest } />
                    <PDFFooter card={ card } { ...rest } />
                </Page>
              )}) }
        </Document>);
};

export default PDFCard;

