import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { userCards } from './reducers/userCards';
import { userPathways } from './reducers/userPathways';
import { publishedCards } from './reducers/publishedCards';
import { publishedPathways } from './reducers/publishedPathways';
import { loader } from './reducers/loader';
import { error } from './reducers/error';
import { cardEditor } from './reducers/cardEditor';
import { metadata } from './reducers/metadata';
import { pathwayEditor } from './reducers/pathwayEditor';
import { templates } from './reducers/templates';
import { auth } from './reducers/auth';
import { filters } from './reducers/filters';
import { LOGOUT_CLIENT } from './actions/actions';

const appReducer = combineReducers({
    auth,
    error,
    cardEditor,
    pathwayEditor,
    loader,
    metadata,
    templates
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_CLIENT) {
        state = {
            auth: undefined,
            metadata: state.metadata
        };
    }
    return appReducer(state, action);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    {},
    composeEnhancers(
        applyMiddleware(
        ReduxThunk
    ))
);

export default store;
