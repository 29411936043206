import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
    Button,
    TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useForm from 'hooks/useForm';
import { initPasswordReset, resetPassword, validatePasswordReset } from 'services/authService';
import { AppLoader } from 'utils/AppLoader';
import { routes } from 'routers/routes';
import './ResetPasswordPage.css';
import { validateResetPassword } from 'validation/validate';

const ResetPasswordPage = () => {
    const { id, token } = useParams();
    const history = useHistory();

    const [isFetching, setIsFetching] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        async function validateToken() {
            try {
                setIsFetching(true);
                await validatePasswordReset(id, token);
                setIsValid(true);
                setIsFetching(false);
            } catch (error) {
                setIsFetching(false);
                setIsValid(false);
            }
        }
        validateToken();
    }, [id, token]);

    async function onSubmit({ values }) {
        try {
            setIsFetching(true);
            await resetPassword(id, token, values);
            setError(null);
            setSuccess(true);
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            setError('Tapahtui virhe. Yritä myöhemmin uudelleen.');
        }
    }

    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(onSubmit, {
        password: ''
    }, validateResetPassword);

    let content = null;
    if (success) {
        content = (
            <Alert
                className="mt-3"
                severity="success"
            >
                <p>Salasana on vaihdettu onnistuneesti.</p>
                <p><Link to={ routes.LOGIN }>Kirjaudu sisään</Link></p>
            </Alert>
        )
    } else {
        content = (
            <>
                { !isValid ?
                    <Alert
                        className="mt-3"
                        severity="error"
                    >
                        Salasanan palauttamiseen tarvittavat tiedot ovat virheelliset. Aloita tarvittaessa uusi salasanan palautusprosessi.
                    </Alert>
                :
                    <>
                        <AppLoader active={ isFetching } component={false} />
                        <form className="login-form d-flex flex-column" onSubmit={ handleSubmit }>
                            <TextField
                                error={ !!errors.password }
                                helperText={ errors.password }
                                name="password"
                                value={ values.password || '' }
                                onChange={ handleChange }
                                size="small"
                                label="Uusi salasana"
                                variant="outlined"
                                type="password"
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Lähetä
                            </Button>
                            { error &&
                                <Alert
                                    className="mt-3"
                                    severity="error"
                                >
                                    {error}
                                </Alert>
                            }
                        </form>
                    </>
                }
            </>
        )
    }

    return (
        <div className="d-flex flex-column align-items-center">
            { content }
        </div>
    )
}

export default ResetPasswordPage;