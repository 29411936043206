import React from 'react';

export default function VideoEmbed({
    title,
    videoId
}) {
    return (
        <div class="embed-responsive embed-responsive-16by9">
            <iframe
                title={ title }
                id="ytplayer"
                type="text/html"
                width="640"
                height="360"
                src={ `https://www.youtube.com/embed/${ videoId }`}
                frameborder="0"
                allow="fullscreen;"></iframe>
        </div>

    );
}