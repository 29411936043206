import React, { useState } from 'react';
import { Button, TextField, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add } from '@material-ui/icons';
import './TeacherForm.css';
import CreateSchoolDialog from '../CreateTeacherDialog/CreateSchoolDialog';

const TeacherForm = ({
    edit = false,
    errors = {},
    onCancel,
    onChange,
    onSubmit,
    onAddSchool,
    schools = [],
    values = {}
}) => {
    const [showCreateSchoolDialog, setShowCreateSchoolDialog] = useState(false);

    return  (
        <>
            <form
                className="d-flex flex-column create-teacher-form"
                onSubmit={ onSubmit }
            >
                <TextField
                    error={ !!errors.firstName }
                    helperText={ errors.firstName }
                    name="firstName"
                    value={ values.firstName || '' }
                    onChange={ onChange }
                    size="small"
                    label="Etunimi"
                    variant="outlined"
                />
                <TextField
                    error={ !!errors.lastName }
                    helperText={ errors.lastName }
                    name="lastName"
                    value={ values.lastName || '' }
                    onChange={ onChange }
                    size="small"
                    label="Sukunimi"
                    variant="outlined"
                />
                <TextField
                    error={ !!errors.email }
                    helperText={ errors.email }
                    name="email"
                    value={ values.email || '' }
                    onChange={ onChange }
                    size="small"
                    label="Sähköpostiosoite"
                    variant="outlined"
                />
                <div className="school-input">
                    <Autocomplete
                        error={ !!errors.school }
                        helperText={ errors.school }
                        options={ [''].concat(schools) }
                        name="school"
                        getOptionLabel={ (option) => {
                            if (!option) {
                                return '';
                            }
                            return option.name;
                        } }
                        value={ values.school || '' }
                        onChange={ (event, value) => onChange({
                            target: {
                                name: 'school',
                                value
                            }
                        }) }
                        renderInput={(params) => <TextField {...params} size="small" label="Koulu" variant="outlined" />}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        startIcon={ <Add /> }
                        onClick={ () => setShowCreateSchoolDialog(true) }
                    >
                        Lisää
                    </Button>
                </div>
                { !edit &&
                    <>
                        <InputLabel id="subscription-date-label">Tilauksen päättymispäivämäärä</InputLabel>
                        <TextField
                            labelId="subscription-date-label"
                            type="date"
                            error={ !!errors.subscriptionExpiryDate }
                            helperText={ errors.subscriptionExpiryDate }
                            name="subscriptionExpiryDate"
                            value={ values.subscriptionExpiryDate || '' }
                            onChange={ onChange }
                            size="small"
                            variant="outlined"
                        />
                    </>
                }
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={ onCancel }
                        type="button"
                    >
                        Peruuta
                    </Button>
                    <Button
                        className="ml-2"
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Tallenna
                    </Button>
                </div>
            </form>
            <CreateSchoolDialog
                open={ showCreateSchoolDialog }
                onAdd={ onAddSchool }
                onClose={ () => setShowCreateSchoolDialog(false) }
            />
        </>
    );
}

export default TeacherForm;