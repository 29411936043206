import React from 'react';
import { Grid, Typography, List } from '@material-ui/core';
import DownloadLink from './DownloadLink';
import VideoItem from './VideoItem';
import LeadoHorizontalRule from 'components/layout/LeadoHorizontalRule';

const videos = [
  {
    title: "Oppikirjat ja Vihkot",
    videoId: "zzD0TS5OW30"
  },
  {
    title: "Pelit taskustoissa",
    videoId: "arrT0AO7jlA"
  },
  {
    title: "Materiaalin uusiokäyttö",
    videoId: "kkAvVZEP37I"
  },
  {
    title: "Monisteet",
    videoId: "33wgMm0KSkk"
  },
  {
    title: "Liikunnan passi",
    videoId: "7xpej0slSt0"
  },
  {
    title: "Erityisen tuen muotoja - valokuvakortit",
    videoId: "HAU_GqHyaPo"
  },
  {
    title: "Korttien käyttö - prosessien organisointi",
    videoId: "zDW1Td8fJ-k"
  },
  {
    title: "Toiminnallinen matematiikka",
    videoId: "BFQrh-qhKAg"
  },
  {
    title: "Tarrat",
    videoId: "51ZX5DA2ICs"
  }
];

const teacherVideos = [
  {
    title: "Käyttöideoita LeaDo taskustoihin Hennan kanssa",
    videoId: "StE_9UGPLKY"
  },
  {
    title: "Oppimassa ja opettamassa Elinan kanssa",
    videoId: "zLHaGqgbvmY"
  }
];

const supportMaterials = [
  {
    title: "LeaDo-ohjeet",
    documents: [
      {
        fileName: "Leado-Turvallisuus0.2.pdf",
        label: "Leado-turvallisuusohje 1"
      },
      {
        fileName: "Leado-Turvallisuus0.5.pdf",
        label: "Leado-turvallisuusohje 2"
      },
      {
        fileName: "leado-vasui_2021-05-24.pdf",
        label: "Leado-esiopetussuunnitelma"
      }
    ]
  },
  {
    documents: [

    ]
  },
  {
    title: "Leado-passeja",
    documents: [
      {
        fileName: "LeaDoPassi-1.pdf",
        label: "Leado-passi 1"
      },
      {
        fileName: "LeaDoPassi-2.pdf",
        label: "Leado-passi 2"
      },
      {
        fileName: "LeaDoPassiA4-2.pdf",
        label: "Leado-passi A4"
      },
      {
        fileName: "LeaDoPassiSISA.pdf",
        label: "Leado-passi - Sisä"
      }
    ]
  },
  {
    title: "Ladattavia liikuntakortteja",
    documents: [
      {
        fileName: "Liikuntakortit-Sivu1.pdf",
        label: "Liikuntakortit 1"
      },
      {
        fileName: "Liikuntakortit-Sivu2.pdf",
        label: "Liikuntakortit 2"
      },
      {
        fileName: "Liikuntakortit-Sivu3.pdf",
        label: "Liikuntakortit 3"
      },
      {
        fileName: "Liikuntakortit-Sivu4.pdf",
        label: "Liikuntakortit 4"
      }
    ]
  },
  {
    title: "Pelipohjia",
    documents: [
      {
        fileName: "Pelipohja01.pdf",
        label: "Pelipohja 1"
      },
      {
        fileName: "Pelipohja02.pdf",
        label: "Pelipohja 2"
      }
    ]
  },
  {
    title: "Muut",
    documents: [
      {
        fileName: "LEADO_jokerikortti_eitaustaa.pdf",
        label: "LeaDo-jokerikortti (ei taustaa)"
      },
      {
        fileName: "LeaDo-Kannustuslausetaulukko.pdf",
        label: "LeaDo-kannustuslausetaulukko"
      },
      {
        fileName: "LeaDo-KommunikointikorttiOPE.pdf",
        label: "LeaDo-kommunikointikortti (ope)"
      },
      {
        fileName: "LeaDo-KommunikointikorttiOPPILAS.pdf",
        label: "LeaDo-kommunikointikortti (oppilas)"
      },
      {
        fileName: "LeaDo-Materiaalit.pdf",
        label: "LeaDo-materiaalit"
      },
      {
        fileName: "LeaDoHenkilokortti.pdf",
        label: "LeaDo-henkilökortti"
      }
    ]
  }
];

export default function SupportPage() {
    return (
      <>
        <Typography variant="h5" component="h2" gutterBottom>Ladattavat materiaalit</Typography>
        { supportMaterials.map(({ title, documents }) => {
          return (
            <div className="support-materials-section" style={{
              width: '50%',
              maxWidth: '600px',
              miinWidth: '300px'
            }}>
              { title && <Typography variant="h6" component="h3" gutterBottom={ true }>{ title }</Typography> }
              { documents && !!documents.length &&
                <List>
                  { documents.map(({ fileName, label }) => <DownloadLink key={ fileName } fileName={ fileName } label={ label } />) }
                </List>
              }
            </div>
          );
        })}
        <LeadoHorizontalRule />
        <Typography variant="h5" component="h2" gutterBottom>LeaDo-videot</Typography>
        <Grid container spacing={ 8 }>
          { videos.map(({ title, videoId }) => <VideoItem key={ videoId } title={ title } videoId={ videoId } />)}
        </Grid>
        <Grid container spacing ={ 8 }>
          { teacherVideos.map(({ title, videoId }) => <VideoItem key={ videoId } title={ title } videoId={ videoId } />)}
        </Grid>
      </>
    );
}