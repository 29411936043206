import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from 'components/LoginForm';
import { login } from 'services/authService';
import { onLoginSuccess } from 'actions/auth';
import './LoginPage.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { routes } from 'routers/routes';
import { AppLoader } from 'utils/AppLoader';
import logo from 'assets/images/logo_medium.png';
import Alert from '@material-ui/lab/Alert';


const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const [loginError, setLoginError] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    async function handleLogin({
        values,
        resetForm
    }) {
        try {
            setIsFetching(true);
            setLoginError('');
            const response = await login(values);
            dispatch(onLoginSuccess(response));
        } catch(error) {
            let message = error.response.data.message;

            switch (message) {
                case "LoginError":
                    message = 'Tunnistautuminen epäonnistui. Tarkista kirjautumistiedot.';
                    break;
                case "UserSuspended":
                    message = 'Käyttäjätili on lukittu.';
                    break;
                case "UserNoActiveSubscription":
                    message = 'Käyttäjätilillä ei ole aktiivista tilausta.';
                    break;
                default:
                    break;
            }

            setLoginError(message);
            resetForm();
            setIsFetching(false);
        }
    }

    if (user) {
        return <Redirect to={ routes.DASHBOARD } />
    }

    return (
        <>
            <AppLoader active={ isFetching } component={false} />
            <div className="d-flex flex-column align-items-center">
                <img className="logo mb-5" src={ logo } alt="LeaDo logo" />
                { loginError &&
                    <Alert
                        className="mb-2"
                        severity="error"
                    >
                        { loginError }
                    </Alert>
                }
                <LoginForm onLogin={ handleLogin } />
                <Link className="mt-3" to={ routes.FORGOT_PASSWORD }>
                    Unohditko salasanasi?
                </Link>
            </div>

        </>
    );
};

export default LoginPage;