import {
    LOGIN_SUCCESS,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    SIGNUP_FAILURE,
    LOGIN_FAILURE,
    CONFIRM_LOGOUT,
    CANCEL_LOGOUT
} from './actions';
import {
    post,
    put,
    get,
    del
} from '../api';

export function onLoginSuccess(data) {
    const user = data.user;
    const token = data.token;
    if (token) {
        localStorage.setItem('token', token);
    }
    return {
        type: LOGIN_SUCCESS,
        user
    };
}

export function refreshLogin() {
    return async dispatch => {
        try {
            const data = await get('/users/me', true);
            dispatch(onLoginSuccess(data));
        } catch(error) {
            dispatch(onLoginFailure(error));
        }
    }
}

function onLoginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        error
    }
}

export function confirmLogout() {
    return {
        type: CONFIRM_LOGOUT
    }
}

export function cancelLogout() {
    return {
        type: CANCEL_LOGOUT
    }
}

export function logoutClient() {
    localStorage.removeItem('token');
    return {
        type: LOGOUT_CLIENT
    };
}

export function signup(userData) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const response = await post('/users', userData , false);
            localStorage.setItem('token', response.token);
            dispatch(onSignupSuccess(response));
        } catch(error) {
            dispatch(onSignupFailure(error));
            throw new Error();
        }
    }
}

function onSignupSuccess(response) {
    const user = response.user;
    const token = response.token;
    return {
        type: SIGNUP_SUCCESS,
        user,
        token
    };
}

function onSignupFailure(error) {
    return {
        type: SIGNUP_FAILURE,
        error
    }
}

export function onUpdateProfileSuccess(user) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        user
    };
}

export function updatePassword(update) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await put(`/users/me/password`, update);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function initPasswordReset(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await post(`/password-reset`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function validateResetToken(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await get(`/reset-password/${data.id}/${data.token}`);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function resetPassword(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await post(`/reset-password`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function deleteProfile() {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await del(`/users/me`, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

function onAuthRequest() {
    return {
        type: AUTH_REQUEST
    }
}

function onAuthSuccess() {
    return {
        type: AUTH_REQUEST_END
    }
}


function onAuthError(error) {
    return {
        type: AUTH_REQUEST_END,
        error
    }
}

