import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routers/routes';
import { createPathway } from '../../actions/pathwayEditor';
import FilterForm from './FilterForm';
import GridList from './GridList';
import { editPathway } from '../../actions/pathwayEditor';
import PathwayItem from './PathwayItem';
import PathwayViewerDialog from './PathwayViewerDialog';
import CardViewerDialog from './CardViewerDialog';
import Expandable from './Expandable';
import EmptyListItem from './EmptyListItem';
import { useState } from 'react';
import {
    getMyPathways,
    getPublishedPathways,
    getHiddenPathways,
    getPendingPathways,
    getRejectedPathways,
    getPrivatePathways
} from 'services/pathwayService';
import useFilters from 'hooks/useFilters';
import { AppLoader } from 'utils/AppLoader';
import Alert from '@material-ui/lab/Alert';
import { TablePagination } from '@material-ui/core';
import usePagination from 'hooks/usePagination';
import useUser from 'hooks/useUser';

const TAG = '[PathwayLibrary]';

function PathwayLibrary({ published = false }) {
    const history = useHistory();
    const user = useUser();
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState('');
    const [isFetching, setIsFetching] = useState(true);
    const [selectedPathway, setSelectedPathway] = useState();
    const [selectedCard, setSelectedCard] = useState();
    const [filters, setFilters] = useState({});
    const [pathways, setPathways] = useState([]);
    const [error, setError] = useState('');
    const filterPathways = useFilters();

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        initRowsPerPage,
    } = usePagination({
        page: 0,
        rowsPerPage: 64,
    });

    const fetchPathways = useCallback(async () => {
        let action = getMyPathways;
        let status = '';

        if (published) {
            status = filters.onlyHidden
            ? 'HIDDEN'
            : filters.onlyPending
            ? 'PENDING'
            : 'PUBLISHED'
        } else {
            status = filters.status;
        }

        switch (status) {
            case 'HIDDEN':
                action = getHiddenPathways;
                break;
            case 'PRIVATE':
                action = getPrivatePathways;
                break;
            case 'PENDING':
                action = getPendingPathways;
                break;
            case 'REJECTED':
                action = getRejectedPathways;
                break;
            case 'PUBLISHED':
                action = getPublishedPathways;
                break;
            default:
                action = getMyPathways;
                break;
        }

        setIsFetching(true);
        try {
            const pathways = await action();
            setPathways(pathways);
            setIsFetching(false);
        } catch(error) {
            setError('Oppimispolkuja ei voitu hakea. Yritä myöhemmin uudelleen');
            setIsFetching(false);
        }
    }, [published, filters.status, filters.onlyHidden, filters.onlyPending]);

    useEffect(() => {
        fetchPathways();
    }, [fetchPathways]);

    useEffect(() => {
        handleChangePage(null, 0);
    }, [filters, handleChangePage]);

    const visiblePathways = filterPathways(pathways, { ...filters, orderBy: 'updatedAt', order: 'desc' });
    const gridContent = visiblePathways.map((pathway) => (
        <PathwayItem
            key={pathway.id}
            pathway={pathway}
            handleViewPathway={(viewPathway) => setSelectedPathway(viewPathway.id)}
        />
    ));
    if (!published) {
        gridContent.unshift(<EmptyListItem key="empty-item" handleAddItem={() => {
            dispatch(createPathway(user.id));
            history.push(routes.EDIT_PATHWAY);
        }} />);
    }

    const contentLength = gridContent.length;

    return (
        <div>
            <AppLoader active={isFetching} />
            {error && (
                <Alert className="mb-3" severity="error">
                    {error}
                </Alert>
            )}
            <Expandable label="Suodata oppimispolkuja">
                <FilterForm
                    onChange={({ values }) => setFilters(values)}
                    hiddenFilter={ published }
                />
            </Expandable>
            { successMessage && <Alert className="mb-2" severity="success">{ successMessage }</Alert>}
            <GridList
                rowLength={8}
                minItemWidth="150px"
                currentPage={page}
                cardsPerPage={rowsPerPage}
            >
                {gridContent}
            </GridList>
            <PathwayViewerDialog
                open={!!selectedPathway}
                pathwayId={selectedPathway}
                onClose={() => setSelectedPathway(null)}
                onEdit={(pathway) => {
                    dispatch(editPathway(pathway));
                    history.push(routes.EDIT_PATHWAY);
                }}
                onViewCard={(viewCard) => setSelectedCard(viewCard)}
                onSuggest={(suggested, pathway) => {
                    if (pathway.status === 'REJECTED'){
                      let array = pathways.filter(pathwayItem => pathwayItem.id !== pathway.id)
                      array = array.concat(suggested);
                      setPathways(array);
                    }else {
                      setPathways(pathways.concat(suggested));
                    } 
                    setSuccessMessage(`Polku ${ suggested.title } on lähetetty adminille tarkasteltavaksi.`);
                }}
                onPublish={(published) => {
                    if (filters.onlyHidden || filters.onlyPending) {
                        setPathways(pathways.filter(pathway => pathway.id !== published.id));
                    }
                    setSuccessMessage(`Polku ${ published.title } on julkaistu opettajille.`);
                }}
                onReject={(rejected) => {
                    setPathways(pathways.filter(pathway => pathway.id !== rejected.id));
                    setSuccessMessage(`Polku ${ rejected.title } on hylätty.`);
                }}
                onHide={(hidden) => {
                    setPathways(pathways.filter(pathway => pathway.id !== hidden.id));
                    setSuccessMessage(`Polku ${ hidden.title } on piilotettu opettajilta.`);
                }}
                onDelete={(deleted) => {
                    setPathways(pathways.filter(pathway => pathway.id !== deleted.id));
                    setSuccessMessage(`Polku ${ deleted.title } poistettu onnistuneesti!`);
                } }
                onClone={() => console.log('cloned pathway')}
            />
            <table className="grid-list-pagination">
                <tbody>
                    <tr>
                        <TablePagination
                            labelRowsPerPage="Näytä sivulla"
                            page={page}
                            count={contentLength}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            //labelDisplayedRows={ ({ from, to, count }) => `${ from }-${ to }/${ count }` }
                            labelDisplayedRows={({ from, to, count, page }) =>
                                `${page + 1}/${Math.ceil(count / rowsPerPage)}`
                            }
                            rowsPerPageOptions={[initRowsPerPage]}
                        />
                    </tr>
                </tbody>
            </table>
            <CardViewerDialog
                open={!!selectedCard}
                cardId={selectedCard && selectedCard.id}
                cardStatus={selectedCard && selectedCard.status}
                onlyHidden={filters.onlyHidden}
                onlyPending={filters.onlyPending}
                viewOnly={true}
                onClose={() => setSelectedCard(null)}
            />
        </div>
    );
}

export default PathwayLibrary;
