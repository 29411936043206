import { useState, useCallback } from "react";

const TAG = '[usePagination]';

const usePagination = (defaultValues) => {
    const [page, setPage] = useState(defaultValues.page);
    const [rowsPerPage, setRowsPerPage] = useState(defaultValues.rowsPerPage);
    const initRowsPerPage = defaultValues.rowsPerPage;

    const handleChangePage = useCallback((event, page) => {
        setPage(page);
    }, [])

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    return {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        initRowsPerPage
    };
}

export default usePagination;