import {
    cloneDeep
} from 'lodash';

const initialState = {
    allIds: [],
    byIds: {},
    isFetching: false
};

export const duplicateCard = (card) => {
    const cardCopy = cloneDeep(card);
    delete cardCopy.id;
    delete cardCopy.createdAt;
    delete cardCopy.updatedAt;
    cardCopy.title = `${ cardCopy.title } (Kopio)`;
    return cardCopy;
};

export function userCards(state = initialState, action) {
    switch(action.type) {
        case 'DELETE_USER_CARD' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'DELETE_USER_CARD_SUCCESS' : {
            console.log('[userCards] onDeleteCardSuccess', action.id);
            const allIds = state.allIds.filter((id) => id !== action.id);
            const byIds = {
                ...state.byIds
            };
            delete byIds[action.id];
            return {
                ...state,
                allIds,
                byIds,
                isFetching: false
            }
        }
        case 'DELETE_USER_CARD_FAILURE' : {
            return {
                ...state,
                isFetching: false
            }
        }
        case 'INSERT_USER_CARD_BEGIN' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'INSERT_USER_CARD_SUCCESS': {
            const card = action.card;
            const allIds = state.allIds.slice();
            allIds.push(card.id);
            return {
                ...state,
                allIds,
                byIds: {
                    ...state.byIds,
                    [card.id]: card
                },
                isFetching: false
            };
        }
        case 'INSERT_USER_CARD_FAILURE' : {
            return {
                ...state,
                isFetching: false
            };
        }
        case 'UPDATE_USER_CARD_BEGIN' : {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'UPDATE_USER_CARD_SUCCESS': {
            const card = action.card;
            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [card.id]: card
                },
                isFetching: false
            };
        }
        case 'UPDATE_USER_CARD_FAILURE' : {
            return {
                ...state,
                isFetching: false
            }
        }
        case 'FETCH_USER_CARDS_BEGIN': {
            return {
                ...state,
                isFetching: true
            }
        }
        case 'FETCH_USER_CARDS_SUCCESS': {
            console.log('[userCards] onFetchUserCardsSuccess', action.cards);
            const cards = action.cards;
            const byIds = {};
            const allIds = [];
            cards.forEach((card => {
                byIds[card.id] = card;
                allIds.push(card.id);
            }));
            return {
                ...state,
                allIds,
                byIds,
                isFetching: false
            }
        }
        default:
            return state;
    }
}