import React from 'react';
import ToolIcon from '../ToolIcon';
import AddToolTrigger from '../../editor/AddToolTrigger';
import './CardFooter.css';


const CardFooter = ({
    canEdit,
    card,
    metadata,
    secondaryColor}) => {

    return (
        <div className="card-footer"
            style={{
                backgroundColor: secondaryColor
            }}>
            {
                card.tools.map((toolId, index) => {
                    const tool = metadata.tools.find(tool => tool.id === toolId);
                    return <ToolIcon key={ index } canEdit={ canEdit } index={ index } tool={ tool } />
                })
            }
            { canEdit && card.tools.length < 5 &&
                <AddToolTrigger />
            }
        </div>
    );
};

export default CardFooter;