import { partial } from 'lodash';
import loginSchema from './schemas/loginSchema';
import editProfileSchema from './schemas/editProfileSchema';
import {
    createTeacherSchema,
    editTeacherSchema,
    editPasswordSchema
} from './schemas/createTeacherSchema';
import subscriptionSchema from './schemas/subscriptionSchema';
import schoolSchema from './schemas/schoolSchema';
import resetPasswordSchema from './schemas/resetPasswordSchema';

const formatErrors = (err) => {
    return err.inner.reduce((acc, error) => {
        return {
            ...acc,
            [error.path]: error.message
        };
    }, {});
}

const validate = async (schema, values) => {
    try {
        await schema.validate(values, {
            abortEarly: false
        });
        return {};
    } catch(err) {
        return formatErrors(err);
    }
}

export const validateLogin = partial(validate, loginSchema);
export const validateCreateTeacher = partial(validate, createTeacherSchema);
export const validateResetPassword = partial(validate, resetPasswordSchema);
export const validateEditTeacher = partial(validate, editTeacherSchema);
export const validateEditProfile = partial(validate, editProfileSchema);
export const validateEditPassword = partial(validate, editPasswordSchema);
export const validateSubscription = partial(validate, subscriptionSchema);
export const validateSchool = partial(validate, schoolSchema);
