import React from 'react';
import { Typography, Grid, Button, makeStyles } from '@material-ui/core';
import { withPermissions } from 'utils/withPermissions';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import ProfileEditor from './ProfileEditor';
import Alert from '@material-ui/lab/Alert';
import TeacherSubscriptions from 'components/TeacherSubscriptions';
import PasswordEditor from './PasswordEditor';

const TAG = '[ProfilePage]';

const TeacherProfile = withPermissions(({ user, onUpdate }) => <TeacherSubscriptions single={ true } teacher={ user } role={ 'TEACHER' } />, null, ['TEACHER']);


const EditProfileButton = withPermissions(({
  onClick
}) => {

  return (
    <Grid item xs={ 4 }>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={ onClick }
      >
        Muokkaa
      </Button>
    </Grid>
  );
}, null, ['ADMIN']);

export default function ProfilePage() {
  const user = useUser();
  const [edit, setEdit] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  function handleEdit() {
    setSuccessMessage('');
    setEdit(true);
  }

  function handleUpdate() {
    setSuccessMessage('Käyttäjätili päivitetty!');
  }

  return (
    <>
      { successMessage && <Alert className="mb-3" severity="success">{ successMessage }</Alert> }
      { edit ?
        <ProfileEditor
          user={ user }
          onClose={ () => setEdit(false) }
          onUpdate={ handleUpdate }
        /> :
        <>
          <Grid container>
            <Grid xs={ 3 }>
              <Typography variant="subtitle2" gutterBottom>
                Etunimi
              </Typography>
              <Typography variant="body1" gutterBottom>{ user.firstName }</Typography>
            </Grid>
            <Grid xs={ 3 }>
              <Typography variant="subtitle2" gutterBottom>
                Sukunimi
              </Typography>
              <Typography variant="body1" gutterBottom>{ user.lastName }</Typography>
            </Grid>
            <Grid xs={ 12 } md={ 3 }>
              <Typography variant="subtitle2" gutterBottom>
                Sähköposti
              </Typography>
              <Typography variant="body1" gutterBottom>{ user.email }</Typography>
            </Grid>
            <Grid xs={ 12 } md={ 3 }>
              <EditProfileButton onClick={ handleEdit } />
            </Grid>
          </Grid>
          <TeacherProfile user={ user } />
          <PasswordEditor />
        </>
      }
    </>
  );
}