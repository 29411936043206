import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from 'components/layout/NavBar';

export default function Layout({
    children,
    className = ''
}) {
    return (
        <>
            <NavBar />
            <Container
                className={`pt-5 pb-5 ${ className }`}
            >
                { children }
            </Container>
        </>
    )
}