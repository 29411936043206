import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '2% 1.5%',
        height: '100%'
    },
    column1: {
        width: '100%'
    },
    column1_2: {
        width: '50%'
    },
    column1_3: {
        width: '33%'
    },
    column1_4: {
        width: '25%'
    },
    column2_5: {
        width: '40%'
    }
    ,
    column3_5: {
        width: '60%'
    }
});

class PDFColumn extends React.Component {

    render() {
        const classNameStyles = this.props.className.split(' ').map((className) => styles[className]);
        const componentStyles = [ styles.column ].concat(classNameStyles);
        return (
            <View
                style={ componentStyles }
                // debug={ true }
            >
                { this.props.children }
            </View>
        );
    }
};

export default PDFColumn;